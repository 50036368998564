<template>
    <div>
        <div class="hidden-sm hidden-xs">
            <!-- Aqui vai menu computador-->
            <header class="header header-intro-clearance header-4 " style="position: fixed;z-index: 99;">
                <div class="header-top" style="padding-top: 5px;padding-bottom: 5px;" v-if="topo != ''">
                    <div class="container">
                        <div class="header-left hidden-xs hidden-sm">
                            <a :href="topo[0].itiLinkBtn1" style="color:#ffffff;font-size: 13px;" target="_blank"><i
                                    class="icon-phone"></i>{{ topo[0].itiNome }} | {{ topo[0].itiReferencia }}</a>
                        </div><!-- End .header-left -->
                        <div class="header-right">
                            <span style="color:#ffffff;padding-right:15px;">{{ variavelHeader1 }}</span>
                            <div class="d-flex justify-content-center" v-if="redes != ''">
                                <a v-if="redes[0].itiNome != '' && redes[0].itiNome != null" :href="redes[0].itiNome"
                                    target="_blank" title="Facebook"
                                    style="color:#ffffff;font-size:13px;padding-right:15px;"><i
                                        class="icon-facebook-f"></i></a>
                                <a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                                    :href="redes[0].itiReferencia" target="_blank" title="Instagram"
                                    style="color:#ffffff;font-size:13px;padding-right:15px;"><i
                                        class="icon-instagram"></i></a>
                                <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                                    :href="redes[0].itiCampoExtra1" target="_blank" title="Youtube"
                                    style="color:#ffffff;font-size:13px;padding-right:15px;"><i
                                        class="icon-youtube"></i></a>
                                <a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                                    :href="redes[0].itiCampoExtra2" target="_blank" title="Linkedin"
                                    style="color:#ffffff;"><i class="icon-linkedin"></i></a>
                            </div><!-- End .social-icons -->
                        </div><!-- End .header-right -->
                    </div><!-- End .container -->
                </div><!-- End .header-top -->

                <div class="header-middle custom-div" >
                    <div class="container">
                        <div class="header-left">
                            <router-link to="/" class="logo">
                                <img src="/images/logo-nome.png" style="width:100%;height:75px;">
                            </router-link>
                        </div><!-- End .header-left -->

                        <div class="header-center">
                            <div class="header-bottom sticky-header">
                                <div class="container" >
                                    <div class="" v-if="!showSearch2">
                                        <nav class="main-nav">
                                            <ul class="menu">
                                                <li class="megamenu-container"
                                                    style="padding-left: 5px;padding-right: 10px;">
                                                    <router-link to="/">Inicio</router-link>
                                                </li>
                                                <li class="menu-item">
                                                    <router-link to="/CestasporCategoria">Cestas por Categorias</router-link>
                                                    <!-- Submenu para Cestas por Categoria -->
                                                    <ul class="submenu">
                                                        <li v-for="seg in SegmentosMenu" :key="seg.psgId">
                                                           <router-link :to="'/CestasporCategoria/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                                            {{ seg.psgNomeSegmento }}
                                                        </router-link>
                                                        </li>
                                                        <!-- Adicione mais itens conforme necessário -->
                                                    </ul>
                                                    </li> 
                                                <li class="megamenu-container" v-if="linkMenu!='' && linkMenu!=null"
                                                    style="padding-left: 5px;padding-right: 10px;">
                                                    <router-link :to="linkMenu[0].itiReferencia">{{ linkMenu[0].itiNome }}</router-link>
                                                </li>
                                                <li class="megamenu-container"
                                                    style="padding-left: 5px;padding-right: 10px;">
                                                    <router-link to="/sobre">Sobre</router-link>
                                                </li>
                                                <li class="megamenu-container"
                                                    style="padding-left: 5px;padding-right: 10px;">
                                                    <router-link to="/faleconosco">Fale Conosco</router-link>
                                                </li>
                                                <li class="megamenu-container"
                                                    style="padding-left: 0px;padding-right: 10px;">
                                                    <div class="icon" @click="toggleSearch2" style="cursor: pointer;">
                                                        <!--i class="icon-search" style="font-size: 26px;"></!--i-->
                                                          <img src="/images/LUPA.png" style="height: 35px;">
                                                    </div>
                                                </li>
                                                <li class="megamenu-container"
                                                    style="padding-left: 0px;padding-right: 10px;">
                                                    <div class="wishlist">
                                                        <router-link to="/login" title="Login" v-if="variavelHeader1 == ''">
                                                            <div class="icon">
                                                                 <!--i class="icon-user"></!--i-->
                                                                 <img src="/images/LOGIN2.png" style="height: 35px;">
                                                            </div>
                                                        </router-link>
                                                        <router-link to="/MeusDados" v-else title="Login">
                                                            <div class="icon">
                                                                <!--i class="icon-user"></!--i-->
                                                                <img src="/images/LOGIN2.png" style="height: 35px;">
                                                            </div>
                                                        </router-link>
                                                    </div><!-- End .compare-dropdown -->
                                                </li>
                                                <li class="megamenu-container"
                                                    style="padding-left: 0px;padding-right: 10px;">
                                                    <router-link to="/carrinho" title="Carrinho">
                                                        <div class="icon">
                                                            <!--i class="icon-shopping-cart" style="font-size:32px"></!--i-->
                                                            <img src="/images/CARRINHO.png" style="height: 40px;">
                                                            <span v-if="variavelHeader2 != ''" class="cart-count">{{
                                                                variavelHeader2 }}</span>
                                                        </div>
                                                    </router-link>
                                                </li>
                                            </ul><!-- End .menu -->
                                        </nav><!-- End .main-nav -->
                                    </div><!-- End .header-center -->
                                    <div class="custom-row" v-if="showSearch2" style="padding-left: 30px;padding-top: 0px;">
                                        <div class="custom-col">
                                            <input type="search" ref="searchInput" class="form-control" style="width: 600px;" v-model="pesquisaChave" @keyup.enter="Pesquisa()" placeholder="Pesquise..." required>
                                        </div>
                                        <div class="custom-col">
                                            <a href="#" title="Carrinho" class="" @click="Pesquisa()"><!--i class="icon-search" style="font-size: 25px;"></!--i--><img src="/images/LUPA.png" style="height: 40px;"></a>
                                        </div>
                                        <div class="custom-col">
                                            <a href="#" class="" @click="showSearch2 = !showSearch2"><!--i class="icon-close" style="font-size: 25px;"></!--i--><img src="/images/FECHAR.png" style="height: 40px;"></a>
                                        </div>
                                    </div>
                                </div><!-- End .container -->
                            </div><!-- End .header-bottom -->
                        </div>


                    </div><!-- End .container -->
                </div><!-- End .header-middle -->
                

            </header><!-- End .header -->
          
        </div>

        <div class="container hidden-lg hidden-md" style="padding-bottom: 60px;">
            <v-navigation-drawer v-model="drawer" style="margin-top: 64px;">
                <!-- Conteúdo do menu lateral -->
                <v-list>
                    <v-list-item link to="/">
                        <v-list-item-title style="font-size: 16px;">Início</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-title class="d-inline" style="font-size: 16px;">Cestas por Categoria</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/faleconosco">
                        <v-list-item-title style="font-size: 16px;">Fale Conosco</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/sobre">
                        <v-list-item-title style="font-size: 16px;">Sobre</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-title class="d-inline" style="font-size: 16px;">Informações Gerais</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/Carrinho">
                        <v-list-item-title style="font-size: 16px;">Carrinho</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/MeusPedidos">
                        <v-list-item-title style="font-size: 16px;">Meus Pedidos</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-list >
                    <v-list-item link to="/Login" style="padding-top: 10px;padding-bottom: 10px;background-color: #e5e0e0;" v-if="variavelHeader1 == ''">
                        <v-list-item-title style="display: inline-flex;text-align: center;">
                            <div class="icon" >
                                <!--i class="icon-user" style="font-size:30px"></!--i-->
                                <img src="/images/LOGIN2.png" style="height: 35px;">
                            </div>
                            <div style="padding-top: 8px;font-size: 18px;padding-left: 5px;">Login</div>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/meusdados" style="padding-top: 10px;padding-bottom: 10px;background-color: #e5e0e0;" v-else>
                        <v-list-item-title style="display: inline-flex;text-align: center;">
                            <div class="icon" >
                                <!--i class="icon-user" style="font-size:30px"></!--i-->
                                <img src="/images/LOGIN2.png" style="height: 35px;">
                            </div>
                            <div style="padding-top: 8px;font-size: 18px;padding-left: 5px;">Minha conta</div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-row class="justify-content-left" style="padding-top:10px;padding-left: 30px;">
                    <div class="d-flex justify-content-center" v-if="redes != ''">
                        <a v-if="redes[0].itiNome != '' && redes[0].itiNome != null" :href="redes[0].itiNome"
                            target="_blank" title="Facebook" style="color:#000000;font-size:20px;padding-right:15px;"><i
                                class="icon-facebook-f"></i></a>
                        <a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                            :href="redes[0].itiReferencia" target="_blank" title="Instagram"
                            style="color:#000000;font-size:22px;padding-right:15px;"><i class="icon-instagram"></i></a>
                        <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                            :href="redes[0].itiCampoExtra1" target="_blank" title="Youtube"
                            style="color:#000000;font-size:22px;padding-right:15px;"><i class="icon-youtube"></i></a>
                        <a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                            :href="redes[0].itiCampoExtra2" target="_blank" title="Linkedin" style="color:#000000;"><i
                                class="icon-linkedin"></i></a>
                    </div><!-- End .social-icons -->

                </v-row>
            </v-navigation-drawer>


            <v-navigation-drawer v-model="drawer2" style="margin-top: 64px;">
                <!-- Conteúdo do menu lateral -->
                <v-list>
                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline" style="font-size: 13px;">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="itens in informacoes" :key="itens.itiId">
                        <v-list-item link
                            :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <v-list-item-title style="font-size: 16px;"> {{ itens.itiNome }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer v-model="drawer3" style="margin-top: 64px;">
                <!-- Conteúdo do menu lateral -->
                <v-list>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline" style="font-size: 13px;">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="seg in SegmentosMenu" :key="seg.psgId" class="d-inline">
                        <v-list-item link
                            :to="'/CestasporCategoria/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <i class="fa fa-heart" style="color:#B91F1C;"></i>
                            <v-list-item-title
                                style="font-weight: 500; font-size: 15px;padding-left: 8px;color:#333333;display: inline-flex;">
                                {{ seg.psgNomeSegmento }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <!-- Conteúdo principal -->
            <v-app-bar app>
               
                <v-row align="center" class="logo-cart-container">
                    <v-col class="col" @click.stop="drawer = !drawer" v-if="!showSearch">
                            <img v-if="!drawer" src="/images/MENU.png" alt="Menu Icon" style="width: 100%; height: 100%;" />
                            <img v-else src="/images/FECHAR.png" alt="Close Icon" style="width: 100%; height: 100%;" />
                    </v-col>
                    <v-col class="logo-col" v-if="!showSearch">
                        <img src="/images/logo-nome.png" class="logo-img">
                    </v-col>
                    <v-col class="col" v-if="!showSearch">
                        <div class="dropdown cart-dropdown">
                            <router-link to="/carrinho" class="dropdown-toggle" title="Carrinho">
                                <div class="icon">
                                    <!--i class="icon-shopping-cart"></!i-->
                                    <img src="/images/CARRINHO.png" style="height: 40px;">
                                    <span v-if="variavelHeader2 != ''" class="cart-count">{{ variavelHeader2 }}</span>
                                </div>
                            </router-link>
                        </div><!-- End .cart-dropdown -->
                    </v-col>
                    <v-col v-if="showSearch">
                        <div class="header-search-wrapper" style="padding-left: 5px;">
                            <input type="search" ref="searchInput2" class="form-control" v-model="pesquisaChave" @keyup.enter="Pesquisa()"
                                placeholder="Pesquise..." required>
                            <a href="#" class="" @click="Pesquisa()">
                                <!--i class="icon-search" style="font-size: 25px;"></!--i-->
                                <img src="/images/LUPA.png" style="height: 35px;">
                            </a>
                            <a href="#" class="" @click="showSearch = !showSearch">
                                <!--i class="icon-close" style="font-size: 25px;"></!--i-->
                                <img src="/images/FECHAR.png" style="height: 40px;">
                            </a>
                        </div>
                    </v-col>    
                    <v-col class="col" v-if="!showSearch" style="padding-right: 20px;">
                        <div class="dropdown cart-dropdown">
                            <div class="icon"
                                @click="toggleSearch3()">
                                <!--i class="icon-search" style="font-size: 25px;"></!--i-->
                                <img src="/images/LUPA.png" style="height: 35px;">
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-app-bar>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { mapState } from 'vuex';

export default {
    name: 'PageHeader',
    components: {

    },
    data: () => ({
        topo: [],
        redes: [],
        pesquisaChave: '',
        informacoes: [],
        drawer: false,
        drawer2: false,
        drawer3: false,
        showSearch: false,
        showSearch2: false,
        Segmentos: [],
        SegmentosMenu: [],
        linkMenu:[],
    }),
    methods: {
        toggleSearch2() {
            this.showSearch2 = !this.showSearch2;

            this.$nextTick(() => {
                // Agora, o $refs.searchInput deve estar definido
                this.$refs.searchInput.focus();
            });
        },
        toggleSearch3() {
            this.showSearch = !this.showSearch;

            this.$nextTick(() => {
                // Agora, o $refs.searchInput deve estar definido
                this.$refs.searchInput2.focus();
            });
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                        valorAvulsos += item.praValorPor;
                        vcount++;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                        valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                        vcount++;
                    });
                }
            }
            this.valorTotal = valorProdutos + valorAvulsos;
            this.$store.commit('updateVariavelHeader2', vcount);
        },
        Pesquisa() {
            if (this.pesquisaChave == "") {
                this.$mensagemAviso("Digite uma palavra chave para pesquisa.")
            }
            else {
                const novaURL = `/CestasporCategoriaPesquisa/${this.pesquisaChave}`;
                this.$router.push(novaURL);
                this.showSearch2 = !this.showSearch2;
                this.showSearch = !this.showSearch;
            }
        }
    },
    async created() {

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
            const _dadosRecuperados = JSON.parse(cookieData);
            this.$store.commit('updateVariavelHeader1', "Olá, " + _dadosRecuperados.nomeUsuario);
        }
        this.calculatotal();

        axios.get('/Process/InsInstitucional/institucional/9/cabecalho')
            .then(response => {
                this.topo = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/20/redessociais')
            .then(response => {
                this.redes = response.data;
            })
            .catch(error => {
                console.error(error);
            });

            axios.get('/Process/InsInstitucional/institucional/33/linkmenu')
            .then(response => {
                this.linkMenu = response.data;
            })
            .catch(error => {
                console.error(error);
            });

           

        axios.get('/Process/InsInstitucional/institucional/18/informacoes')
            .then(response => {
                this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get('/Process/ProSegmentos/GetAll')
            .then(response => {
                this.Segmentos = response.data;
                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });
            })
            .catch(error => {
                console.error(error);
            });
    },
    computed: {
        ...mapState(['variavelHeader1', 'variavelHeader2'])
    },
}
</script>

<style>
@media (max-width:768px) {
    .logo-col {
        flex: 5;
        /* Ajuste o valor para controlar o tamanho do logo */
    }

    .icon-col {
        flex: 1;
        /* Ajuste o valor para controlar o tamanho dos ícones e o espaço entre eles */
    }

    .logo-img {
        width: 100%;
        /* Para fazer o logo ocupar 100% da largura da coluna */
    }

    .search-container {
        position: absolute;
        background-color: white;
        /* Cor de fundo do campo de pesquisa */
        z-index: 1;
        /* Para sobrepor o conteúdo abaixo */
        width: 100%;
        /* Para ocupar a largura da barra de aplicativos */
        top: 60px;
        /* Ajuste conforme necessário para a posição vertical desejada */
        padding: 5px;
        /* Espaçamento interno do campo de pesquisa */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Sombra, opcional */
    }

    .search-input {
        width: 100%;
        padding: 10px;
    }

    .header-search-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cart-count {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: #B91F1C;;
    color: white;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 9px;
}
}

    .v-navigation-drawer__scrim {
    position: absolute;
    width: 100%;
    height: 100%;
     background: none !important; 
    opacity: 0.2;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
}

@media (min-width:768px) {
    .v-col {
    flex-basis: 0;
    flex-grow: 0.3 !important;
    max-width: 100%;
}

.logo-col {
        flex: 5;
        /* Ajuste o valor para controlar o tamanho do logo */
    }

    .icon-col {
        flex: 1;
        /* Ajuste o valor para controlar o tamanho dos ícones e o espaço entre eles */
    }

    .logo-img {
        width: 100%;
        /* Para fazer o logo ocupar 100% da largura da coluna */
    }

    .search-container {
        position: absolute;
        background-color: white;
        /* Cor de fundo do campo de pesquisa */
        z-index: 1;
        /* Para sobrepor o conteúdo abaixo */
        width: 100%;
        /* Para ocupar a largura da barra de aplicativos */
        top: 60px;
        /* Ajuste conforme necessário para a posição vertical desejada */
        padding: 5px;
        /* Espaçamento interno do campo de pesquisa */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Sombra, opcional */
    }

    .search-input {
        width: 100%;
        padding: 10px;
    }

    .header-search-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cart-count {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: #B91F1C;;
    color: white;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 9px;
}
}

@media screen and (max-width: 575px){
    .cart-dropdown {
    padding-left: 0px !important;
}
}

.custom-div {
  border-bottom: 2px solid #ccc; /* Cor e espessura da borda inferior */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra na parte inferior */
}

.cart-link {
  position: relative;
}
@media (min-width:1200px) {
.cart-count {
    position: absolute;
    top: -41px;
    right: -28px;
    background-color: #B91F1C;;
    color: white;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 9px;
}
}

.custom-row {
  display: flex;
}

.custom-col {
  flex: 1;
  margin-right: 10px; /* Ajuste conforme necessário para o espaçamento entre as colunas */
}

.main-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu-item {
    position: relative;
    padding: 5px 10px;
    display: inline-block;
  }

  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .menu-item:hover .submenu {
    display: block;
  }

</style>
