<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-carrinho" style="background-color: #f7e7db;">
        <section class="">
          <div class="container">
            <div class="row py-5">
              <div class="col-lg-5 hidden-xs hidden-sm">
                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                  v-if="logintopo != ''">
                  <img v-if="logintopo[0].insInstitucionalImagens != ''"
                    :src="$imgURL + logintopo[0].insInstitucionalImagens[0].itgUrlImagem" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="hidden-xs hidden-sm" style="display:inline-flex;padding-top: 10px;padding-bottom: 10px;padding-left: 10px;">
                  <img src="/images/ENTREGA2.png" style="height: 35px;padding-right: 10px;" /> <span style="padding-right: 5px;color:#B91F1C;font-size: 18px;">Entrega</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right: 10px;margin-top: 10px;" />
                  <img src="/images/MENSAGEM2.png" style="height: 35px;padding-right: 10px;" />  <span style="padding-right: 5px;font-size: 18px;color:#B91F1C;">Mensagem</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right:10px;margin-top: 10px;" />
                  <img src="/images/CIFRÃO2.png" style="height: 35px;padding-right: 10px;" />  <span style="padding-right: 5px;font-size: 18px;color:#B91F1C;">Pagamento</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right: 10px;margin-top: 10px;" />
                  <img src="/images/CONCLUIDO.png" style="height: 35px;" />  <span style="font-size: 18px;">Concluído</span>
                </div>
                <div class="hidden-md hidden-lg" style="display:inline-flex;padding-top: 10px;padding-bottom: 10px;padding-left: 0px;">
                    <img src="/images/entregas2.png" style="height: 15px;padding-right: 5px;" /> <span style="padding-right: 5px;color:#B91F1C;font-size: 8px;">Entrega</span>
                    <img src="/images/seta.png" style="height: 5px;padding-left: 5px;padding-right: 5px;margin-top: 5px;" />
                    <img src="/images/envelope2.png" style="height: 15px;padding-right: 5px;" />  <span style="padding-right: 5px;font-size: 8px;color:#B91F1C;">Mensagem</span>
                    <img src="/images/seta.png" style="height: 5px;padding-left: 5px;padding-right:5px;margin-top: 5px;" />
                    <img src="/images/valor2.png" style="height: 15px;padding-right: 5px;" />  <span style="padding-right: 5px;font-size: 8px;color:#B91F1C;">Pagamento</span>
                    <img src="/images/seta.png" style="height: 5px;padding-left: 5px;padding-right: 5px;margin-top: 5px;" />
                    <img src="/images/sete.png" style="height: 15px;" />  <span style="font-size: 8px;">Concluído</span>
                </div>
                <div class="box_content">
                 <!-- Dados do Cartão -->
                 <h3 v-if="logintopo != ''" class="titulo-principal text-center">{{logintopo[0].itiNome }}</h3>
                 <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12" v-if="ListaformasPagamento.length > 0">
                                <v-select v-model="ListaformasPagamentoselected" label="Selecione Pagamento" :items="ListaformasPagamento" @update:modelValue="CarregaPgtoSelecionado(ListaformasPagamentoselected)"
                                    item-title="pfcNomefront" item-value="pfcId" variant="underlined" style="width: 100%;">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="12" v-if="showPix==true">
                              <PagamentoPix v-if="showPix==true" :PixId="OperacaoId" :key="componentKeypix" />
                            </v-col>
                            <v-col cols="12" md="12" v-if="showRede==true">
                              <PagamentoRede v-if="showRede==true" :key="componentKeypix2" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                </div>
              </div>
            </div>
          </div>

        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import PagamentoPix from '@/components/PagePgtoPixChaveTexto.vue'

export default {
  name: 'PagamentoView',
  components: {
    CheckAcesso,
    PagamentoPix,
  },
  data: () => ({
    logintopo: [],
    ListaformasPagamento: [],
    ListaformasPagamentoselected: null,
    OperacaoId: 0,
    componentKeypix:0,
    componentKeypix2:0,
    showPix: false,
    showRede:false,
    retornoparcelas: [],
  }),
  methods: {
    CarregaPgtoSelecionado(value){
      if (value == 3){
        this.showPix = true;
        this.showRede = false;
        this.OperacaoId = value;
        this.forceRerenderPix();
      }
      else if (value == 4){
        this.showPix = false;
        this.showRede = true;
        this.forceRerenderPix2();
      }

    },
    forceRerenderPix() {
          this.componentKeypix += 1;
    },
    forceRerenderPix2() {
          this.componentKeypix2 += 1;
    },
    Carregaparcelas() {
        this.retornoparcelas = [];
        this.retornoparcelas.push({ pagamentoId: 1, textopagamento: "1x de R$ " + parseFloat(this.TotalProdutos).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 2, textopagamento: "2x de R$ " + (parseFloat(this.TotalProdutos) / 2).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 3, textopagamento: "3x de R$ " + (parseFloat(this.TotalProdutos) /3).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 4, textopagamento: "4x de R$ " + (parseFloat(this.TotalProdutos) /4).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 5, textopagamento: "5x de R$ " + (parseFloat(this.TotalProdutos) /5).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 6, textopagamento: "6x de R$ " + (parseFloat(this.TotalProdutos) /6).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        /*    this.retornoparcelas.push({ pagamentoId: 7, textopagamento: "7x de R$ " + (parseFloat(this.TotalProdutos) /7).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 8, textopagamento: "8x de R$ " + (parseFloat(this.TotalProdutos) /8).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 9, textopagamento: "9x de R$ " + (parseFloat(this.TotalProdutos) /9).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 10, textopagamento: "10x de R$ " + (parseFloat(this.TotalProdutos) /10).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 11, textopagamento: "11x de R$ " + (parseFloat(this.TotalProdutos) /11).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
        this.retornoparcelas.push({ pagamentoId: 12, textopagamento: "12x de R$ " + (parseFloat(this.TotalProdutos) /12).toFixed(2).toString().replace('.', ',') + " Sem Juros" }) */
    },
  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/24/textopagamento')
      .then(response => {
        this.logintopo = response.data;
       // console.log('this.logintopo')
       // console.log(this.logintopo)
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/ConConfigFormasPagamento/GetAll')
      .then(response => {
        this.ListaformasPagamento = response.data;
      //  console.log('ListaformasPagamento')
       // console.log(this.ListaformasPagamento)
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}
</style>
