<template>
    <div>
        <main class="main">
            <!--div v-if="informacoestopo != '' && informacoestopo[0].insInstitucionalImagens != ''"
                class="page-header text-center"
                :style="{ backgroundImage: 'url(' + $imgURL + informacoestopo[0].insInstitucionalImagens[0].itgUrlImagem + ')' }">
                <div class="container">
                    <h1 class="page-title">Cestas de Presente</h1>
                </div>
            </div-->

           <div style="padding-top: 30px;" v-if="SegmentosMenu!=''">
                <Swiper :navigation="false" :space-between="3" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 1100: { slidesPerView: 10 }, 768: { slidesPerView: 6 }, 0: { slidesPerView: 3 } }" style="height: 180px !important;">
                    <SwiperSlide v-for="seg in SegmentosMenu" :key="seg.psgId" class="swiper-slide">
                        <div class="swiper-content">
                            <a href="#" @click.prevent="MontaUrl(seg.psgNomeSegmento, seg.psgId)">
                            <img v-if="seg.psgResumo !== '' && seg.psgResumo !== null" :src="$imgURL + seg.psgResumo" class="swiper-image" />
                            <center v-else><div  class="swiper-placeholder"></div></center>
                        <span class="swiper-text" style="font-weight: 500; font-size: 14px;color:#333333;">{{ seg.psgNomeSegmento }}</span>
                        </a>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div class="page-content" style="padding-top: 30px;">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12" style="text-align: center;">
                            <div class="toolbox-info" style="color:#B91F1C;font-size: 30px;font-weight: 500;">
                                {{ NomeCategoria }}
                            </div><!-- End .toolbox-info -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="toolbox">
                                <div class="toolbox-left">
                                    <v-chip v-for="seg2 in SegmentosSubMenu" :key="seg2.psgId" style="margin: 5px;">
                                       <a href="#" @click="filtraProdutos(seg2.psgNomeSegmento,seg2.psgId)" style="font-size: 13px;">
                                         {{ seg2.psgNomeSegmento}}
                                        </a>
                                    </v-chip>
                                </div><!-- End .toolbox-left -->
                                <div class="toolbox-right">
                                    <div class="toolbox-sort">
                                        <v-select v-model="Ordenacao" :items="OrdenacaoAtivo" label="Ordenar por"
                                            @update:modelValue="OrdenaProdutos(Ordenacao)" item-title="nome"
                                            item-value="valor" variant="underlined" style="width: 180px;">
                                        </v-select>
                                    </div><!-- End .toolbox-sort -->
                                </div><!-- End .toolbox-right -->
                            </div><!-- End .toolbox -->

                            <div class="products mb-3">
                                <div class="row justify-content-center" v-if="ProdutosFiltro != ''">
                                    <div class="col-6 col-md-3 col-lg-3 pt-4" v-for="prod4car in ProdutosFiltro"
                                        :key="prod4car.proProdutos.proId">
                                        <div class="product product-2" v-if="prod4car != ''">
                                            <figure class="product-media" style="border: 0px solid #cccccc;">
                                                <span v-if="prod4car.proProdutos.proEtiqueta != '' && prod4car.proProdutos.proEtiqueta != null"
                                                    class="product-label label-sale">{{ prod4car.proProdutos.proEtiqueta }}</span>
                                                    <span v-else-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null  && prod4car.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                                    <router-link
                                                    :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                                    <img v-if="prod4car.proProdutos.proCampoExtra10 != '' && prod4car.proProdutos.proCampoExtra10 != null"
                                                        :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt=""
                                                        class="product-image">
                                                    <img v-if="prod4car.proProdutos.proCampoExtra9 != '' && prod4car.proProdutos.proCampoExtra9 != null"
                                                    :src="$imgURL + prod4car.proProdutos.proCampoExtra9" alt=""
                                                    class="product-image-hover">
                                                </router-link>
                                                <div class="product-action">
                                                    <router-link
                                                        :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                        class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                                    </router-link>
                                                </div><!-- End .product-action -->
                                            </figure><!-- End .product-media -->

                                            <div class="product-body">
                                                <div class="product-cat">
                                                </div><!-- End .product-cat -->
                                                <h3 class="product-title">
                                                    <router-link
                                                        :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                                        {{ prod4car.proProdutos.proNomeProduto }}
                                                    </router-Link>
                                                </h3><!-- End .product-title -->
                                                <div class="product-price"
                                                    v-if="prod4car.proProdutos != '' && prod4car.proProdutos != null && prod4car.proProdutos.proEstoque > 0">
                                                    <span class="new-price">por R$ {{
                                                        formatarValor(prod4car.proProdutos.proValorPor) }}</span>
                                                    <small class="old-price"
                                                        v-if="prod4car.proProdutos.proValorDe != '' && prod4car.proProdutos.proValorDe != null">era
                                                        R$ {{ formatarValor(prod4car.proProdutos.proValorDe)
                                                        }}</small>
                                                </div><!-- End .product-price -->
                                                <div class="product-price" v-else>
                                                    <span class="new-price">Indisponível</span>
                                                </div><!-- End .product-price -->
                                            </div><!-- End .product-body -->
                                        </div><!-- End .product -->
                                    </div><!-- End .col-sm-6 col-lg-4 -->
                                </div><!-- End .row -->
                            </div><!-- End .products -->
                        </div><!-- End .col-lg-9 -->

                    </div><!-- End .row -->
                </div><!-- End .container -->
                <div v-if="infocall != ''" class="cta bg-image bg-dark"
                    :style="{ backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom: '80px' }">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-sm-10 col-md-8 col-lg-6">
                                <div class="cta-text text-center">
                                    <h3 class="cta-title text-white">{{ infocall[0].itiNome }}</h3><!-- End .cta-title -->
                                    <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1"
                                        style="color: #ccc !important;"></p><!-- End .cta-desc -->

                                    <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded"
                                        target="_blank"><span>{{ infocall[0].itiTextoBtn1 }}</span><i
                                            class="icon-long-arrow-right"></i></a>
                                    <a v-if="infocall[0].itiReferencia!='' && infocall[0].itiReferencia!=null" :href="infocall[0].itiReferencia" class="btn btn-warning btn-rounded" style="margin: 15px;"><span>{{infocall[0].itiCampoExtra1}}</span><i
                                class="icon-long-arrow-right"></i></a>
                                </div><!-- End .cta-text -->
                            </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .cta -->
            </div><!-- End .page-content -->
        </main>

    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
    name: 'CestasporCategoriaView',
    setup() {
        const route = useRoute();
        const id = route.params.idSegmentoProduto;
        const nomeSeg = route.params.nomeSegmento;
        const pesquisa = route.params.chavepesquisa;
        return {
            id,pesquisa
        };
    },
    components: {
        Swiper,
    SwiperSlide,
    },
    data: () => ({
        informacoestopo: [],
        produtosSegmentos: [],
        Segmentos: [],
        SegmentosMenu: [],
        ProdutosGeral:[],
        ProdutosFiltro: [],
        infocall: [],
        CategoriaClicada: false,
        NomeCategoria: '',
        OrdenacaoAtivo: [{ nome: "A-Z", valor: 1 }, { nome: "Z-A", valor: 2 }, { nome: "Maior Valor", valor: 3 }, { nome: "Menor Valor", valor: 4 }],
        Ordenacao: '',
        SegmentosSubMenu:[],
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Produtos - Cestas Porto Alegre'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        carregaTexto(value) {
            this.informacao = this.informacoes.filter(x => x.itiId == value)
        },
        OrdenaProdutos(value) {
            if (value == 1) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                });
            }
            else if (value == 2) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                }).reverse();
            }
            else if (value == 3) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                }).reverse();
            }
            else if (value == 4) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                });
            }

        },
        MontaUrl(nomeSegmento, psgId) {
            this.$router.push({
                name: 'CestasporCategoria',
                params: {
                    idSegmentoProduto: psgId,
                    nomeSegmento: nomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', ''),
                },
                replace: true,
            });
        },
        filtraProdutos(nomeSegmento, psgId){
            this.ProdutosFiltro = this.ProdutosGeral.filter(x=> x.proProdutos.proVisivel && x.proSegmentos.psgId == psgId).sort((a, b) => {
                                                                                const valorA = parseInt(a.proProdutos.proOrdem) || 0;
                                                                                const valorB = parseInt(b.proProdutos.proOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                this.NomeCategoria = nomeSegmento;
        },
        VoltarNivel() {
            if (this.id != 0) {
                let _seg = this.Segmentos.filter(x => x.psgId == this.id);
                let _nome = 'Cestas mais vendidas';
                if (_seg[0].psgIdPai != 0) {
                    _nome = this.Segmentos.filter(x => x.psgId == _seg[0].psgIdPai)[0].psgNomeSegmento;
                }
                this.$router.push({
                    name: 'CestasporCategoria',
                    params: {
                        idSegmentoProduto: _seg[0].psgIdPai,
                        nomeSegmento: _nome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', ''),
                    },
                    replace: true,
                });
            }
        },
        AjustaUrl(value, nome) {
            let urlFriendlyNomeProduto = nome
                .normalize("NFD") // Normaliza para decompor os caracteres acentuados
                .replace(/[\u0300-\u036f]/g, "") // Remove os caracteres acentuados
                .replace(/[^\w\s-]/g, "") // Remove caracteres especiais
                .replace(/\s+/g, "-") // Substitui espaços em branco por hífens
                .toLowerCase(); // Converte para minúsculas

            return `${value}/${urlFriendlyNomeProduto}`;
        },
    },
    async created() {
        this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/27/imagemtopo')
            .then(response => {
                this.informacoestopo = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/19/callaction')
            .then(response => {
                this.infocall = response.data;
            })
            .catch(error => {
                console.error(error);
            });

            await axios.get('/Process/ProProdutosSegmentos/GetAllBySite')
                    .then(response => {
                        //console.log(response.data)
                        this.ProdutosGeral = response.data;
                        this.ProdutosGeral = this.ProdutosGeral.sort((a, b) => {
                                                                                const valorA = parseInt(a.proProdutos.proOrdem) || 0;
                                                                                const valorB = parseInt(b.proProdutos.proOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });
                        console.log('this.ProdutosGeral')
                        console.log(this.ProdutosGeral)
                    })
                    .catch(error => {
                        console.error(error);
                    });

            await axios.get('/Process/ProSegmentos/GetAll')
                    .then(response => {
                        this.Segmentos = response.data;
                        this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                            const nomeA = a.psgNomeSegmento || "";
                            const nomeB = b.psgNomeSegmento || "";
                            return nomeA.localeCompare(nomeB);
                        });

                        this.NomeCategoria = 'Cestas mais vendidas';
                    })
                    .catch(error => {
                        console.error(error);
                    });


        if (this.pesquisa != null && this.pesquisa != '') {

            let _pesquisa = this.pesquisa.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
           
            let dados = this.ProdutosGeral.filter(x => x.proProdutos.proVisivel && x.proProdutos.proNomeProduto != null).sort((a, b) => {
                                                                                const valorA = parseInt(a.proProdutos.proOrdem) || 0;
                                                                                const valorB = parseInt(b.proProdutos.proOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });
            let dados2 = dados.filter(x => x.proProdutos.proNomeProduto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1 
                || (x.proProdutos.proTexto1!=null && x.proProdutos.proTexto1.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1)
                || (x.proProdutos.proTexto2!=null && x.proProdutos.proTexto2.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1)
                );    

                dados2.forEach(element => {
                    if (this.ProdutosFiltro.filter(x=> x.proProdutos.proId == element.proProdutos.proId).length == 0){
                        this.ProdutosFiltro.push(element);
                    }
                });        
           this.NomeCategoria = this.pesquisa;
        }
        else {
            if (this.id == null || this.id == '' || this.id == 0) {
                let dados = this.ProdutosGeral.filter(x=> x.proProdutos.proDestaque);
                   dados.forEach(element => {
                        if (this.ProdutosFiltro.filter(x=> x.proProdutos.proId == element.proProdutos.proId).length == 0){
                            this.ProdutosFiltro.push(element);
                        }
                    });                
            }
            else {
                this.ProdutosFiltro = this.ProdutosGeral.filter(x=> x.proProdutos.proVisivel && x.proSegmentos.psgId == this.id).sort((a, b) => {
                                                                                const valorA = parseInt(a.proProdutos.proOrdem) || 0;
                                                                                const valorB = parseInt(b.proProdutos.proOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                this.SegmentosSubMenu = this.Segmentos.filter(x => x.psgIdPai ==  this.id && x.psgVisivel).sort((a, b) => {
                                const nomeA = a.psgNomeSegmento || "";
                                const nomeB = b.psgNomeSegmento || "";
                                return nomeA.localeCompare(nomeB);
                            });
                this.NomeCategoria = this.Segmentos.filter(x=> x.psgId == this.id)[0].psgNomeSegmento;
            }
        }
    },
}
</script>

<style>.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-content {
  text-align: center;
}

.swiper-image,
.swiper-placeholder {
  height: 100px;
  width: 100px;
  border: 1px solid #B91F1C;
  border-radius: 50%;
}

.swiper-placeholder {
  background-color: #cccccc;
}

.swiper-text {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.toolbox {
    display: flex;
    justify-content: space-between;
}

.toolbox-left {
    flex: 1 !important; /* Isso fará com que ele ocupe o espaço disponível (90% da largura) */
}

</style>
