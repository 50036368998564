<template>
  <div>
    <main class="main" >
      <div  v-if="sobretopo!='' && sobretopo[0].insInstitucionalImagens!=''" class="page-header text-center" :style="{backgroundImage: 'url(' + $imgURL + sobretopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}">
        		<div class="container">
        			<h1 class="page-title">{{sobretopo[0].itiNome}}</h1>
        		</div><!-- End .container -->
        	</div><!-- End .page-header -->
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Corporativo</li>
                    </ol>
                </div><!-- End .container -->
            </nav><!-- End .breadcrumb-nav -->

            <div class="page-content pb-3">
                <div class="container pb-6" v-if="texto!=''">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-text text-center mt-3">
                                <h2 class="title text-center mb-2">{{texto[0].itiNome}}</h2><!-- End .title text-center mb-2 -->
                                <p v-html="texto[0].itiResumo1"></p><br>
                               
                            </div><!-- End .about-text -->
                        </div><!-- End .col-lg-10 offset-1 -->
                    </div><!-- End .row -->
                </div><!-- End .container -->

                <div class="mb-2"></div><!-- End .mb-2 -->

                <div class="products mb-3">
                    <div class="row justify-content-center" v-if="produtos != ''">
                        <div class="col-6 col-md-3 col-lg-3 pt-4" v-for="prod in produtos"  :key="prod.itiId">
                            <div class="product product-2" v-if="prod4car != ''">
                                <figure class="product-media" style="border: 0px solid #cccccc;">
                                     <img v-if="prod.insInstitucionalImagens!= '' && prod.insInstitucionalImagens != null"
                                            :src="$imgURL + prod.insInstitucionalImagens[0].itgUrlImagem" alt="" class="product-image">
                               </figure><!-- End .product-media -->
                                <div class="product-body">
                                    <div class="product-cat">
                                    </div><!-- End .product-cat -->
                                    <h3 class="product-title">
                                      {{ prod.itiReferencia }} - {{ prod.itiNome }}
                                    </h3><!-- End .product-title -->
                                    <div class="product-price">
                                        <span class="new-price"> {{ prod.itiCampoExtra1 }}</span>
                                    </div><!-- End .product-price -->
                                </div><!-- End .product-body -->
                            </div><!-- End .product -->
                        </div><!-- End .col-sm-6 col-lg-4 -->
                    </div><!-- End .row -->
                </div><!-- End .products -->
            </div><!-- End .page-content -->
            <div v-if="infocall != ''" class="cta bg-image bg-dark"
                    :style="{ backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom: '80px' }">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-sm-10 col-md-8 col-lg-6">
                                <div class="cta-text text-center">
                                    <h3 class="cta-title text-white">{{ infocall[0].itiNome }}</h3><!-- End .cta-title -->
                                    <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1"
                                        style="color: #ccc !important;"></p><!-- End .cta-desc -->

                                    <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded"
                                        target="_blank"><span>{{ infocall[0].itiTextoBtn1 }}</span><i
                                            class="icon-long-arrow-right"></i></a>
                                    <a v-if="infocall[0].itiReferencia!='' && infocall[0].itiReferencia!=null" :href="infocall[0].itiReferencia" class="btn btn-warning btn-rounded" style="margin: 15px;"><span>{{infocall[0].itiCampoExtra1}}</span><i
                                class="icon-long-arrow-right"></i></a>
                                </div><!-- End .cta-text -->
                            </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .cta -->
    </main>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import VueJsCounter from 'vue-js-counter'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'CorporativoView',
  components: {
    VueJsCounter,
    Swiper,
    SwiperSlide
  },
  data: () => ({
    sobretopo: [],
    texto:[],
    produtos:[],
    infocall: [],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Corporativo - Cestas Porto Alegre'
			})
		},
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/30/corporativotopo')
      .then(response => {
        this.sobretopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/31/corporativotexto')
      .then(response => {
        this.texto = response.data;
      })
      .catch(error => {
        console.error(error);
      });

   axios.get('/Process/InsInstitucional/institucional/32/corporativoprodutos')
      .then(response => {
        this.produtos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
        console.log('this.produtos')
        console.log(this.produtos)
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/19/callaction')
            .then(response => {
                this.infocall = response.data;
            })
            .catch(error => {
                console.error(error);
            });
   
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
