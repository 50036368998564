<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-carrinho" style="background-color: #f7e7db;">
        <section class="">
          <div class="container">
            <div class="row py-5">
              <div class="col-lg-5 hidden-xs hidden-sm">
                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                  v-if="logintopo != ''">
                  <img v-if="logintopo[0].insInstitucionalImagens != ''"
                    :src="$imgURL + logintopo[0].insInstitucionalImagens[0].itgUrlImagem" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="hidden-xs hidden-sm" style="display:inline-flex;padding-top: 10px;padding-bottom: 10px;padding-left: 10px;">
                  <img src="/images/ENTREGA2.png" style="height: 35px;padding-right: 10px;" /> <span style="padding-right: 5px;color:#B91F1C;font-size: 18px;">Entrega</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right: 10px;margin-top: 10px;" />
                  <img src="/images/MENSAGEM2.png" style="height: 35px;padding-right: 10px;" />  <span style="padding-right: 5px;font-size: 18px;color:#B91F1C;">Mensagem</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right:10px;margin-top: 10px;" />
                  <img src="/images/CIFRÃO.png" style="height: 35px;padding-right: 10px;" />  <span style="padding-right: 5px;font-size: 18px;">Pagamento</span>
                  <img src="/images/seta.png" style="height: 10px;padding-left: 5px;padding-right: 10px;margin-top: 10px;" />
                  <img src="/images/CONCLUIDO.png" style="height: 35px;" />  <span style="font-size: 18px;">Concluído</span>
                </div>
                <div class="hidden-md hidden-lg" style="display:inline-flex;padding-top: 10px;padding-bottom: 10px;padding-left: 0px;">
                  <img src="/images/ENTREGA2.png" style="height: 20px;padding-right: 3px;" /> <span style="padding-right: 2px;color:#B91F1C;font-size: 12px;">Entrega</span>
                  <img src="/images/seta.png" style="height: 5px;padding-left: 3px;padding-right: 3px;margin-top: 5px;" />
                  <img src="/images/MENSAGEM2.png" style="height: 20px;padding-right: 5px;" />  <span style="padding-right: 3px;color:#B91F1C;font-size: 12px;">Mensagem</span>
                  <img src="/images/seta.png" style="height: 5px;padding-left: 3px;padding-right:3px;margin-top: 5px;" />
                  <img src="/images/CIFRÃO.png" style="height: 20px;padding-right: 3px;" />  <span style="padding-right: 3px;font-size: 12px;">Pagamento</span>
                  <img src="/images/seta.png" style="height: 5px;padding-left: 3px;padding-right: 3px;margin-top: 5px;" />
                  <img src="/images/CONCLUIDO.png" style="height: 20px;" />  <span style="font-size: 12px;">Concluído</span>
                </div>
                <div class="box_content">
                  <h3 v-if="logintopo != ''" class="titulo-principal text-center mb-5">{{ logintopo[0].itiNome }}</h3>
                  <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco" style="display: none;">
                    <input v-model="Formulario.NomeDe" type="text" class="form-control form-control-icone" maxlength="100"
                      placeholder="Quem Envia" onfocus="this.placeholder=''" onblur="this.placeholder='Quem Envia'">
                  </div>
                  <div class="input-group mb-3" style="display: none;">
                    <input v-model="Formulario.NomePara" type="text" class="form-control form-control-icone" maxlength="100"
                      placeholder="Quem Recebe" onfocus="this.placeholder=''" onblur="this.placeholder='Quem Recebe'">
                  </div>
                  <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco">
                    <textarea v-model="Formulario.Mensagem" type="text" class="form-control form-control-icone" rows="6" cols="12" 
                      placeholder="Mensagem (*Não esqueça de assinar)" onfocus="this.placeholder=''" onblur="this.placeholder='Mensagem (*Não esqueça de assinar)'" maxlength="800"
                      @focus="limparPlaceholder" @blur="restaurarPlaceholder" @input="limitarCaracteres"></textarea>
                  </div>
                  <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco">
                    <br><small>{{ contadorCaracteres }}/800 caracteres</small>
                  </div>
                  <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco && mensagens!='' && mensagens!=null">
                    <br><button class="btn btn-info" @click.prevent="dialog = true">Ver sugestões de mensagens</button>
                  </div>
                  <v-col cols="12" md="12" style="display: inline-flex;">
                    <v-checkbox v-model="EnviarCartaoBranco" label="Enviar Cartão em Branco" @change="preencherEnviarCartaoBranco"></v-checkbox>
                    <v-checkbox v-model="SemCartao" label="Não Desejo Cartão" @change="preencherSemCartao"></v-checkbox>
                  </v-col>
                  <!-- <button class="btn btn-success w-100 mt-4">Fazer Login</button> -->
                  <button v-if="!AbreSenha && !AbreCad && logintopo != ''" @click="ArmazenaMensagem()"
                    class="btn btn-success w-100 mt-4">{{ logintopo[0].itiTextoBtn1 }}</button>
                </div>
              </div>
            </div>
            <v-row justify="center">
            <v-dialog v-model="dialog" class="controlcel">
              <v-card style="height: 600px;overflow-y: scroll;">
                <v-card-title>
                  <span class="text-h5">Sugestões de Mensagens</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                  <v-container>
                    <v-row v-for="(mens, index) in mensagens" :key="mens.itiId" :class="index % 2 === 0 ? 'white-row' : 'light-gray-row'">
                      <v-col cols="12" sm="8" md="8" >
                        <h3 style="font-size: 20px;">{{ mens.itiNome }}</h3>
                        <p v-html="mens.itiResumo1"></p>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="d-flex align-center">
                        <v-btn variant="text" @click="updateMensagem(mens.itiResumo1);dialog = false" class="btn btn-info" style="color:#ffffff;">
                          Escolher
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black" variant="text" @click="dialog = false">
                    Fechar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          </div>
         
        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1, SHA256 } from 'crypto-js';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';
import { useHead } from '@vueuse/head';

export default {
  name: 'MensagemPedidoView',
  components: {
    CheckAcesso,
  },
  data: () => ({
    logintopo: [],
    Formulario: {
      NomeDe: '',
      NomePara: '',
      Mensagem: '',
    },
    AbreSenha: false,
    AbreCad: false,
    contadorCaracteres: 0,
    EnviarCartaoBranco: false,
    SemCartao: false,
    dialog: false,
    mensagens:[],
    set: 'apple',

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Mensagem - Cestas Porto Alegre'
			})
		},
    inserirEmoji(emoji) {
      // Insira o emoji selecionado no campo de mensagem
      this.Formulario.Mensagem += emoji.native;
    },
    updateMensagem(value) {
      // Usando a propriedade innerText para obter apenas o texto sem HTML
      this.Formulario.Mensagem = this.stripHTML(value);
    },
    stripHTML(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    preencherEnviarCartaoBranco(){
      this.SemCartao = false;
      this.Formulario.Mensagem = "";
      this.Formulario.NomeDe = "";
      this.Formulario.NomePara = "";
      this.contadorCaracteres = 0;
    },
    preencherSemCartao(){
      this.EnviarCartaoBranco = false;
      this.Formulario.Mensagem = "";
      this.Formulario.NomeDe = "";
      this.Formulario.NomePara = "";
      this.contadorCaracteres = 0;
    },
    limitarCaracteres() {
      if (this.Formulario.Mensagem.length > 800) {
        this.Formulario.Mensagem = this.Formulario.Mensagem.substring(0, 800);
      }
      this.contadorCaracteres = this.Formulario.Mensagem.length;
    },
    limparPlaceholder(event) {
      event.target.placeholder = '';
    },
    restaurarPlaceholder(event) {
      event.target.placeholder = 'Mensagem (*Não esqueça de assinar)';
    },
    ArmazenaMensagem(){
     /* if (this.SemCartao == false && this.EnviarCartaoBranco == false && this.Formulario.NomeDe == '') {
        this.$mensagemAviso("Por favor preencha o Nome de quem envia a cesta.");
      }
      else*/
      if (this.SemCartao == false && this.EnviarCartaoBranco == false && this.Formulario.Mensagem == ""){
        this.$mensagemAviso("Por favor preencha a mensagem ou selecione as opções sem mensagem.");
      }
      else {
        let _mensagem = {
          NomeDe: this.Formulario.NomeDe,
          NomePara: this.Formulario.NomePara,
          Mensagem: this.Formulario.Mensagem,
          SemCartao: this.SemCartao,
          CartaoBranco: this.EnviarCartaoBranco
        }

        if (this.SemCartao == true){
          _mensagem.Mensagem = "Sem Cartão";
          _mensagem.NomeDe = "";

        }
        if (this.EnviarCartaoBranco == true){
          _mensagem.Mensagem = "Enviar Cartão em Branco"
          _mensagem.NomeDe = "";
        }

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (dadosArmazenados) {
          dadosArmazenados = JSON.parse(dadosArmazenados);
          dadosArmazenados.mensagem = [];
          dadosArmazenados.mensagem.push(_mensagem);
          localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
          this.$router.push({ path: '/PagamentoMercadoPago', replace: true });
        }
      }
    },
  },
  created() {
    this.carregaMetas();
    
    axios.get('/Process/InsInstitucional/institucional/26/textomensagem')
      .then(response => {
        this.logintopo = response.data;
       // console.log('this.logintopo')
       // console.log(this.logintopo)
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/28/cadastromensagens')
      .then(response => {
        this.mensagens = response.data;
       // console.log('this.logintopo')
       // console.log(this.logintopo)
      })
      .catch(error => {
        console.error(error);
      });


      

      let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.mensagem != ''){
                      
                      this.EnviarCartaoBranco = dadosArmazenados.mensagem[0].CartaoBranco;
                      this.SemCartao = dadosArmazenados.mensagem[0].SemCartao;
                      console.log(this.EnviarCartaoBranco)
                      console.log( this.SemCartao)
                      if (this.SemCartao == "true" || this.EnviarCartaoBranco == "true"){
                        this.Formulario.Mensagem = "";
                        this.Formulario.NomeDe = "";
                        this.Formulario.NomePara = "";
                      }
                      else{
                        this.Formulario.Mensagem = dadosArmazenados.mensagem[0].Mensagem;
                        this.Formulario.NomeDe = dadosArmazenados.mensagem[0].NomeDe;
                        this.Formulario.NomePara = dadosArmazenados.mensagem[0].NomePara;
                      }
                    }
                    
                }
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

.white-row {
  background-color: #fff; /* cor branca */
}

.light-gray-row {
  background-color: #f2f2f2; /* cor cinza claro */
}

@media (max-width:767px) {
  .controlcel {
  width:'100%';
}
}

@media (min-width:1200px) {
  .controlcel {
  width:'50%' !important;
}
}

.emoji-bar {
  display: flex;
  justify-content: center;
}

</style>
