<template>
    <div>
        <main class="main">
            <section class="main-carrinho" style="background-color: #f7e7db;padding-bottom: 60px;">
                    <div class="container">
                        <div class="row py-5">
                            <!--div class="col-lg-6 hidden-xs hidden-sm">
                                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                                    v-if="textoCarrinho != ''">
                                    <img v-if="textoCarrinho[0].insInstitucionalImagens != ''"
                                        :src="$imgURL + textoCarrinho[0].insInstitucionalImagens[0].itgUrlImagem" />
                                </div>
                            </!--div-->
                            <div class="col-lg-8">
                                <div class="box_content">
                                    <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">{{
                                        textoCarrinho[0].itiNome }}</h3>
                                    <table class="table table-cart">
                                        <thead>
                                            <tr class="text-center">
                                                <th scope="col-8">Produto</th>
                                                <th scope="col-1 hidden-xs hidden-sm"></th>
                                                <th scope="col-2">Valor</th>
                                                <th scope="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="table-cart-line" v-for="(itemp, index) in DadosCompra.produtos"
                                                :key="index">
                                                <td class="d-flex align-items-left" style="text-align: center;">
                                                    <img class="img-cart img-fluid"
                                                        style="border:1px solid #cccccc;height: 80px;width: auto;"
                                                        :src="$imgURL + itemp.proProdutosImagens[0].pimUrlImagem">
                                                        <div class="align-items-left" style="text-align: left;">
                                                            <h6 class="texto-destaque">{{ itemp.proNomeProduto }}</h6>
                                                            <span style="font-size: 13px;">Ref: {{ itemp.proReferencia }}</span>
                                                            <v-text-field maxlength="40" class="hidden-md hidden-lg" style="margin-top: 20px;" v-if="itemp.proCampoExtra1 == '1'" type="text" label="Texto Balão" variant="outlined" v-model="itemp.proCampoExtra2"></v-text-field>
                                                            <button class="btn btn-block btn-default hidden-md hidden-lg" @click.prevent="carregaProdutosAvulsos(itemp.proId)" style="border:1px solid #cccccc;border-radius: 5px;">+ Adicionar Produto</button>
                                                        </div>
                                                </td>
                                                <td class="table-cart-data hidden-xs hidden-sm"
                                                    style="width:30%;text-align: center;">
                                                    <v-text-field maxlength="40" v-if="itemp.proCampoExtra1 == '1'" type="text" autocomplete="off" label="Texto Balão" variant="outlined" v-model="itemp.proCampoExtra2"></v-text-field>
                                                    <button class="btn btn-block btn-default" @click.prevent="carregaProdutosAvulsos(itemp.proId)" style="border:1px solid #cccccc;border-radius: 5px;">+ Adicionar Produto</button>
                                                </td>
                                                <td class="table-cart-data"
                                                    style="width:20%;text-align: center;font-size: 13px;">R$ {{
                                                        formatarValor(itemp.proProdutosPrecos[0].ppcValorPor) }}</td>
                                                <td class="table-cart-data">
                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" @click.prevent="ExcluiProduto(index)">
                                                        <i class="fa fa-window-close" style="color: black;font-size: 13px;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr class="table-cart-line" v-for="(itemp, index) in DadosCompra.avulsos" :key="index">
                                                <td class="d-flex align-items-center" style="text-align: center;">
                                                    <img class="img-cart img-fluid"
                                                        style="border:1px solid #cccccc;height: 80px;width: auto;"
                                                        :src="$imgURL + itemp.proProdutosAvulsosImagens[0].paiUrlImagem">
                                                        <div style="text-align: left;">
                                                            <h6 class="texto-destaque">{{ itemp.praNomeProduto }}</h6>
                                                            <span style="font-size: 13px;">Ref: {{ itemp.praReferencia }}</span>
                                                            <v-text-field maxlength="40" v-if="itemp.praDestaque" class="hidden-md hidden-lg" autocomplete="off" style="margin-top: 20px;" type="text" label="Texto Balão" variant="outlined" v-model="itemp.praCampoExtra1"></v-text-field>
                                                            <button v-if="itemp.praAceitaImg" class="btn btn-block btn-default hidden-md hidden-lg" @click.prevent="openModalArtes(index)" style="border:1px solid #cccccc;border-radius: 5px;">Selecionar Arte</button>
                                                            <button v-if="itemp.praAceitaImg" class="btn btn-block btn-default hidden-md hidden-lg" @click.prevent="openModal(index)" style="border:1px solid #cccccc;border-radius: 5px;">Incluir Foto</button>
                                                            <div class="hidden-md hidden-lg" style="display: inline-flex;padding-top: 3px;padding-left: 5px;">
                                                                <img v-if="itemp.praCampoExtra2!=''" :src="$imgURL + itemp.praCampoExtra2" style="height: 50px;padding-right: 5px;" class="mx-auto" />
                                                                <img v-if="itemp.praCampoExtra3 !=''" :src="$imgURL + itemp.praCampoExtra3" style="height: 50px;" class="mx-auto" />
                                                            </div>
                                                        </div>
                                                </td>
                                                <td class="table-cart-data hidden-xs hidden-sm" 
                                                    style="width:20%;text-align: center;font-size: 13px;">
                                                    <v-text-field maxlength="40" v-if="itemp.praDestaque" autocomplete="off" style="margin-top: 20px;" type="text" label="Texto Balão" variant="outlined" v-model="itemp.praCampoExtra1"></v-text-field>
                                                    <button v-if="itemp.praAceitaImg" class="btn btn-block btn-default" @click.prevent="openModalArtes(index)" style="border:1px solid #cccccc;border-radius: 5px;">Selecionar Arte</button>
                                                    <button v-if="itemp.praAceitaImg" class="btn btn-block btn-default" @click.prevent="openModal(index)" style="border:1px solid #cccccc;border-radius: 5px;">Incluir Foto</button>
                                                    <div style="display: inline-flex;padding-top: 3px;padding-left: 5px;">
                                                        <img v-if="itemp.praCampoExtra2!=''" :src="$imgURL + itemp.praCampoExtra2" style="height: 50px;padding-right: 5px;" class="mx-auto" />
                                                        <img v-if="itemp.praCampoExtra3 !=''" :src="$imgURL + itemp.praCampoExtra3" style="height: 50px;" class="mx-auto" />
                                                    </div>
                                                </td>
                                                <td class="table-cart-data"
                                                    style="width:20%;text-align: center;font-size: 13px;">R$ {{
                                                        formatarValor(itemp.praValorPor) }}</td>
                                                <td class="table-cart-data">
                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir"
                                                        @click.prevent="ExcluiAvulso(index)">
                                                        <i class="fa fa-window-close"
                                                            style="color: black;font-size: 13px;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hidden-xs hidden-sm">
                                        <div class="mb-4 d-flex align-items-center">
                                            <v-text-field v-model="numcupom" type="text" label="Cupom Desconto" variant="outlined" cols="12" md="3" class="mr-2" style="margin: 0px; padding-bottom: 0px;height: 50px;"></v-text-field>
                                            <button class="btn btn-outline-success mr-2" @click="validarCupom(numcupom)" style="min-width: 200px !important;">Atualizar</button>
                                            <a href="#" @click.prevent="openModal2()" class="d-inline-flex align-items-center">
                                                <img src="/images/ENTREGA.png" style="height: 35px;padding-right: 10px;" />
                                                <span style="padding-right: 5px;font-size: 18px;color:#333333;">Calcule Frete</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="mb-4 align-items-center hidden-md hidden-lg">
                                        <v-text-field v-model="numcupom" type="text" label="Cupom Desconto" variant="outlined" cols="12" md="3" class="mr-2" style="margin: 0px; margin-bottom: 20px;height: 50px;"></v-text-field>
                                        <button class="btn btn-outline-success mr-2" @click="validarCupom(numcupom)" style="width: 100% !important;">Atualizar</button>
                                        <a href="#" @click.prevent="openModal2()" class="d-inline-flex align-items-center" style="padding-top:20px;">
                                            <img src="/images/ENTREGA.png" style="height: 35px;padding-right: 10px;" />
                                            <span style="padding-right: 5px;font-size: 18px;color:#333333;">Calcule Frete</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="posicao2">
                                    <div class="box_content">
                                        <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">Resumo</h3>
                                        <div v-if="valorTotalProdutos != ''"
                                            class="d-flex align-items-center justify-content-between mb-1"
                                            style="padding-right:5px;">
                                            <h6 class="texto-destaque mb-0">Valor Produtos:</h6>
                                            <h6 class="texto-destaque text-center mb-0">R$ {{ formatarValor(valorTotalProdutos) }}</h6>
                                        </div>
                                        <div v-if="ValorFreteFront > 0"
                                            class="d-flex align-items-center justify-content-between mb-1"
                                            style="padding-right:5px;">
                                            <h6 class="texto-destaque mb-0">Entrega: </h6>
                                            <h6 class="texto-destaque text-center mb-0">R$ {{ formatarValor(ValorFreteFront) }}</h6>
                                        </div>
                                        <div v-if="ValorCupom > 0"
                                            class="d-flex align-items-center justify-content-between mb-1"
                                            style="padding-right:5px;">
                                            <h6 class="texto-destaque mb-0">Cupom Desconto:</h6>
                                            <h6 class="texto-destaque text-center mb-0" style="color:red;">- R$ {{ formatarValor(ValorCupom) }}</h6>
                                        </div>
                                        <div class="border-bottom mb-1"></div>
                                        <div v-if="valorTotal != ''"
                                            class="d-flex align-items-center justify-content-between mb-2"
                                            style="padding-right:5px;">
                                            <h6 class="texto-destaque mb-0" style="font-size: 20px;">Total:</h6>
                                            <h6 class="texto-destaque text-center mb-0" style="font-size: 20px;">R$ {{ formatarValor(valorTotal) }}</h6>
                                        </div>
                                    </div>
                                    <div class="box_content-footer">
                                        <div class="mt-2" style="width: 100%;" v-if="textoCarrinho != ''">
                                            <button @click="AvancarEndereco()"
                                                class="btn btn-success w-100 mb-1">{{
                                                    textoCarrinho[0].itiTextoBtn1 }}</button><br>
                                            <router-link to="/cestasporcategoria"
                                                class="btn btn-outline-danger mb-3" style="width: 100%;">{{
                                                    textoCarrinho[0].itiTextoBtn2 }}</router-link>
                                        
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
            </section>
        </main>
        <!--modal imagem produto avulso-->
        <v-row justify="center">
                        <v-dialog v-model="dialog" class="controlcel">
                        <v-card style="height: 600px;">
                            <v-card-text style="padding: 16px 0px 10px">
                            <v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <span class="text-h5">Adicione uma imagem (Opcional - formato Quadrada)</span>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input v-model="selectedFile" label="Imagem" variant="underlined"
                                                prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="UrlImagem !== '' && UrlImagem !== null">
                                            <img :src="$imgURL + UrlImagem" style="height;: 350px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                                <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="6" v-if="UrlImagem==''">
                                            <a href="#"  variant="text" @click.prevent="salvarImagem();" class="btn btn-success px-md-5 mt-0 mb-3" style="color:#ffffff;">
                                                Salvar Imagem
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="black" variant="text" @click="dialog = false">
                                Cancelar
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
        </v-row>
        <!--modal calculo do frete-->
    <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialogfrete" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialogfrete = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title style="padding: 5px;padding-top: 15px;">
                   <div style="display: inline-flex;">
                    <img src="/images/ENTREGA.png" style="height: 35px;padding-right: 10px;" />
                    <span style="padding-right: 5px;font-size: 18px;color:#333333;padding-top: 5px;">Calcule Frete</span>
                </div> 
                </v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" v-if="Estados.length > 1">
                                        <v-select v-model="selectedEstados" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(selectedEstados)"
                                            item-title="cetNome" item-value="cetId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select-wrapper>
                                            <small>Selecione Cidade</small>
                                            <select class="styled-select" v-model="selectedCidades" @change="CarregaBairros(selectedCidades)" style="cursor: pointer;">
                                                 <option v-for="item in Cidades" :key="item.cidNome" :value="item.cidId">{{ item.cidNome }}</option>
                                            </select>
                                            <span class="caret"></span>
                                        </v-select-wrapper>
                                    </v-col>
                                    <v-col cols="12" md="6" >
                                        <v-select-wrapper v-if="Bairros!='' && Bairros!=null">
                                            <small>Selecione Bairro</small>
                                            <select class="styled-select" v-model="selectedBairros" @change="BuscaValor(2)" style="cursor: pointer;">
                                                 <option v-for="item in Bairros" :key="item.baiNome" :value="item.baiId">{{ item.baiNome }}</option>
                                            </select>
                                            <span class="caret"></span>
                                        </v-select-wrapper>
                                    </v-col>
                                    <v-col>
                                         <span v-for="val in ValorFrete" :key="val.eftId" class="fonttext">
                                           {{ TextoFrete }} {{ val.eftTipoEntrega }} ( R$ {{  formatarValor(val.eftValorFrete) }} )
                                        </span><br>
                                        <span class="fonttext" v-if="mostraCidBai">
                                           - Sem hora marcada: Frete Grátis (Carro)
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                       <p style="text-align: justify;" class="fonttext">Para garantir maior qualidade nossas entregas são feitas de carro e entregues em mãos. Não embutimos o valor do frete 
                                          no valor dos proutos. É possível retirar na loja para não ter custo do frete.</p>
                                    </v-col>
                                    <v-col style="text-align: center;">
                                        <button class="btn btn-success" @click.prevent="FinalizarCalculoFrete()" style="min-width: 70px !important">Concluir</button>
                                    </v-col>
                                </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>

    <!--modal escolher arte-->
    <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialogArtes" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <!-- Toolbar fixo -->
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialogArtes = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="ListaArtesCateg!=''" style="padding: 5px;padding-top: 5px;">
                    <v-select-wrapper>
                        <select class="styled-select fontstyle" v-model="CategArteSelected" @change="FiltraCategArtes()" style="cursor: pointer;">
                            <option value="" disabled selected>Selecione Categoria</option>
                            <option v-for="item in ListaArtesCateg" :key="item.sgiId" :value="item.sgiId">{{ item.sgiNomeSegmento }}</option>
                        </select>
                        <span class="caret"></span>
                    </v-select-wrapper>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn variant="text" class="btn btn-success" @click="GravarArtes();" style="font-size:15px;color: #fff;
    background-color: #218838;
    border-color: #1e7e34;">Concluir</v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                <v-item-group v-model="ArteSelected">
                    <v-container>
                    <v-row>
                        <v-col v-for="mens in artes" :key="mens.itiId" cols="6" md="3">
                        <v-item>
                            <v-card 
                            class="d-flex align-center" 
                            dark height="230" 
                            @click="toggle(mens)"
                            :class="{ 'border-green': ArteSelected === mens }"
                            >
                            <div class="flex-grow-1 text-center" style="justify-content: center;">
                                <img :src="$imgURL + mens.insInstitucionalImagens[0].itgUrlImagem" style="height: 150px;" class="mx-auto" />
                                {{ mens.itiNome }}
                            </div>
                            <v-icon v-if="ArteSelected === mens" class="check-icon">mdi-check</v-icon>
                            </v-card>
                        </v-item>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-item-group>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>

    <!--modal adiciona produtos avulsos-->
    <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialogProdutosAvulsos" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <!-- Toolbar fixo -->
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialogProdutosAvulsos = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="title text-center">Adicione produtos avulsos e incremente sua cesta!</h2>
                <v-spacer></v-spacer>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                <v-item-group>
                    <v-container>
                    <v-row>
                        <div class="cat-blocks-container">
                            <div class="row">
                                <div class="col-6 col-sm-4 col-lg-2" v-for="itema in ProdutoAvulsosModal" :key="itema.papId">
                                    <a href="#" @click="GravarProdutos(itema)" class="cat-block">
                                        <figure v-if="itema.proProdutosAvulso.proProdutosAvulsosImagens!=''">
                                            <span>
                                                <img :src="$imgURL + itema.proProdutosAvulso.proProdutosAvulsosImagens[0].paiUrlImagem" alt="">
                                            </span>
                                        </figure>
        
                                        <h3 class="cat-block-title">{{itema.proProdutosAvulso.praNomeProduto}}</h3>
                                        <span class="new-price" style="font-size: 14px;">R$ {{ formatarValor(itema.proProdutosAvulso.praValorPor) }}</span><!-- End .cat-block-title -->
                                          <span class="new-price" style="font-size: 14px;">Adicionar</span>
                                    </a>
                            </div><!-- End .row -->
                            </div>
                        </div><!-- End .cat-blocks-container -->
                    </v-row>
                    </v-container>
                </v-item-group>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>

    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';

export default {
    name: 'CarrinhoView',
    components: {
    },
    data: () => ({
        textoCarrinho: [],
        ValorCupom: 0,
        numcupom: '',
        Produto: [],
        ProdutoImg: [],
        infocall: [],
        valorTotal: 0,
        vProdutosExtras: [],
        ProdutoAvulsos: [],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        Cupons:[],
        CuponsProdutos:[],
        CuponsSegmentos:[],
        CuponsCliente:[],
        dialog: false,
        artes:[],
        ArteSelected: '',
        selectedFile: null,
        UrlImagem:'',
        ArteSelectedImg: '',
        controleindex:'',
        valorTotalProdutos:'',
        valorEntregas:'',
        //frete
        dialogfrete:false,
        Estados: [],
        selectedEstados:null,
        Cidades: [],
        selectedCidades:null,
        Bairros: [],
        selectedBairros:null,
        ValorFrete:[],
        TextoFrete:'',
        ValorFreteFront: 0,
        mostraCidBai:false,
        //artes
        dialogArtes: false,
        artes:[],
        artesGeral:[],
        ArteSelected: '',
        ArteSelectedImg: '',
        controleindex:'',
        ListaArtesCateg:[],
        CategArteSelected:'',
        //avulsos
        ProdutoAvulsosModal: [],
        dialogProdutosAvulsos:false,
    }),
    methods: {
        openModal(value){
            this.controleindex = value;
            this.dialog = true;
            this.UrlImagem = this.DadosCompra.avulsos[this.controleindex].praCampoExtra3;
        },
        carregaMetas() {
            useHead({
                title: 'Carrinho - Cestas Porto Alegre'
            })
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async salvarImagem(){
            if (this.selectedFile != '' && this.selectedFile != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.UrlImagem = uploadSuccess.data.nameFile;
                        this.DadosCompra.avulsos[this.controleindex].praCampoExtra3  = this.UrlImagem;
                        this.selectedFile = null;
                        this.dialog = false;
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                        valorAvulsos += item.praValorPor;
                        vcount++;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                        valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                        vcount++;
                    });
                }
                this.valorTotalProdutos = (valorProdutos + valorAvulsos);
                console.log('aqui')
                console.log(this.ValorFreteFront)
                this.valorTotal = (valorProdutos + valorAvulsos + this.ValorFreteFront)- this.ValorCupom;
                this.$store.commit('updateVariavelHeader2', vcount);
            }
            else{
                this.$store.commit('updateVariavelHeader2', '');
            }
           
        },
        ExcluiAvulso(index) {
           // Recupere os dados do carrinho do localStorage
            let dadosArmazenados = JSON.parse(localStorage.getItem(this.$DadosCompra)) || { avulsos: [] };

            // Verifique se o índice a ser removido está dentro dos limites do array
            if (index >= 0 && index < dadosArmazenados.avulsos.length) {
                // Remova o produto do array
                dadosArmazenados.avulsos.splice(index, 1);

                // Atualize os dados no localStorage
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));

                // Atualize a exibição do carrinho
                this.DadosCompra.avulsos = [...dadosArmazenados.avulsos];
                this.calculatotal();
            } else {
                console.error("Índice inválido ou produto não encontrado.");
            }
        },
        ExcluiProduto(index) {
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.produtos.splice(index, 1);
              
                if (dadosArmazenados.produtos.length == 0) {
                   // Limpar o array 'avulsos'
                    dadosArmazenados.avulsos = [];
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$router.go(0);
                    this.$mensagemAviso("Carrinho vazio, escolha cesta e adicione no carrinho.")
                }
                else{
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.DadosCompra.produtos = [...dadosArmazenados.produtos];
                }
                this.calculatotal();
            }
        },
        async validarCupom(value){
           if (this.numcupom == ''){
            this.$mensagemAviso("Digite Código do cupom.")
           }
           else{
               if (this.Cupons.length > 0){
                 let _cupomresult = this.Cupons.filter(x=> x.cupCodigo == value);

                  if (_cupomresult != ''){
                    
                    let _cupomvalido = false;

                    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                     if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);


                    //valida se o cupom é específico para algum produto
                       await axios.get('/Process/CupCuponsProdutos/GetAllByCupId/' + _cupomresult[0].cupId + '/0' )
                        .then(response => {
                            this.CuponsProdutos = response.data;
                            //console.log('this.CuponsProdutos2')
                            //console.log(response.data)
                           // console.log(_cupomresult[0].cupId)
                            if (this.CuponsProdutos!= ''){
                                if (dadosArmazenados.produtos !='')
                                {
                                    let dadosprod = dadosArmazenados.produtos;
                                        dadosprod.forEach(element => {
                                            const _validaproduto = this.CuponsProdutos.filter(x => x.proId == element.proId);
                                            
                                            if (_validaproduto.length > 0) {
                                                //console.log('produtoscupom')
                                                _cupomvalido = true;
                                            }
                                        });
                                }
                                }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                    //valida se o cupom e específico para algum segmento
                      await axios.get('/Process/CupCuponsSegmentos/GetAllByCupId/' + _cupomresult[0].cupId + '/0' )
                        .then(response => {
                            this.CuponsSegmentos = response.data;
                            //console.log('this.CuponsSegmentos')
                           // console.log(this.CuponsSegmentos)
                            if (this.CuponsSegmentos!= ''){
                           if (dadosArmazenados.produtos !='')
                           {
                            let dadosseg = dadosArmazenados.produtos;
                            dadosseg.forEach(element => {
                                if (Array.isArray(element.proProdutosSegmentos)) {
                                    const segmentosValidos = element.proProdutosSegmentos.filter(segmento => {
                                        if (segmento.proSegmentos && this.CuponsSegmentos.some(cupomSegmento => cupomSegmento.psgId === segmento.proSegmentos.psgId)) {
                                            return true;
                                        }
                                        return false;
                                    });

                                    if (segmentosValidos.length > 0) {
                                       // console.log('segmentoscupom');
                                        _cupomvalido = true;
                                    }
                                }
                            });

                            }
                          }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                    //valida se o cupom e específico para algum cliente                      
                            await axios.get('/Process/CupCuponsClientes/GetAllByCupId/' + _cupomresult[0].cupId + '/0')
                                .then(response => {
                                    this.CuponsCliente = response.data;
                                    //console.log('this.CuponsCliente')
                                    //console.log(this.CuponsCliente)
                                    if (this.CuponsCliente.length > 0){
                                       
                                        const cookieData = Cookies.get(this.$CookieCliente);
                                            if (cookieData) {
                                                const _dadosRecuperados = JSON.parse(cookieData);
                                               // console.log('_dadosRecuperados.ususarioId')
                                               // console.log(_dadosRecuperados.ususarioId)
                                                
                                                if (this.CuponsCliente.filter(x=> x.cliId == _dadosRecuperados.usuarioId).length > 0){
                                                   // console.log('clientescupom')
                                                    _cupomvalido = true;
                                                }
                                            }
                                    }
                                   
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                   

                      if (this.CuponsProdutos.length == 0 && this.CuponsSegmentos.length == 0 && this.CuponsCliente.length == 0 ) {
                            //console.log('geralcupom')
                            _cupomvalido = true;
                        }

                        if (_cupomvalido){

                           var valorAvulsos = 0;
                            var valorProdutos = 0;
                            if (dadosArmazenados.avulsos != '') {
                                dadosArmazenados.avulsos.forEach(item => {
                                    valorAvulsos += item.praValorPor;
                                });
                            }

                            if (dadosArmazenados.produtos != '') {
                                dadosArmazenados.produtos.forEach(item => {
                                    valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                                });
                            }

                            if (_cupomresult[0].cupTipoValor == 0){
                                this.ValorCupom = ((valorAvulsos + valorProdutos)*_cupomresult[0].cupValor)/100;
                                this.calculatotal();
                            }
                            else{
                                this.ValorCupom = _cupomresult[0].cupValor;
                                this.calculatotal();
                            }
                        }
                        else{
                            this.$mensagemAviso("Digite Código de cupom válido.")
                            this.ValorCupom = 0;
                            this.numcupom = '';
                            this.calculatotal();
                            }
                    }

                  } else{
                        this.$mensagemAviso("Digite Código de cupom válido.")
                        this.ValorCupom = 0;
                        this.calculatotal();
                  }
               }
               else{
                this.$mensagemAviso("Digite Código de cupom válido.")
                this.ValorCupom = 0;
                this.calculatotal();
               }
           }
        },
        AvancarEndereco(){
            if (this.numcupom != '' && this.ValorCupom != 0){
                if (this.DadosCompra.avulsos.filter(x=>x.praAceitaImg && (x.praCampoExtra2 == '' || x.praCampoExtra2 == null)).length > 0){
                    this.$mensagemAviso("Selecione uma arte.")
                }
                else if (this.DadosCompra.avulsos.filter(x=>x.praDestaque && (x.praCampoExtra1 == '' || x.praCampoExtra1 == null)).length > 0){
                    this.$mensagemAviso("Adicione Texto ao produto.")
                }
                else if (this.DadosCompra.produtos.filter(x=> x.proCampoExtra1 == '1' && (x.proCampoExtra2 == null || x.proCampoExtra2 == '')).length > 0){
                    this.$mensagemAviso("Adicione Texto ao balão.")
                }
                else{
                    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.cupom = [];
                        dadosArmazenados.cupom.push({numCupom: this.numcupom, ValorCupom: this.ValorCupom });
                        dadosArmazenados.avulsos = this.DadosCompra.avulsos;
                        dadosArmazenados.produtos = this.DadosCompra.produtos;
                        localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                        this.$router.push('/endereco');
                    }
                }
            }
            else{
                console.log("this.DadosCompra")
                console.log(this.DadosCompra)
                
                if (this.DadosCompra.avulsos.filter(x=>x.praAceitaImg && (x.praCampoExtra2 == '' || x.praCampoExtra2 == null)).length > 0){
                    this.$mensagemAviso("Selecione uma arte.")
                }
                else if (this.DadosCompra.avulsos.filter(x=>x.praDestaque && (x.praCampoExtra1 == '' || x.praCampoExtra1 == null)).length > 0){
                    this.$mensagemAviso("Adicione Texto ao produto.")
                }
                else if (this.DadosCompra.produtos.filter(x=> x.proCampoExtra1 == '1' && (x.proCampoExtra2 == null || x.proCampoExtra2 == '')).length > 0){
                    this.$mensagemAviso("Adicione Texto ao balão.")
                }
                else{
                    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.avulsos = this.DadosCompra.avulsos;
                    dadosArmazenados.produtos = this.DadosCompra.produtos;
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$router.push('/endereco').catch(err => console.error(err));
                }             
            }

        },
        DeleteImagem(){
            this.UrlImagem = "";
            this.DadosCompra.avulsos[this.controleindex].praCampoExtra3 = ""
        },
        //frete
        openModal2(){
        this.dialogfrete = true;
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                    const nomeA = a.cetNome || ""; 
                    const nomeB = b.cetNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    });
    
                    if (this.Estados.length == 1) {
                        this.selectedEstados = this.Estados[0].cetId;
                        this.CarregaCidades(this.selectedEstados)
                    }
                // console.log('this.Estados')
               // console.log(this.Estados)

                });
        },
        CarregaCidades(value) {
            this.ValorFrete = [];
            this.TextoFrete ="";
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                });
        },
        CarregaBairros(value) {
            this.ValorFrete = [];
            this.TextoFrete ="";
            this.selectedBairros = null;
            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                    const nomeA = a.baiNome || ""; 
                    const nomeB = b.baiNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    }); 
                    
                    if (this.Bairros.length == 0){
                      this.BuscaValor(1);
                    }
                });
        },
        FinalizarCalculoFrete(){
            let _agenda = {
                    Estado: this.selectedEstados,
                    Cidade: this.selectedCidades,
                    Bairro: this.selectedBairros,
                    ValorFrete: this.ValorFrete[0].eftValorFrete,
                }
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.agenda = [];
                    dadosArmazenados.agenda.push(_agenda);
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                }
                this.dialogfrete = false;
                this.ValorFreteFront = this.ValorFrete[0].eftValorFrete;
                this.calculatotal();
        },
        BuscaValor(value){
            this.ValorFrete = [];
            this.TextoFrete ="";
            if (value == 1){
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades);
                this.TextoFrete = "- Com hora marcada: "
            }
            else{
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades && x.baiId == this.selectedBairros);
                this.TextoFrete = "- Com hora marcada: "
            }
            if (this.selectedBairros == 42){
                this.mostraCidBai = true;
            }
            else{
                this.mostraCidBai = false;
            }
            
        },
        //artes
        openModalArtes(value){
            this.controleindex = value;
            this.dialogArtes = true;
        },
        FiltraCategArtes(){
          this.artes = this.artesGeral.filter(x=> x.insInstitucionalSegmentos.some(w=> w.insSegmentos.sgiId == this.CategArteSelected))
        },
        toggle(mes) {
            this.ArteSelected = mes
        },
        GravarArtes() {
        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        dadosArmazenados = JSON.parse(dadosArmazenados);
           console.log('dadosArmazenados.avulsos')
           console.log(dadosArmazenados.avulsos)
            if (this.ArteSelected == '' && dadosArmazenados.avulsos[this.controleindex].praCampoExtra2 == ''){
                this.$mensagemAviso("Selecione uma arte.")
            }
            else{      
                if (this.ArteSelected!=''){
                    dadosArmazenados.avulsos[this.controleindex].praCampoExtra2 = this.ArteSelected.insInstitucionalImagens[0].itgUrlImagem;
                    this.DadosCompra.avulsos = dadosArmazenados.avulsos;
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                }       
                this.controleindex = '';
                    this.dialogArtes=false;
               
            }
       },
        //avulsos
        carregaProdutosAvulsos(value){
            axios.get('/Process/ProAvulsosporProdutos/GetAllByProId/' + value)
                .then(response => {
                    this.ProdutoAvulsosModal = response.data.filter(x=> x.proProdutosAvulso!=null && x.proProdutosAvulso.proProdutosAvulsosImagens!=null);
                    this.ProdutoAvulsosModal = this.ProdutoAvulsosModal.sort((a, b) => {
                                                                const valorA = parseInt(a.proProdutosAvulso.praOrdem) || 0;
                                                                const valorB = parseInt(b.proProdutosAvulso.praOrdem) || 0;
                                                                return valorA - valorB;
                                                                })
                    if (this.ProdutoAvulsosModal!=''){
                        this.dialogProdutosAvulsos = true;
                    }
                    else{
                        this.$mensagemAviso("Não temos produtos avulso para esta cesta.")
                    }
                    
                })
                .catch(error => {
                    console.error(error);
                });
        },
        GravarProdutos(dados){
           this.AdicionaAvulso(dados)
        },
        AdicionaAvulso(produtoAvulsoAdicionado){
           let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.avulsos.push(produtoAvulsoAdicionado.proProdutosAvulso);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.DadosCompra.avulsos.push(produtoAvulsoAdicionado.proProdutosAvulso)
                this.calculatotal()
                this.dialogProdutosAvulsos = false;
            }
        },
    },
    created() {
        this.carregaMetas();

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
            this.$mensagemAviso("Carrinho vazio, escolha uma cesta e adicione no carrinho.")
            this.calculatotal();
            this.$router.push('/')
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            this.DadosCompra = dadosArmazenados;
            if (this.DadosCompra.produtos.length == 0) {
                this.$mensagemAviso("Carrinho vazio, escolha uma cesta e adicione no carrinho.")
                this.$router.push('/')
            }
            if (this.DadosCompra.cupom.length > 0){
                this.numcupom = this.DadosCompra.cupom[0].numCupom;
                this.ValorCupom = this.DadosCompra.cupom[0].ValorCupom;
            }

            if (this.DadosCompra.agenda.length > 0 && (this.DadosCompra.agenda[0].Horarios != '' && this.DadosCompra.agenda[0].Horarios != null && this.DadosCompra.agenda[0].Horarios !== undefined)){
                this.ValorFreteFront = parseFloat(this.DadosCompra.agenda[0].Horarios.Valor);
            }


            this.calculatotal();
        }

        axios.get('/Process/InsInstitucional/institucional/22/textocarrinho')
            .then(response => {
                this.textoCarrinho = response.data;
               // console.log('textocarrinho')
               // console.log(this.textoCarrinho)
            })
            .catch(error => {
                console.error(error);
            });

            axios.get('/Process/CupCupons/GetAll')
            .then(response => {
                this.Cupons = response.data.filter(x=> moment(x.cupDataInicial).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') 
                                                   && moment(x.cupDataFinal).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') 
                                                   && x.cupAtivo
                                                   && x.cupQtdeUso > 0);
                //console.log('Cupons')
               // console.log(this.Cupons)
            })
            .catch(error => {
                console.error(error);
            });

            axios.get('/Process/InsInstitucional/institucional/29/cadastroartes')
                .then(response => {
                    this.artesGeral = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
                    this.artes =this.artesGeral;
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsSegmentos/GetAll')
                .then(response => {
                    this.ListaArtesCateg = response.data.sort((a, b) => a.itiNome - b.itiNome);

                })
                .catch(error => {
                    console.error(error);
                });

            axios.get(`/Process/EntEntregaFrete/GetAll`)
                    .then(response => {
                        this.EntregaFrete = response.data;
                        console.log('EntregaFrete')
                     console.log(this.EntregaFrete)
                    });


           this.CarregaEstados(76);

    },
}
</script>

<style scoped>
@media (max-width:767px) {
.posicao2 {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:767px) {
.posicao2 {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:1200px) {
    .posicao2 {
    position:fixed; 
    padding-right: 10px;
    padding-top: 0px;
    z-index: 9;
    width: 28%;
}

.fontbtn{
    font-size: 15px;
}
}

.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}


@media (max-width:767px) {
    .v-dialog > .v-overlay__content {
    max-height: calc(100% - 128px);
    width: calc(100% - 48px);
    max-width: calc(100% - 486px);
    margin: 24px;
    display: flex;
    flex-direction: column;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 9px 15px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    min-width: 60px; 
    border-radius: 0;
    white-space: normal;
    transition: all 0.3s;
}
}

@media (min-width:767px) {
    .v-dialog > .v-overlay__content {
    max-height: calc(100% - 128px);
    width: calc(100% - 48px);
    max-width: calc(100% - 486px);
    margin: 24px;
    display: flex;
    flex-direction: column;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 9px 15px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    min-width: 60px; 
    border-radius: 0;
    white-space: normal;
    transition: all 0.3s;
}
}

@media (min-width:1200px) {

.v-dialog > .v-overlay__content {
    max-height: calc(100% - 128px);
    width: calc(100% - 48px);
    max-width: calc(100% - 486px);
    margin: 24px;
    display: flex;
    flex-direction: column;
}
}

.v-field__field {
    flex: 1 0;
    grid-area: field;
    position: relative;
    align-items: flex-start;
    display: flex;
    height: 47px;
}

</style>
