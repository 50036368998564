<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-carrinho" :style="{backgroundImage:'url(' +  $imgURL + logintopo.pfcUrlImgFundo + ')',backgroundSize: 'cover',minHeight: '500px'}" v-if="logintopo!=''">
        <section class="">
          <div class="container" style="padding-bottom: 60px;">
              <div class="row">
                <div class="col-lg-12" style="padding-top: 60px;">
                  <div class="row">
                    <div class="col-lg-6" style="padding-top: 60px;">
                      <div class="row">
                         <h3 style="color:#ffffff;">Ocorreu um problema!</h3>
                         <div class="col-lg-12">
                            <span style="color:#ffffff;font-size: 20px;">Não foi possível completar seu pagamento.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="box_content">
                          <!-- Dados do Cartão -->
                          <div id="statusScreenBrick_container"></div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1, SHA256 } from 'crypto-js';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';

export default {
  beforeRouteEnter(to, from, next) {
    let dadosArmazenados = localStorage.getItem('dadoscompraCestasPortoAlegre');
    dadosArmazenados = JSON.parse(dadosArmazenados);

    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.async = true;
    script.onload = () => {
      // O SDK foi carregado com sucesso, agora você pode inicializá-lo
      const mp = new MercadoPago('APP_USR-5f6e364d-88ee-463d-b453-88faef5c08ac', {
        locale: 'pt-BR',three_d_secure_mode: 'optional'
      });

      const bricksBuilder = mp.bricks();

       const renderStatusScreenBrick = async (bricksBuilder,paymentid,externalResourceURL,creq) => {
          const settings = {
            initialization: {
              paymentId: paymentid, // id do pagamento a ser mostrado
              additionalInfo: {
                externalResourceURL: externalResourceURL,
                creq: creq,
              },
            },
            callbacks: {
              onReady: () => {
                /*
                  Callback chamado quando o Brick estiver pronto.
                  Aqui você pode ocultar loadings do seu site, por exemplo.
                */
              },
              onError: (error) => {
                // callback chamado para todos os casos de erro do Brick
                console.error(error);
              },
            },
            customization: {
                  backUrls: {
                      'error': 'https://cestasportoalegre.com/PagamentoMercadoPago',
                  }
              },
            };
            window.statusScreenBrickController = await bricksBuilder.create(
            'statusScreen',
            'statusScreenBrick_container',
            settings,
            );  
          };

       renderStatusScreenBrick(bricksBuilder,dadosArmazenados.informacao[0].CodRetornoPgto, dadosArmazenados.informacao[0].ExternalResource,dadosArmazenados.informacao[0].Creq);
    };
    document.head.appendChild(script);
    next();
  },
  name: 'PedidoNegadoView',
  components: {
    CheckAcesso,
  },
  data: () => ({
    logintopo: [],
    Formulario: {
      NomeDe: '',
      NomePara: '',
      Mensagem: '',
    },
    AbreSenha: false,
    AbreCad: false,
  }),
  methods: {
  },
  async created() {
    await axios.get('/Process/ConConfigFormasPagamento/GetById/3')
        .then(response => {
          this.logintopo = response.data;
        })
        .catch(error => {
          console.error(error);
        });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color:#ffffff !important;
  font-size: 20px;
}
</style>
