<template>
  <div>
    <div v-if="contatotopo != '' && contatotopo[0].insInstitucionalImagens != ''" class="page-header text-center"
      :style="{ backgroundImage: 'url(' + $imgURL + contatotopo[0].insInstitucionalImagens[0].itgUrlImagem + ')' }">
      <div class="container">
        <h1 class="page-title">{{ contatotopo[0].itiNome }}</h1>
      </div><!-- End .container -->
    </div><!-- End .page-header -->
    <nav aria-label="breadcrumb" class="breadcrumb-nav">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">Fale Conosco</li>
        </ol>
      </div><!-- End .container -->
    </nav><!-- End .breadcrumb-nav -->


    <div class="page-content pt-6" style="padding-bottom:0px !important">
      <div class="container pb-3">
        <div class="row">
          <div class="col-md-4" v-if="contato!=''">
            <div class="contact-box text-center">
              <h3>Endereço</h3>

              <address><p v-html="contato[0].itiResumo1"></p></address>
            </div><!-- End .contact-box -->
          </div><!-- End .col-md-4 -->

          <div class="col-md-4" v-if="contato!=''">
            <div class="contact-box text-center">
              <h3>Contatos</h3>
              <p v-html="contato[0].itiResumo2"></p>
            </div><!-- End .contact-box -->
          </div><!-- End .col-md-4 -->

          <div class="col-md-4">
            <div class="contact-box text-center">
              <h3>Social</h3>

              <div class="social-icons social-icons-color justify-content-center">
                <a v-if="redes[0].itiNome!='' && redes[0].itiNome!=null" :href="redes[0].itiNome" class="social-icon social-facebook" title="Facebook" target="_blank"><i
                    class="icon-facebook-f"></i></a>
                <a v-if="redes[0].itiReferencia!='' && redes[0].itiReferencia!=null" :href="redes[0].itiReferencia" class="social-icon social-instagram" title="Instagram" target="_blank"><i
                    class="icon-instagram"></i></a>
                <a v-if="redes[0].itiCampoExtra1!='' && redes[0].itiCampoExtra1!=null" :href="redes[0].itiCampoExtra1" class="social-icon social-youtube" title="Youtube" target="_blank"><i
                    class="icon-youtube"></i></a>
                <a v-if="redes[0].itiCampoExtra2!='' && redes[0].itiCampoExtra2!=null" :href="redes[0].itiCampoExtra2" class="social-icon social-linkedin" title="Youtube" target="_blank"><i
                    class="icon-linkedin"></i></a>
              </div><!-- End .soial-icons -->
            </div><!-- End .contact-box -->
          </div><!-- End .col-md-4 -->
        </div><!-- End .row -->

        <hr class="mt-3 mb-5 mt-md-1">
        <div class="touch-container row justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="text-center" v-if="contato!=''">
              <h2 class="title mb-1">{{contato[0].itiNome}}</h2><!-- End .title mb-2 -->
              <p class="lead text-primary" style="color:#B91F1C !important">
                {{ contato[0].itiReferencia }}
              </p><!-- End .lead text-primary -->
            </div><!-- End .text-center -->

            <form class="contact-form mb-2">
              <div class="row">
                <div class="col-sm-4">
                  <label for="cname" class="sr-only">Nome</label>
                  <input type="text" class="form-control" v-model="Nome" placeholder="Nome *" required>
                </div><!-- End .col-sm-4 -->

                <div class="col-sm-4">
                  <label for="cemail" class="sr-only">E-mail</label>
                  <input type="email" class="form-control" v-model="Email" placeholder="E-mail *" required>
                </div><!-- End .col-sm-4 -->

                <div class="col-sm-4">
                  <label for="cphone" class="sr-only">Telefone</label>
                  <input type="tel" class="form-control" v-model="Telefone" placeholder="Telefone">
                </div><!-- End .col-sm-4 -->
              </div><!-- End .row -->

              <label for="csubject" class="sr-only">Assunto</label>
              <input type="text" class="form-control" v-model="Assunto" placeholder="Assunto">

              <label for="cmessage" class="sr-only">Mensagem</label>
              <textarea class="form-control" cols="30" rows="4" v-model="Mensagem" required placeholder="Mensagem *"></textarea>

              <div class="text-center">
                <button class="btn btn-outline-primary-2 btn-minwidth-sm" @click.prevent="EnviaEmail()">Enviar <i class="icon-long-arrow-right"></i></button>
              </div><!-- End .text-center -->
            </form><!-- End .contact-form -->
          </div><!-- End .col-md-9 col-lg-7 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
      <iframe
        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + contato[0].itiCampoExtra2"
        width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
      </iframe>
    </div><!-- End .page-content -->




  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'FaleConoscoView',
  components: {
  },
  data: () => ({
    contatotopo: [],
    contato: [],
    Nome: '',
    Email: '',
    Telefone: '',
    Assunto: '',
    Mensagem: '',
    redes:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Fale Conosco - Cestas Porto Alegre'
			})
		},
    EnviaEmail() {
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Cestas Porto Alegre",
          assunto: 'Contato do Site Cestas Porto Alegre - ' + this.Assunto,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Assunto = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/15/contatotopo')
      .then(response => {
        this.contatotopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/20/redessociais')
         .then(response => {
         this.redes = response.data;
       })
       .catch(error => {
         console.error(error);
       });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
