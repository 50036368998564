// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    variavelHeader1: '',
    variavelHeader2: '',
  },
  mutations: {
    updateVariavelHeader1(state, newValue) {
      state.variavelHeader1 = newValue;
    },
    updateVariavelHeader2(state, newValue) {
      state.variavelHeader2 = newValue;
    },
  },
});
