<template>
<div></div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'PageCheckAcesso',
  components: {

  },
  data: () => ({
    usuario: {},
  }),
  methods: {
    CheckLogin() {
      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData) {
        const _dadosRecuperados = JSON.parse(cookieData);
        let _usuario = {
          email: _dadosRecuperados.email,
          senha: _dadosRecuperados.senha,
        };

        if (this.$route.path == '/login'){
          let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
          if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados.produtos != '') {
              if (dadosArmazenados.agenda != '') {
                this.$router.push({ path: '/PagamentoMercadoPago', replace: true });
              }
              else {
                this.$router.push('/endereco');
              }
            }
        }
      }

      } else {
        if (this.$route.path != '/login'){
          this.$router.push('/login');
        }
      }
    },
    Logout() {
      Cookies.remove(this.$CookieCliente);
      this.$router.push('/');
    },
  },
  created() {
    this.CheckLogin();
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
