<template>
  <div>
    <main class="main" >
      <div  v-if="sobretopo!='' && sobretopo[0].insInstitucionalImagens!=''" class="page-header text-center" :style="{backgroundImage: 'url(' + $imgURL + sobretopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}">
        		<div class="container">
        			<h1 class="page-title">{{sobretopo[0].itiNome}}</h1>
        		</div><!-- End .container -->
        	</div><!-- End .page-header -->
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Sobre</li>
                    </ol>
                </div><!-- End .container -->
            </nav><!-- End .breadcrumb-nav -->

            <div class="page-content pb-3">
                <div class="container pb-6" v-if="sobreCestas!=''">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <div class="about-text text-center mt-3">
                                <h2 class="title text-center mb-2">{{sobreCestas[0].itiNome}}</h2><!-- End .title text-center mb-2 -->
                                <p v-html="sobreCestas[0].itiResumo1"></p><br>
                               
                            </div><!-- End .about-text -->
                        </div><!-- End .col-lg-10 offset-1 -->
                    </div><!-- End .row -->
                </div><!-- End .container -->

                <div class="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8" v-if="sobreCestas!=''">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 mb-3 mb-lg-0">
                                <h2 class="title">{{sobreCestas[0].itiReferencia}}</h2><!-- End .title -->
                                <p v-html="sobreCestas[0].itiResumo2"></p><!-- End .lead text-primary -->
                              
                            </div><!-- End .col-lg-5 -->

                            <div class="col-lg-6 offset-lg-1">
                                <div class="about-images">
                                    <img v-if="sobreCestas[0].insInstitucionalImagens!=''" :src="$imgURL + sobreCestas[0].insInstitucionalImagens[0].itgUrlImagem" alt="" class="about-img-front">
                                    <img v-if="sobreCestas[0].insInstitucionalImagens.length > 1" :src="$imgURL + sobreCestas[0].insInstitucionalImagens[1].itgUrlImagem" alt="" class="about-img-back">
                                </div><!-- End .about-images -->
                            </div><!-- End .col-lg-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .bg-light-2 pt-6 pb-6 -->

                <div class="mb-2"></div><!-- End .mb-2 -->

                <div class="about-testimonials pt-3 pb-6" v-if="depoimentos!=''">
                    <div class="container">
                        <h2 class="title text-center mb-4">O que dizem nossos clientes!</h2><!-- End .title text-center -->

                        <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="1" :breakpoints="{768: { slidesPerView: 1 }, 0: { slidesPerView: 1 }}" class="carousel-testimony owl-carousel">
                          <SwiperSlide v-for="depoi in depoimentos" :key="depoi.itiId">
                            <blockquote class="testimonial text-center">
                                <img v-if="depoi.insInstitucionalImagens!=''" :src="$imgURL + depoi.insInstitucionalImagens[0].itgUrlImagem" alt="user">
                                <p v-html="depoi.itiResumo1"></p>
                                <cite>
                                   {{depoi.itiNome}}
                                    <span> {{depoi.itiReferencia}}</span>
                                </cite>
                            </blockquote><!-- End .testimonial -->
                          </SwiperSlide>
                        </Swiper>
                    </div><!-- End .container -->
                </div><!-- End .bg-light-2 pt-5 pb-6 -->
                <div v-if="numerosfundo!='' && numerosfundo[0].insInstitucionalImagens!=''" class="bg-image" :style="{backgroundImage: 'url(' + $imgURL + numerosfundo[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '60px', paddingBottom: '50px'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-6 col-md-3" v-for="numero in numeros" :key="numero.itiId">
                                <div class="count-container text-center">
                                    <div class="count-wrapper text-white" style="display:inline-flex;">
                                      <VueJsCounter start="0" :end="numero.itiNome" duration="8000" class="counter" style="font-size: 40px;font-weight: 300;line-height: 0.9;margin-bottom: 5px;color: #ffffff;"></VueJsCounter>{{ numero.itiCampoExtra1 }}
                                    </div><!-- End .count-wrapper -->
                                    <h3 class="count-title text-white">{{numero.itiReferencia}}</h3><!-- End .count-title -->
                                </div><!-- End .count-container -->
                            </div><!-- End .col-6 col-md-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .bg-image pt-8 pb-8 -->

            </div><!-- End .page-content -->
    </main>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import VueJsCounter from 'vue-js-counter'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'SobreView',
  components: {
    VueJsCounter,
    Swiper,
    SwiperSlide
  },
  data: () => ({
    sobretopo: [],
    sobreCestas:[],
    numeros:[],
    numerosfundo:[],
    depoimentos:[],  //owl-carousel owl-simple owl-testimonials-photo
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Cestas Porto Alegre'
			})
		},
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/11/sobretopo')
      .then(response => {
        this.sobretopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/12/sobre')
      .then(response => {
        this.sobreCestas = response.data;
      })
      .catch(error => {
        console.error(error);
      });

   axios.get('/Process/InsInstitucional/institucional/14/numeros')
      .then(response => {
        this.numeros = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/25/numerosimgfundo')
      .then(response => {
        this.numerosfundo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
      
      axios.get('/Process/InsInstitucional/institucional/13/depoimentos')
      .then(response => {
        this.depoimentos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
