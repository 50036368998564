<template>
    <!--Loader v-if="loading"></!--Loader-->
    <div v-if="montaHomeItens!=''" >
       <div v-for="item in blocosHome" :key="item.hbhId">
           
          <!-- Slider -->
          <div v-if="item.homTipoBloco.htbId == 1">
                <v-carousel class="tamCar" v-if="montaHomeItens.length > 0" style="height: 100% !important;" :cycle="true" :cycle-interval="5000" 
                    :show-arrows="false"
                    hide-delimiter-background="false"> 
                  <v-carousel-item v-for="slider in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="slider.hbiId">
                    <a :href="slider.hbiLink">
                       <img v-if="slider!=''" :src="$imgURL + slider.hbiUrlImg" style="width: 100%;" />
                    </a>
                  </v-carousel-item>
              </v-carousel>
           </div>

           <div v-else-if="item.homTipoBloco.htbId == 2">
            <div v-if="montaHomeItens.length > 0" style="padding-top: 40px;padding-bottom: 40px;" >
                    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 1 } }" :autoplay="{ delay: 3000 }" class="carousel-testimony owl-carousel" style="height: 200px !important;">
                        <SwiperSlide v-for="bannercar in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="bannercar.hbiId" style="padding: 3px;">
                          <a :href="bannercar.hbiLink">
                             <img v-if="bannercar!=''" :src="$imgURL + bannercar.hbiUrlImg" style="width: 100%;" />
                           </a>
                        </SwiperSlide>
                    </Swiper>
                  </div>
          </div>

          <!-- Categorias -->
          <div v-else-if="item.homTipoBloco.htbId == 15">
            <div v-if="item.hbhVisivel" style="padding-top: 20px;padding-bottom: 20px;" >
                <div v-if="SegmentosMenu!=''">
                    <Swiper :navigation="false" :space-between="3" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{1100: { slidesPerView: 10}, 768: { slidesPerView: 6}, 0: { slidesPerView: 3 } }" style="height: 180px !important;">
                        <SwiperSlide v-for="seg in SegmentosMenu" :key="seg.psgId" class="swiper-slide">
                            <div class="swiper-content">
                                <a href="#" @click.prevent="MontaUrl(seg.psgNomeSegmento, seg.psgId)">
                                <img v-if="seg.psgResumo !== '' && seg.psgResumo !== null" :src="$imgURL + seg.psgResumo" class="swiper-image" />
                                <center v-else><div  class="swiper-placeholder"></div></center>
                            <span class="swiper-text" style="font-weight: 500; font-size: 14px;color:#333333;">{{ seg.psgNomeSegmento }}</span>
                            </a>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
          </div>

        <!-- Promoções -->
            <div v-else-if="item.homTipoBloco.htbId == 16">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 0px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-3 mt-3" v-for="prod4 in ProdutosDestaque" :key="prod4.proId">
                              <div class="product product-2">
                                  <figure class="product-media">
                                      <span v-if="prod4.proEtiqueta!='' && prod4.proEtiqueta!=null" class="product-label label-sale">{{prod4.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)">
                                          <img v-if="prod4.proCampoExtra10!='' && prod4.proCampoExtra10!=null" :src="$imgURL + prod4.proCampoExtra10" alt="" class="product-image">
                                          <img v-if="prod4.proCampoExtra9!='' && prod4.proCampoExtra9!=null" :src="$imgURL + prod4.proCampoExtra9" alt="" class="product-image-hover">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)">
                                            {{ prod4.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proValorPor != 0 && prod4.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod4.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod4.proValorDe!='' && prod4.proValorDe!=null">era R$ {{ formatarValor(prod4.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

           <!-- Carrossel 5 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 3">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
                </div>
                  <div v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                    <Swiper :navigation="true" :pagination="{ clickable: true }" :slides-per-view="5" :breakpoints="{ 768: { slidesPerView: 5 }, 0: { slidesPerView: 2 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4car.hbiId">
                                <div class="product" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null  && prod4car.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image">
                                            <img v-if="prod4car.proProdutos.proCampoExtra9!='' && prod4car.proProdutos.proCampoExtra9!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra9" alt="" class="product-image-hover">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size: 14px !important;">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proValorPor != 0 && prod4car.proProdutos.proVisivel">
                                            <span class="new-price">por R$ {{ formatarValor(prod4car.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4car.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                    <div v-if="item.hbhCampoExtra2!='' && item.hbhCampoExtra2!=null" style="text-align: center; padding-top: 20px;">
                        <a :href="item.hbhCampoExtra2" class="btn btn-outline-danger mb-3" title="" style="width: 200px;">{{item.hbhCampoExtra1}}</a>
                    </div>
                  </div>
                
                 

          </div>

           <!-- 1 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 4">
              <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12" v-for="banner1 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner1.hbiId">
                        <a :href="banner1.hbiLink">
                          <img v-if="banner1!=''" :src="$imgURL + banner1.hbiUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

           <!-- 2 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 5">
              <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                  <div class="row">
                    <div class="col-sm-6 col-lg-6" v-for="banner2 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner2.hbiId">
                        <a :href="banner2.hbiLink">
                          <img v-if="banner2!=''" :src="$imgURL + banner2.hbiUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 3 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 6">
            <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4" v-for="banner3 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner3.hbiId">
                        <a :href="banner3.hbiLink">
                          <img v-if="banner3!=''" :src="$imgURL + banner3.hbiUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 4 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 7">
            <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                  <div class="row">
                    <div class="col-sm-3 col-lg-3" v-for="banner4 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner4.hbiId">
                        <a :href="banner4.hbiLink">
                          <img v-if="banner4!=''" :src="$imgURL + banner4.hbiUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 6 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 8">
              <div class="container-fluid" v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;">
                  <div class="row">
                    <div class="col-sm-2 col-lg-2" v-for="banner6 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner6.hbiId">
                        <a :href="banner6.hbiLink">
                          <img v-if="banner6!=''" :src="$imgURL + banner6.hbiUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 1 Banner Linha -->
          <div v-else-if="item.homTipoBloco.htbId == 9">
              <section v-if="montaHomeItens.length > 0" style="padding-top: 10px;padding-bottom: 0px;" >
                  <div v-for="bannerlinha in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="bannerlinha.hbiId" >
                      <a :href="bannerlinha.hbiLink">
                        <img v-if="bannerlinha!=''" :src="$imgURL + bannerlinha.hbiUrlImg" style="width: 100%;" />
                      </a>
                  </div>
              </section>
          </div>

           <!-- 2 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 10">
            <div class="mb-4"></div><!-- End .mb-4 -->

                <div class="container for-you">
                    <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 0px;">
                        <div class="heading-center">
                            <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                        </div><!-- End .heading-left -->
                    </div><!-- End .heading -->

                    <div class="products">
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-6 col-lg-6 mt-3" v-for="prod2 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod2.hbiId">
                                <div class="product product-2" v-if="prod2.proProdutos!=''">
                                    <figure class="product-media">
                                        <span v-if="prod2.proProdutos.proEtiqueta!='' && prod2.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod2.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod2.proProdutos.proValorDe!='' && prod2.proProdutos.proValorDe!=null  && prod2.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                            <img v-if="prod2.proProdutos.proCampoExtra10!='' && prod2.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod2.proProdutos.proCampoExtra10" alt="" class="product-image">
                                            <img v-if="prod2.proProdutos.proCampoExtra9!='' && prod2.proProdutos.proCampoExtra9!=null" :src="$imgURL + prod2.proProdutos.proCampoExtra9" alt="" class="product-image-hover">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                              {{ prod2.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title --> 
                                        <div class="product-price" v-if="prod2.proProdutos.proValorPor != 0 && prod2.proProdutos.proVisivel">
                                            <span class="new-price">por R$ {{ formatarValor(prod2.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod2.proProdutos.proValorDe!='' && prod2.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod2.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .products -->
                </div><!-- End .container -->
          </div>

           <!-- 3 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 11">
              <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-4 mt-3" v-for="prod3 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod3.hbiId">
                              <div class="product product-2" v-if="prod3.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod3.proProdutos.proEtiqueta!='' && prod3.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod3.proProdutos.proEtiqueta}}</span>
                                      <span v-else-if="prod3.proProdutos.proValorDe!='' && prod3.proProdutos.proValorDe!=null  && prod3.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                          <img v-if="prod3.proProdutos.proCampoExtra10!='' && prod3.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod3.proProdutos.proCampoExtra10" alt="" class="product-image">
                                          <img v-if="prod3.proProdutos.proCampoExtra9!='' && prod3.proProdutos.proCampoExtra9!=null" :src="$imgURL + prod3.proProdutos.proCampoExtra9" alt="" class="product-image-hover">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                            {{ prod3.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod3.proProdutos.proValorPor != 0 && prod3.proProdutos.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod3.proProdutos.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod3.proProdutos.proValorDe!='' && prod3.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod3.proProdutos.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- 4 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 12">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 0px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-3 mt-3" v-for="prod4 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4.hbiId">
                              <div class="product product-2" v-if="prod4.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod4.proProdutos.proEtiqueta!='' && prod4.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4.proProdutos.proEtiqueta}}</span>
                                      <span v-else-if="prod4.proProdutos.proValorDe!='' && prod4.proProdutos.proValorDe!=null  && prod4.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                          <img v-if="prod4.proProdutos.proCampoExtra10!='' && prod4.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4.proProdutos.proCampoExtra10" alt="" class="product-image">
                                          <img v-if="prod4.proProdutos.proCampoExtra9!='' && prod4.proProdutos.proCampoExtra9!=null" :src="$imgURL + prod4.proProdutos.proCampoExtra9" alt="" class="product-image-hover">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                            {{ prod4.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proProdutos.proValorPor != 0 && prod4.proProdutos.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod4.proProdutos.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod4.proProdutos.proValorDe!='' && prod4.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4.proProdutos.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- Carrossel 4 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 13" :style="{backgroundColor: item.hbhCorFundo + ' !important'}">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 30px;">
                      <div class="heading-center">
                          <h2 class="title" :style="{ color: item.hbhCorFonte }">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
              
                  <div v-if="montaHomeItens.length > 0" style="padding-top: 30px;padding-bottom: 30px;" >
                    <Swiper :navigation="false" :space-between="10" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 2 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4car.hbiId">
                                <div class="product product-2" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media" style="border: 1px solid #cccccc;">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null  && prod4car.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image">
                                            <img v-if="prod4car.proProdutos.proCampoExtra9!='' && prod4car.proProdutos.proCampoExtra9!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra9" alt="" class="product-image-hover">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proValorPor != 0 && prod4car.proProdutos.proVisivel">
                                            <span class="new-price">por R$ {{ formatarValor(prod4car.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4car.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>
              </div>
          </div>

          <!-- Carrossel 4 Informações -->
          <div v-else-if="item.homTipoBloco.htbId == 14">
              <div class="icon-boxes-container">
                <div class="container">
                      <div class="row">
                            <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{1100: { slidesPerView: 4 }, 768: { slidesPerView: 3 }, 0: { slidesPerView: 4 } }" class="carousel-testimony owl-carousel" style="height: 100px !important;">
                                <SwiperSlide v-for="carro4inf in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="carro4inf.hbiId">
                                    <div class="row">  
                                        <div class="icon-box icon-box-side col-lg-4">
                                            <span class="icon-box-icon text-dark" style="padding-right: 10px;">
                                                <img v-if="carro4inf!=''" :src="$imgURL + carro4inf.hbiUrlImg" style="width: 80px;" />
                                            </span>
                                            <div class="icon-box-content">
                                                <h3 class="icon-box-title" style="width: 250px">{{carro4inf.hbiLink}}</h3><!-- End .icon-box-title -->
                                                <p>{{carro4inf.hbiTexto}}</p>
                                            </div><!-- End .icon-box-content -->
                                        </div><!-- End .icon-box -->
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                       </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
    <!-- aqui mobile-->
    <div v-if="montaHomeItensM!=''">
       <div v-for="item in blocosHomeM" :key="item.hbmId">
           
          <!-- Slider -->
          <div v-if="item.homTipoBloco.htbId == 1">
                <v-carousel class="tamCar" v-if="montaHomeItensM.length > 0" style="height: 100% !important;" :cycle="true" :cycle-interval="5000"
                    :show-arrows="false"
                    hide-delimiter-background>
                  <v-carousel-item v-for="slider in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="slider.himId">
                    <a :href="slider.himLink">
                       <img v-if="slider!=''" :src="$imgURL + slider.himUrlImg" style="width: 100%;" />
                    </a>
                  </v-carousel-item>
              </v-carousel>
           </div>

           <div v-else-if="item.homTipoBloco.htbId == 2">
            <div v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;" >
                    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 2 } }" :autoplay="{ delay: 3000 }" class="carousel-testimony owl-carousel" style="height: 200px !important;">
                        <SwiperSlide v-for="bannercar in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="bannercar.himId" style="padding: 3px;">
                          <a :href="bannercar.himLink">
                             <img v-if="bannercar!=''" :src="$imgURL + bannercar.himUrlImg" style="width: 100%;" />
                           </a>
                        </SwiperSlide>
                    </Swiper>
                  </div>
          </div>

            <!-- Categorias -->
            <div v-else-if="item.homTipoBloco.htbId == 15">
            <div v-if="item.hbmVisivel" style="padding-top: 20px;padding-bottom: 20px;" >
                <div style="padding-top: 0px;" v-if="SegmentosMenu!=''">
                    <Swiper :navigation="false" :space-between="10" :pagination="{ clickable: true }" :slides-per-view="3" :breakpoints="{ 768: { slidesPerView: 10 }, 0: { slidesPerView: 3 } }" style="height: 190px !important;">
                            <SwiperSlide v-for="seg in SegmentosMenu" :key="seg.psgId" class="swiper-slide">
                                <div class="swiper-content">
                                    <a href="#" @click="MontaUrl(seg.psgNomeSegmento, seg.psgId)">
                                        <img v-if="seg.psgResumo !== '' && seg.psgResumo !== null" :src="$imgURL + seg.psgResumo" class="swiper-image" style="width: 120px !important;height:120px !important;display: block;margin: 0 auto;" />
                                        <center v-else><div  class="swiper-placeholder"></div></center>
                                        <span class="swiper-text" style="font-weight: 500; font-size: 14px;color:#333333;">{{ seg.psgNomeSegmento }}</span>
                                    </a>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                </div>
            </div>
          </div>

        <!-- Promoções -->
        <div v-else-if="item.homTipoBloco.htbId == 16">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 0px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-3 mt-3" v-for="prod4 in ProdutosDestaque" :key="prod4.proId">
                              <div class="product product-2">
                                  <figure class="product-media">
                                      <span v-if="prod4.proEtiqueta!='' && prod4.proEtiqueta!=null" class="product-label label-sale">{{prod4.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)">
                                          <img v-if="prod4.proCampoExtra10!='' && prod4.proCampoExtra10!=null" :src="$imgURL + prod4.proCampoExtra10" alt="" class="product-image">
                                          <img v-if="prod4.proCampoExtra9!='' && prod4.proCampoExtra9!=null" :src="$imgURL + prod4.proCampoExtra9" alt="" class="product-image-hover">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proId, prod4.proNomeProduto)">
                                            {{ prod4.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proValorPor != 0 && prod4.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod4.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod4.proValorDe!='' && prod4.proValorDe!=null">era R$ {{ formatarValor(prod4.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

           <!-- Carrossel 5 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 3">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 0px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
                </div>
                  <div v-if="montaHomeItensM.length > 0" style="padding-top: 0px;padding-bottom: 20px;" >
                    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="1" :breakpoints="{ 768: { slidesPerView: 5 }, 0: { slidesPerView: 1 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="prod4car.himId">
                                <div class="product" v-if="prod4car.proProdutos!='' && prod4car.proProdutos!=null">
                                    <figure class="product-media">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null  && prod4car.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align: center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size: 20px !important;">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proValorPor != 0 && prod4car.proProdutos.proVisivel" style="justify-content: center;">
                                            <span class="new-price">por R$ {{ formatarValor(prod4car.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4car.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                    <div v-if="item.hbmCampoExtra2!='' && item.hbmCampoExtra2!=null" style="text-align: center; padding-top: 20px;">
                        <a :href="item.hbmCampoExtra2" class="btn btn-outline-danger mb-3" title="" style="width: 200px;">{{item.hbmCampoExtra1}}</a>
                    </div>
                  </div>

          </div>

           <!-- 1 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 4">
              <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-12 col-lg-12" v-for="banner1 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner1.himId">
                        <a :href="banner1.himLink">
                          <img v-if="banner1!=''" :src="$imgURL + banner1.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

           <!-- 2 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 5">
              <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-6 col-lg-6" v-for="banner2 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner2.himId">
                        <a :href="banner2.himLink">
                          <img v-if="banner2!=''" :src="$imgURL + banner2.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 3 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 6">
            <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-4 col-lg-4" v-for="banner3 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner3.himId">
                        <a :href="banner3.himLink">
                          <img v-if="banner3!=''" :src="$imgURL + banner3.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 4 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 7">
            <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-3 col-lg-3" v-for="banner4 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner4.himId">
                        <a :href="banner4.himLink">
                          <img v-if="banner4!=''" :src="$imgURL + banner4.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 6 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 8">
              <div class="container-fluid" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-2 col-lg-2" v-for="banner6 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner6.himId">
                        <a :href="banner6.himLink">
                          <img v-if="banner6!=''" :src="$imgURL + banner6.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 1 Banner Linha -->
          <div v-else-if="item.homTipoBloco.htbId == 9">
              <section v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;" >
                  <div v-for="bannerlinha in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="bannerlinha.himId" >
                      <a :href="bannerlinha.himLink">
                        <img v-if="bannerlinha!=''" :src="$imgURL + bannerlinha.himUrlImg" style="width: 100%;" />
                      </a>
                  </div>
              </section>
          </div>

           <!-- 2 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 10">
            <div class="mb-4"></div><!-- End .mb-4 -->

                <div class="container for-you">
                    <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 20px;">
                        <div class="heading-center">
                            <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                        </div><!-- End .heading-left -->
                    </div><!-- End .heading -->

                    <div class="products">
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-6 col-lg-6 mt-3" v-for="prod2 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod2.himId">
                                <div class="product product-2" v-if="prod2.proProdutos!='' && prod2.proProdutos!=null">
                                    <figure class="product-media">
                                        <span v-if="prod2.proProdutos.proEtiqueta!=null && prod2.proProdutos.proEtiqueta!=''" class="product-label label-sale">{{prod2.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod2.proProdutos.proValorDe!='' && prod2.proProdutos.proValorDe!=null  && prod2.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                            <img v-if="prod2.proProdutos.proCampoExtra10!='' && prod2.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod2.proProdutos.proCampoExtra10" alt="" class="product-image">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                              {{ prod2.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title --> 
                                        <div class="product-price" v-if="prod2.proProdutos.proValorPor != 0 && prod2.proProdutos.proVisivel">
                                            <span class="new-price">por R$ {{ formatarValor(prod2.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod2.proProdutos.proValorDe!='' && prod2.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod2.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .products -->
                </div><!-- End .container -->
          </div>

           <!-- 3 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 11">
              <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 20px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-4 col-md-4 col-lg-4 mt-3" v-for="prod3 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod3.himId">
                              <div class="product product-2" v-if="prod3.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod3.proProdutos.proEtiqueta!='' && prod3.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod3.proProdutos.proEtiqueta}}</span>
                                      <span v-else-if="prod3.proProdutos.proValorDe!='' && prod3.proProdutos.proValorDe!=null  && prod3.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                          <img v-if="prod3.proProdutos.proCampoExtra10!='' && prod3.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod3.proProdutos.proCampoExtra10" alt="" class="product-image">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                            {{ prod3.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod3.proProdutos.proValorPor != 0 && prod3.proProdutos.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod3.proProdutos.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod3.proProdutos.proValorDe!='' && prod3.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod3.proProdutos.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- 4 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 12">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 20px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-3 col-md-4 col-lg-3 mt-3" v-for="prod4 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod4.himId">
                              <div class="product product-2" v-if="prod4.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod4.proProdutos.proEtiqueta!='' && prod4.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4.proProdutos.proEtiqueta}}</span>
                                      <span v-else-if="prod4.proProdutos.proValorDe!='' && prod4.proProdutos.proValorDe!=null  && prod4.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                      <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                          <img v-if="prod4.proProdutos.proCampoExtra10!='' && prod4.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4.proProdutos.proCampoExtra10" alt="" class="product-image">
                                      </router-link>
                                      <div class="product-action">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                            {{ prod4.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proProdutos.proValorPor != 0 && prod4.proProdutos.proVisivel">
                                          <span class="new-price">por R$ {{ formatarValor(prod4.proProdutos.proValorPor) }}</span>
                                          <small class="old-price" v-if="prod4.proProdutos.proValorDe!='' && prod4.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4.proProdutos.proValorDe) }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- Carrossel 4 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 13" :style="{backgroundColor: item.hbmCorFundo + ' !important'}">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 20px;">
                      <div class="heading-center">
                          <h2 class="title" :style="{ color: item.hbmCorFonte }">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
              
                  <div v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;" >
                    <Swiper :navigation="false" :space-between="10" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 1 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="prod4car.himId">
                                <div class="product product-2" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media" style="border: 1px solid #cccccc;">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <span v-else-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null  && prod4car.proProdutos.proValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image">
                                        </router-link>
                                        <div class="product-action">
                                            <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/cestadetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proValorPor != 0 && prod4car.proProdutos.proVisivel">
                                            <span class="new-price">por R$ {{ formatarValor(prod4car.proProdutos.proValorPor) }}</span>
                                            <small class="old-price" v-if="prod4car.proProdutos.proValorDe!='' && prod4car.proProdutos.proValorDe!=null">era R$ {{ formatarValor(prod4car.proProdutos.proValorDe) }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>
              </div>
          </div>

          <!-- Carrossel 4 Informações -->
          <div v-else-if="item.homTipoBloco.htbId == 14">
              <div class="icon-boxes-container">
                <div class="container">
                      <div class="row">
                        <v-carousel hide-delimiters class="carousel-testimony owl-carousel" v-if="montaHomeItensM.length > 0" style="height: 90px !important;" :cycle="true" :cycle-interval="3000" 
                                :show-arrows="false"> 
                            <v-carousel-item v-for="carro4inf in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="carro4inf.himId">
                                <div class="icon-box icon-box-side" style="justify-content: center;">
                                          <span class="icon-box-icon text-dark">
                                            <img v-if="carro4inf!=''" :src="$imgURL + carro4inf.himUrlImg" style="width: 50px;" />
                                          </span>
                                          <div class="icon-box-content">
                                              <h3 class="icon-box-title">{{carro4inf.himLink}}</h3><!-- End .icon-box-title -->
                                              <p>{{carro4inf.himTexto}}</p>
                                          </div><!-- End .icon-box-content -->
                                      </div><!-- End .icon-box -->
                            </v-carousel-item>
                        </v-carousel>
                       </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Loader from '@/components/PageLoader.vue'

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Loader
  },
 data: () => ({
    montaHomeItens:[],
    blocosHome:[],
    montaHomeItensM:[],
    blocosHomeM:[],
    SegmentosMenu: [],
    Segmentos:[],
    loading: true ,
    ProdutosDestaque:[],
  }),
  methods: {
    formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
    carregaMetas() {
			useHead({
				title: 'Home - Cestas Porto Alegre'
			})
		},
    AddCarrinho(valueProd){
      console.log(valueProd);
    },
    AjustaUrl(value, nome) {
            let urlFriendlyNomeProduto = nome
                .normalize("NFD") // Normaliza para decompor os caracteres acentuados
                .replace(/[\u0300-\u036f]/g, "") // Remove os caracteres acentuados
                .replace(/[^\w\s-]/g, "") // Remove caracteres especiais
                .replace(/\s+/g, "-") // Substitui espaços em branco por hífens
                .toLowerCase(); // Converte para minúsculas

            return `${value}/${urlFriendlyNomeProduto}`;
        },
        MontaUrl(nomeSegmento, psgId) {
            this.$router.push({
                name: 'CestasporCategoria',
                params: {
                    idSegmentoProduto: psgId,
                    nomeSegmento: nomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', ''),
                },
                replace: true,
            });
        },
  },
  created() {
    window.scrollTo(0, 0);
  /* setTimeout(() => {
      this.loading = false;
    }, 3000); */
  


   this.carregaMetas();

   axios.get('/Process/ProSegmentos/GetAll')
                    .then(response => {
                        this.Segmentos = response.data;
                        this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                            const nomeA = a.psgNomeSegmento || "";
                            const nomeB = b.psgNomeSegmento || "";
                            return nomeA.localeCompare(nomeB);
                        });

                    })
                    .catch(error => {
                        console.error(error);
                    });

    axios.get('/Process/ProProdutos/GetAllByProdutosDestaques')
                    .then(response => {
                        this.ProdutosDestaque = response.data;
                        this.ProdutosDestaque = this.ProdutosDestaque.sort((a, b) => {
                            const nomeA = a.proNomeProduto || "";
                            const nomeB = b.proNomeProduto || "";
                            return nomeA.localeCompare(nomeB);
                        });

                    })
                    .catch(error => {
                        console.error(error);
                    });

                    

   if (window.innerWidth > 768)
   {
    axios.get('/Process/HomBlocosHomeItens/GetAll')
         .then(response => {
         this.montaHomeItens = response.data.filter(x=> x.hbiVisivel).sort((a, b) => {
                          const valorA = parseInt(a.hbiOrdem) || 0;
                          const valorB = parseInt(b.hbiOrdem) || 0;
                          return valorA - valorB;
                        });
       //  console.log('this.montaHomeItens')
       //  console.log(this.montaHomeItens)
       })
       .catch(error => {
         console.error(error);
       });

         axios.get('/Process/HomBlocosHome/GetAll')
         .then(response => {
         this.blocosHome = response.data.filter(x=> x.hbhVisivel).sort((a, b) => {
                          const valorA = parseInt(a.hbhOrdem) || 0;
                          const valorB = parseInt(b.hbhOrdem) || 0;
                          return valorA - valorB;
                        });
      //  console.log('this.blocosHome')
      //  console.log(this.blocosHome)
       })
       .catch(error => {
         console.error(error);
       });

   }
   else{
     axios.get('/Process/HomBlocosHomeItensMobile/GetAll')
         .then(response => {
         this.montaHomeItensM = response.data.filter(x=> x.himVisivel).sort((a, b) => {
                          const valorA = parseInt(a.himOrdem) || 0;
                          const valorB = parseInt(b.himOrdem) || 0;
                          return valorA - valorB;
                        });
         //console.log('this.montaHomeItensM')
       // console.log(this.montaHomeItensM)
       })
       .catch(error => {
         console.error(error);
       });

          axios.get('/Process/HomBlocosHomeMobile/GetAll')
         .then(response => {
         this.blocosHomeM = response.data.filter(x=> x.hbmVisivel).sort((a, b) => {
                          const valorA = parseInt(a.hbmOrdem) || 0;
                          const valorB = parseInt(b.hbmOrdem) || 0;
                          return valorA - valorB;
                        });
        //console.log('this.blocosHomeM')
        //console.log(this.blocosHomeM)
       })
       .catch(error => {
         console.error(error);
       });
   }
       
  }
}
</script>

<style>

.v-carousel .v-carousel__controls {
    display: none;
}

   .das {
            display: block !important;
        }

    .slider-text h2 p{
      font-size: 60px;
      color:#081624;
      padding:10px;
      display: inline-block;
      background: #99CC33;
      line-height: 1;
    }

    .swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden; 
    height: 400px !important;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #B91F1C !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -5px) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}

.swiper-button-prev, .swiper-button-next{
    color:#B91F1C !important;
}
@media (max-width:767px) {

    .swiper, swiper-container {
        overflow: auto !important; 
         height: 500px !important; 
    }

    .product-price {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    color: #c96;
    margin-bottom: 13px;
}
}

.swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden !important;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}


</style>
