<template>
  <div>
    <Loader v-if="$loading.value"></Loader>
    <CheckAcesso />
    <main class="main">
      <section class="main-carrinho" style="background-color: #f7e7db;padding-bottom: 100px;">
        <section class="">
          <div class="container" >
            <div class="row py-5">
              <!--div class="col-lg-5 hidden-xs hidden-sm">
                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                  v-if="logintopo != ''">
                  <img v-if="logintopo[0].insInstitucionalImagens != ''"
                    :src="$imgURL + logintopo[0].insInstitucionalImagens[0].itgUrlImagem" />
                </div>
              </!--div-->
              <div class="col-lg-8">
                <div class="box_content">
                  <!-- Dados do Cartão -->
                  <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center hidden-xs hidden-sm"><span style="color:#cccccc">Entrega > Login </span> > Pagamento</h3>
                  <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center hidden-lg hidden-md" style="font-size: 20px;"><span style="color:#cccccc">Entrega > Login </span> > Pagamento</h3>
                  <p>Importante:</p>
                  <small>1) Dados somente do titular do cartão</small><br>
                  <small>2) Verificar se seu banco pede autorização para efetivar compra</small><br>
                  <small>3) Evite deixar espaços em brancos no inicio e final dos textos digitados</small><br>
                  <small>4) Caso não apareça as opçoes de pagamento, aguarde um pouco e atualize a página</small>
                  <div id="paymentBrick_container"></div>
                </div>
              </div>
              <div class="col-lg-4">
                  <div class="posicao">
                      <div class="box_content">
                          <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">Resumo</h3>
                             <div v-if="TotalCarrinho != ''"
                                  class="d-flex align-items-center justify-content-between mb-1"
                                  style="padding-right:5px;">
                                  <h6 class="mb-0" v-if="selectedHorarioId !== null && selectedHorarioId !== ''">
                                      Entrega: {{ selectedHorarioId.DataEntrega !== null ? formattedDate(selectedHorarioId.DataEntrega) : '' }} -
                                      {{ selectedHorarioId == '' ? null : (selectedHorarioId.Horarios.Valor == 0 ? ( !selectedHorarioId.RetirarLoja ? selectedHorarioId.Horarios.Horario.split('R$')[0].trim() + " (Sem Hora Marcada)" : selectedHorarioId.Horarios.Horario.split('R$')[0].trim() + " (Retirar na Loja)") : selectedHorarioId.Horarios.Horario.split('R$')[0].trim()) }}
                                  </h6>
                                  <h6 class="text-center mb-0"></h6>
                              </div>
                              <div v-if="TotalCarrinho != ''"
                                  class="d-flex align-items-center justify-content-between mb-1"
                                  style="padding-right:5px;">
                                  <h6 class="mb-0">Produtos: </h6>
                                  <h6 class="text-center mb-0">R$ {{ formatarValor(TotalProdutos) }}</h6>

                              </div>
                              <div v-if="TotalCarrinho != ''"
                                  class="d-flex align-items-center justify-content-between mb-1"
                                  style="padding-right:5px;">
                                  <h6 class="mb-0">Entrega: </h6>
                                  <h6 class="text-center mb-0">R$ {{ TotalFrete == '' ? 0: formatarValor(TotalFrete) }}</h6>
                          </div>
                          <div v-if="TotalCupom != '' && TotalCupom > 0"
                                  class="d-flex align-items-center justify-content-between mb-1"
                                  style="padding-right:5px;">
                                  <h6 class="mb-0">Cupom: </h6>
                                  <h6 class="text-center mb-0" style="color:red;">- R$ {{ formatarValor(TotalCupom) }}</h6>

                              </div>
                          <div class="border-bottom mb-4"></div>
                          <div v-if="TotalCarrinho != ''"
                              class="d-flex align-items-center justify-content-between mb-1"
                              style="padding-right:5px;">
                              <h6 class="texto-destaque mb-0" style="font-size: 20px;">Total:</h6>
                              <h6 class="texto-destaque text-center mb-0" style="font-size: 20px;">R$ {{ formatarValor(TotalCarrinho) }}</h6>
                          </div>
                      </div>
                      <div class="box_content-footer" style="text-align: center;">
                          <div class="mt-2" style="width: 100%;">
                              <router-link to="/endereco"
                                  class="btn btn-outline-danger mb-1" style="width: 100%;">Voltar</router-link>
                              <small>Botão para concluir a compra fica abaixo do meio de pagamento escolhido.</small>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>

        </section>
      </section>
    </main>
  </div>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent class="controlcel" style="max-width: calc(100% - 20%);">
      <v-card>
        <v-card-title>
          <span class="text-h5">Deixe seu Whatsapp de Contato</span>
        </v-card-title>
        <v-card-text style="padding: 16px 0px 10px">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                    <v-select v-model="selectedPais" label="País (DDI)" :items="Paises" @update:modelValue="TelefoneRecebe=''"
                        item-title="cpaNome" item-value="cpaId" variant="outlined">
                    </v-select>
                </v-col>
                <v-col cols="12" md="8">
                    <v-text-field v-model="TelefoneRecebe" autocomplete="off"
                      ref="whatsAppInput"
                      @input="aplicarMascara" 
                      @blur="validarNumero" 
                        label="Seu Whatsapp de Contato"  variant="outlined" required>
                        <template v-slot:append>
                                <v-tooltip v-model="show2" location="top" class="custom-tooltip" :open-on-click="true">
                                    <template v-slot:activator="{ props }">
                                        <v-icon color="#000000" v-bind="props" style="font-size: 20px;height: 30px;">
                                            mdi-help-circle
                                        </v-icon>
                                    </template>
                                    <span style="font-size: 12px;">Usamos somente se for necessário entrar em <br> contato para realizar a entrega. Não informamos <br> na ligação quem esta enviando o presente.</span>
                                    </v-tooltip>
                            </template>
                        </v-text-field>
                        <span class="small-text">*Número com DDD (ex: 5199999999)</span>
                </v-col>
              <v-col cols="12" sm="4" md="4" class="d-flex align-center">
                <v-btn variant="text" @click.prevent="AddWhatsappCli(TelefoneRecebe);" class="btn btn-info" style="color:#ffffff;background-color: #117a8b;">
                  Salvar
                </v-btn>
                <br>
               
              </v-col>
              <v-col cols="12" sm="12" md="12" class="d-flex align-center">
                <span style="color:red;">{{messageaviso }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Loader from '@/components/PageLoader.vue'
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';
import 'moment-timezone';
import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export default {
  beforeRouteEnter(to, from, next) {
    // Importe o SDK dinamicamente
    let dadosArmazenados = localStorage.getItem('dadoscompraCestasPortoAlegre');
    dadosArmazenados = JSON.parse(dadosArmazenados);

    let _totalProdutos = 0;
    let _cupom = 0;
    if (dadosArmazenados.produtos != '') {
      dadosArmazenados.produtos.forEach((itemprod) => {
        _totalProdutos += parseFloat(itemprod.proProdutosPrecos[0].ppcValorPor * 1)
      });
    }

    if (dadosArmazenados.avulsos != '') {
      if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
        dadosArmazenados.avulsos.forEach((itemavul) => {
          _totalProdutos += parseFloat(itemavul.praValorPor)
        });
      }
    }

    if (dadosArmazenados.agenda != '') {
      _totalProdutos += parseFloat(dadosArmazenados.agenda[0].Horarios.Valor);
    }

    if (dadosArmazenados.cupom != '') {
      _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10);
    }

    const TotalCarrinho = _totalProdutos - _cupom;

    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.async = true;
    script.onload = () => {
      // O SDK foi carregado com sucesso, agora você pode inicializá-lo
      const mp = new MercadoPago('APP_USR-5f6e364d-88ee-463d-b453-88faef5c08ac', {
        locale: 'pt-BR', three_d_secure_mode: 'optional'
      });

      const bricksBuilder = mp.bricks();


      // Faça qualquer outra configuração necessária aqui
      const settings = {
        initialization: {
          amount: TotalCarrinho,
          payer: {
            firstName: "",
            lastName: "",
            email: "",
          },
        },
        customization: {
          visual: {
            style: {
              theme: "default",
            },
          },
          paymentMethods: {
            creditCard: "all",
            bankTransfer: ['pix'],
            maxInstallments: 6
          },
        },
        callbacks: {
          onReady: () => {
            /*
            Callback chamado quando o Brick está pronto.
            Aqui, você pode ocultar seu site, por exemplo.
            */
          },
          onSubmit: ({ selectedPaymentMethod, formData }) => {
           // console.log('aqui formData')
           // console.log(formData)
            // callback chamado quando há click no botão de envio de dados
            const cookieData = Cookies.get("CliLogadoCestasPoa");
            if (cookieData === undefined) {
              // O cookie está expirado
              window.alert("Sessão expirou, pagamento não processado.")
              window.location.href = "/login";
            } else {
                  return new Promise((resolve, reject) => {
              fetch("https://controle.cestasportoalegre.com/api/Process/PgtoMercadoPago/process_payment", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
              })
                .then((response) => response.json())
                .then((response) => {
                  // receber o resultado do pagamento
                 // console.log('resultado')
                 // console.log(response)
                  let _external = '';
                  let _creq = '';

                  if (response.threeDSInfo != null) {
                    _external = response.threeDSInfo.externalResourceUrl;
                    _creq = response.threeDSInfo.creq
                  }

                  var _urlpixemail = "";
                  if (response.pointOfInteraction.type == "PIX")
                  {
                    _urlpixemail = response.pointOfInteraction.transactionData.ticketUrl;
                  }

                  if (response.status == "approved" || response.status == "pending" || response.status == "in_process" || response.paymentTypeId == 'bank_transfer') {
                    let dadosArmazenados = localStorage.getItem('dadoscompraCestasPortoAlegre');
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.pagamento = []
                    dadosArmazenados.informacao = []
                    dadosArmazenados.pagamento.push({
                      FormaPgto: response.paymentTypeId,
                      RetornoPgto: response.status,
                      CodRetornoPgto: response.id,
                      ValorTotal: TotalCarrinho,
                      FotoComprova: '',
                      ExternalResource: _external,
                      Creq: _creq,
                      PedQtdeParcelas: response.installments,
                      PedValorParcelas: response.transactionDetails.installmentAmount,
                      UrlPix: _urlpixemail
                    });
                    localStorage.setItem('dadoscompraCestasPortoAlegre', JSON.stringify(dadosArmazenados))
                    window.location.href = "/pedidorealizado";
                  }
                  else {
                    let dadosArmazenados = localStorage.getItem('dadoscompraCestasPortoAlegre');
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.informacao = []
                    dadosArmazenados.pagamento = []
                    dadosArmazenados.informacao.push({
                      FormaPgto: response.paymentTypeId,
                      RetornoPgto: response.status,
                      CodRetornoPgto: response.id,
                      ValorTotal: TotalCarrinho,
                      FotoComprova: '',
                      ExternalResource: _external,
                      Creq: _creq,
                      PedQtdeParcelas: response.installments,
                      PedValorParcelas: response.transactionDetails.installmentAmount
                    });
                    localStorage.setItem('dadoscompraCestasPortoAlegre', JSON.stringify(dadosArmazenados))
                    window.location.href = "/pedidonegado";
                  }

                  resolve();
                })
                .catch((error) => {
                 // console.log('error')
                  console.log(error)
                  // manejar a resposta de erro ao tentar criar um pagamento
                  reject();
                });
            });
          }
          },
          onError: (error) => {
            // callback chamado para todos os casos de erro do Brick
            console.error(error);
          },
        },
      };

      window.paymentBrickController = bricksBuilder.create(
        "payment",
        "paymentBrick_container",
        settings
      );
    };
    document.head.appendChild(script);
    next();
  },
  name: 'PagamentoMercadoPagoView',
  components: {
    CheckAcesso,
    Loader
  },
  data: () => ({
    logintopo: [],
    ListaformasPagamento: [],
    ListaformasPagamentoselected: null,
    OperacaoId: 0,
    TotalCarrinho: 0,
    TotalProdutos: 0,
    TotalFrete: 0,
    TotalCupom: 0,
    paymentBrickController: null,
    selectedHorarioId: '',
    cookieData: {},
    dadosRecuperados: {},
    dialog: false,
    TelefoneRecebe:'',
    Paises: [],
    selectedPais:null,
    messageaviso:""
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Pagamento - Cestas Porto Alegre'
			})
		},
    validarNumero() {
    // Remover espaços em branco e caracteres desnecessários
        let numeroTelefone = this.TelefoneRecebe.trim();

      try {
        // Parse do número de telefone
        const parsedNumber = phoneUtil.parse(numeroTelefone, 'ZZ');
        
        // Verificar se o número é válido
        if (phoneUtil.isValidNumber(parsedNumber)) {
          this.aplicarMascara(); // Formatar o número de telefone
        } else {
          this.$mensagemAviso('Número de telefone inválido');
          this.TelefoneRecebe = ''; // Limpar o campo
          this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
        }
      } catch (e) {
        console.error('Erro ao processar o número de telefone:', e);
        this.TelefoneRecebe = ''; // Limpar o campo
        this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
      }
    },
    aplicarMascara() {
        let input = this.TelefoneRecebe.replaceAll("(","").replaceAll(")","").replaceAll("-","");

        let fonePais = this.Paises.filter(x=> x.cpaId == this.selectedPais)[0];

        input =  "+" + fonePais.cpaFone + this.TelefoneRecebe;

        try {
            const parsedNumber = phoneUtil.parseAndKeepRawInput(input, 'ZZ');
            const isValid = phoneUtil.isValidNumber(parsedNumber);

            if (isValid) {
            const formattedNumber = phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
            this.TelefoneRecebe = formattedNumber;
            } else {
            console.error('Número de telefone inválido');
            }
        } catch (e) {
            console.error('Erro ao processar o número de telefone:', e);
        }
    },
    formatarValor(valor) {
      if (valor != '' && valor != null) {
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      }
      else {
        valor = 0;
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      }

    },
    formattedDate(value) {
      if (value != null || value != "") {
        return this.$moment(value).format('DD/MM/YYYY')
      }
      else {
        return "";
      }

    },
    async createPaymentBrick() {
    },
    async CarregaPedido(){

      let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (dadosArmazenados) {
              dadosArmazenados = JSON.parse(dadosArmazenados);
              let _situacao = 'Aguardando';
              let _pspId = 1;
              let _retorno = 1;
              let _erro = '';

              //dados do pedido
              try {
                      
                      let _pedido = {
                          PedId: 0,
                          GrtId: 7,
                          CdtId: 28,
                          IdiId: 1,
                          PedDataPedido: moment().format('YYYY-MM-DD'),
                          PedOrigemPedido: 'Site',
                          CliId: this.dadosRecuperados.usuarioId,
                          PedFormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
                          PedTipoFrete: dadosArmazenados.agenda[0].Horarios.Tipo,
                          PedValorFrete: dadosArmazenados.agenda[0].Horarios.Valor,
                          CupId : 0,
                          PedCodigoCupom: dadosArmazenados.cupom.length > 0 ? dadosArmazenados.cupom[0].numCupom : '',
                          PedValorCupom: dadosArmazenados.cupom.length > 0 ? dadosArmazenados.cupom[0].ValorCupom : 0,
                          PedObsCliente: '',
                          PedObsLoja: '',
                          PedRetornoPgto: dadosArmazenados.pagamento[0].RetornoPgto,
                          PedCodigoRetornoPgto: dadosArmazenados.pagamento[0].CodRetornoPgto.toString(),
                          PspId: _pspId,
                          PedSituacaoPedido: _situacao,
                          PedObsCliente: dadosArmazenados.agenda[0].ObsCliente
                        }

                      await axios.post(`/Process/PedPedidos/Add`, _pedido)
                                .then(response => {
                                    if (response.data > 0) {
                                            _pedido.PedId = response.data; 
                                            this.NumeroPedido = _pedido.PedId;
                                            console.log('_pedido adicionado')
                                            console.log(_pedido)                
                                    }
                                });

                //itens do pedido
                let _pedidoItens = [];
                if (dadosArmazenados.produtos != '') {
                    dadosArmazenados.produtos.forEach((itemprod) => {
                      _pedidoItens.push(
                          {
                            PdiId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedId: _pedido.PedId,
                            ProId: itemprod.proId,
                            PdiNomeProduto: itemprod.proNomeProduto,
                            PdiQtdeProduto: 1,
                            PdiValorProduto: itemprod.proProdutosPrecos[0].ppcValorPor,
                            PdiValorTotalProduto: itemprod.proProdutosPrecos[0].ppcValorPor,
                            PdiDataCadastro: moment().format('YYYY-MM-DD'),
                            PdiCampoExtra2: itemprod.proCampoExtra2,
                          }
                      );
                    });
                }

                await _pedidoItens.forEach((itens) => {
                  axios.post(`/Process/PedPedidoItem/Add`, itens)
                        .then(response => {
                            if (response.data > 0) {
                              console.log('_pedidoItens adicionado')
                              console.log(itens)    
                              axios.put(`/Process/ProProdutosPrecos/UpdateEstoque/`+ itens.ProId + '/' + itens.PdiQtdeProduto)
                                  .then(response => {
                                      if (response.data > 0) {
                                        console.log('Estoque atualizado')        
                                      }
                                  });
                            }
                        });
                })

                //itens adicional do pedido
                let _pedidoItensAvulsos = [];
                if (dadosArmazenados.avulsos != '') {
                    dadosArmazenados.avulsos.forEach((itemprod) => {
                      _pedidoItensAvulsos.push(
                          {
                            PiaId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedId: _pedido.PedId,
                            PraId: itemprod.praId,
                            PiaNomeProduto: itemprod.praNomeProduto,
                            PiaQtdeProduto: 1,
                            PiaValorProduto: itemprod.praValorPor,
                            PiaValorTotalProduto: itemprod.praValorPor,
                            PiaDataCadastro: moment().format('YYYY-MM-DD'),
                            PiaCampoExtra1: itemprod.praCampoExtra1,
                            PiaCampoExtra2: itemprod.praCampoExtra2,  // arte
                            PiaCampoExtra3: itemprod.praCampoExtra3,  //foto
                          }
                      );
                    });

                    await _pedidoItensAvulsos.forEach((itens) => {
                    axios.post(`/Process/PedPedidoItemAdicional/Add`, itens)
                          .then(response => {
                              if (response.data > 0) {
                                console.log('_pedidoItensAvulsos adicionado')
                                console.log(itens)         
                              }
                          });
                })
                }
              

              var _PagHoraIni = "";
              var _PagMinIni = "";
              var _PagHoraFim = "";
              var _PagMinFim = "";

              if (dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni === undefined || dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni === null) {
                  _PagHoraIni = dadosArmazenados.agenda[0].Horarios.Agendamento.excHoraIni;
                  _PagMinIni = dadosArmazenados.agenda[0].Horarios.Agendamento.excMinIni;
                  _PagHoraFim = dadosArmazenados.agenda[0].Horarios.Agendamento.excHoraFim;
                  _PagMinFim = dadosArmazenados.agenda[0].Horarios.Agendamento.excMinFim;
                } else {
                  _PagHoraIni = dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni;
                  _PagMinIni = dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinIni;
                  _PagHoraFim =  dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraFim;
                  _PagMinFim = dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinFim;
                }
                //pedido agendamento
                let _pedidoAgendamento = {
                  PagId: 0,
                  GrtId: 7,
                  CdtId: 28,
                  IdiId: 1,
                  PagDiaEntrega: dadosArmazenados.agenda[0].DataEntrega,
                  PagHoraIni: _PagHoraIni,
                  PagMinIni: _PagMinIni,
                  PagHoraFim: _PagHoraFim,
                  PagMinFim: _PagMinFim,
                  PagUrlImagemEntrega:'',
                  PedId:_pedido.PedId,
                }

                await axios.post(`/Process/PedPedidoAgendamento/Add`, _pedidoAgendamento)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoAgendamento adicionado')
                                    console.log(_pedidoAgendamento)                
                            }
                        });


                  //pedido Endereço
                  let _pedidoEndereco = {
                    PdcId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PdcEndereco: dadosArmazenados.agenda[0].Endereco,
                    PdcNumero: parseInt(dadosArmazenados.agenda[0].Numero,10),
                    PdcComplemento: dadosArmazenados.agenda[0].Complemento,
                    PdcObsEndereco:'',
                    pdcCep:'',
                    PdcPais:'',
                    PdcEstado:'',
                    PdcCidade:'',
                    PdcBairro: dadosArmazenados.agenda[0].BairroTexto,
                    CpaId:1,
                    CetId: dadosArmazenados.agenda[0].Estado!= null ? parseInt(dadosArmazenados.agenda[0].Estado,10): 0,
                    CidId: dadosArmazenados.agenda[0].Cidade != null ? parseInt(dadosArmazenados.agenda[0].Cidade,10): 0,
                    BaiId: dadosArmazenados.agenda[0].Bairro != null ? parseInt(dadosArmazenados.agenda[0].Bairro,10) : 0,
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoEndereco adicionado')
                                    console.log(_pedidoEndereco)                
                            }
                        });


                  if (dadosArmazenados.mensagem != '') {

                    let _recebeCesta = dadosArmazenados.agenda[0].NomePara;

                  if (dadosArmazenados.agenda[0].MesmoRecebe == "false"){
                        _recebeCesta += " - (Quem vai receber: " + dadosArmazenados.agenda[0].NomePara2  + " )"
                  }

                  //pedido Mensagem
                  let _pedidoMensagem = {
                    PmsId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PmsUrlImagem: '',
                    PmsNomeDe: dadosArmazenados.mensagem[0].NomeDe,
                    PmsNomePara: _recebeCesta,
                    PmsTexto: dadosArmazenados.mensagem[0].Mensagem,
                    PmsTelefoneRecebe: dadosArmazenados.agenda[0].TelefoneRecebe,
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoMensagem adicionado')
                                    console.log(_pedidoMensagem)                
                            }
                        });
                }

                if (dadosArmazenados.pagamento[0].FormaPgto != 'credit_card') {
                  //pedido Comprovante
                  let _pedidoComprovante = {
                    PpcId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PpcUrlImagem: dadosArmazenados.pagamento[0].FotoComprova,
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoComprovante adicionado')
                                    console.log(_pedidoComprovante)                
                            }
                        });

                        dadosArmazenados.pedidoId = this.NumeroPedido;
                        localStorage.setItem('dadoscompraCestasPortoAlegre', JSON.stringify(dadosArmazenados))
                }
              } catch (erro1) {
                _retorno = 0;
                _erro = "Pedido - " + erro1;
                this.EnviaEmailErro(_erro,JSON.stringify(dadosArmazenados));
              }
              return _retorno;
            }
    },
    EnviaEmailErro(erro, dados){
      
     let texto = "Dados do pedido:  <br><br>" + dados + "<br><br> Erro: " + erro;

      //envia email com erro e dados do pedido
      /* let _dadosEmail = {
            nomeDe: 'Site Cestas Porto Alegre',
            nomePara: "Cestas Porto Alegre",
            assunto: 'Falha no cadastro de pedido ',
            destinatario: 'contato@cestasportoalegre.com',
            emailResposta: 'contato@cestasportoalegre.com',
            textoEmail: texto,
            nomeAnexo: '',
            anexo: '',
            tipoAnexo: ''
          }
          axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
            .then(response => {
              if (response.data == 1) {
                console.log('email enviado')
              }
            });*/

           //envia email com erro e dados do pedido
       let _dadosEmail2 = {
            nomeDe: 'Site Cestas Porto Alegre',
            nomePara: "Avance Digital",
            assunto: 'Falha no cadastro de pedido ',
            destinatario: 'contato@avancedigital.com.br',
            emailResposta: 'contato@cestasportoalegre.com',
            textoEmail: texto,
            nomeAnexo: '',
            anexo: '',
            tipoAnexo: ''
          }
          axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
            .then(response => {
              if (response.data == 1) {
                console.log('email 2 enviado')
              }
            });
    },
    AddWhatsappCli(Whats){
      if (Whats == null || Whats == ""){
                this.messageaviso = "Preencha o Telefone.";
                this.dialog = true;
            }
            else{
              this.Clientes.cliWhatsapp = Whats;
                axios.put(`/Process/CliClientes/Update`, this.Clientes)
                    .then(response => {
                      if (response.status == 200) {
                        this.dialog = false;
                      }
                    });
            }
    },
  },
  mounted() {
    //this.createPaymentBrick();
  },
 async created() {
    this.carregaMetas();

    const cookieData = Cookies.get(this.$CookieCliente);
    if (cookieData) {
      const _dadosRecuperados = JSON.parse(cookieData);
       
     await axios.get(`/Process/CliClientes/GetById/` + _dadosRecuperados.usuarioId)
            .then(response => {
              this.Clientes = response.data;
              if (this.Clientes != '') {
                     if (this.Clientes.cliWhatsapp == null || this.Clientes.cliWhatsapp == ""){
                            this.dialog = true;
                     }
                }
            });
      }
    
    axios.get('/Process/InsInstitucional/institucional/24/textopagamento')
      .then(response => {
        this.logintopo = response.data;
        //console.log('this.logintopo')
        //console.log(this.logintopo)
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/ConConfigFormasPagamento/GetById/4')
      .then(response => {
        this.ListaformasPagamento = response.data;
        //console.log('ListaformasPagamento')
        //console.log(this.ListaformasPagamento)
      })
      .catch(error => {
        console.error(error);
      });

      axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.sort((a, b) => {
                                                            const nomeA = a.cpaNome || ""; 
                                                            const nomeB = b.cpaNome || ""; 
                                                            return nomeA.localeCompare(nomeB);
                                                            });
               // console.log('this.Paises')
               // console.log(this.Paises)
               this.selectedPais = 76;
                });

    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
    dadosArmazenados = JSON.parse(dadosArmazenados);

    let _totalProdutos = 0;
    let _cupom = 0;
    if (dadosArmazenados.produtos != '') {
      dadosArmazenados.produtos.forEach((itemprod) => {
        _totalProdutos += parseFloat(itemprod.proProdutosPrecos[0].ppcValorPor * 1)
      });
      this.TotalProdutos = _totalProdutos;
    }

    
    if (dadosArmazenados.avulsos != '') {
      if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
        dadosArmazenados.avulsos.forEach((itemavul) => {
          _totalProdutos += parseFloat(itemavul.praValorPor)
        });
        this.TotalProdutos = _totalProdutos;
      }
    }

    if (dadosArmazenados.agenda != '') {
      _totalProdutos += parseFloat(dadosArmazenados.agenda[0].Horarios.Valor);
      this.TotalFrete = parseFloat(dadosArmazenados.agenda[0].Horarios.Valor);
    }

    if (dadosArmazenados.cupom != '') {
      _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10);
      this.TotalCupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10);
    }

    this.TotalCarrinho = _totalProdutos - _cupom;
    this.selectedHorarioId  = dadosArmazenados.agenda[0];
  },
}
</script>

<style>
@media (max-width:767px) {
.posicao {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:767px) {
.posicao {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:1200px) {
    .posicao {
    position:fixed; 
    padding-right: 10px;
    padding-top: 0px;
    z-index: 9;
    width: 28%;
}

.fontbtn{
    font-size: 15px;
}
}


.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}
</style>
