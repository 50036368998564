<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section
        class="main-carrinho"
        :style="{
          backgroundImage: 'url(' + $imgURL + logintopo.pfcUrlImgFundo + ')',
          backgroundSize: 'cover',
          minHeight: '500px',
        }"
        v-if="logintopo != ''"
      >
        <section class="">
          <div class="container" style="padding-bottom: 60px">
            <div class="row">
              <div class="col-lg-12" style="padding-top: 60px">
                <div class="row">
                  <div class="col-lg-5" style="padding: 30px">
                    <div class="row">
                      <h3 style="color: #ffffff" v-if="!ValidacaoCartao">
                        {{ logintopo.pfcTituloTextoFinalizado }}
                      </h3>
                      <div
                        class="col-lg-12"
                        v-if="TipoPgto == 'Pix' && !ValidacaoCartao"
                      >
                        <span v-html="logintopo.pfcDados"></span>
                      </div>
                      <div class="col-lg-12" v-else-if="!ValidacaoCartao">
                        <span v-html="logintopo.pfcTextoFinalizado"></span>
                      </div>
                      <div class="col-lg-12" v-if="ValidacaoCartao">
                        <span
                          style="color: #ffffff; font-size: 20px"
                          v-html="TextoComplementar"
                        ></span>
                      </div>
                      <div
                        class="col-lg-12"
                        style="padding-top: 60px"
                        v-if="TipoPgto != 'Pix' && !ValidacaoCartao"
                      >
                        <a
                          href="#"
                          @click="VerMeusPedidos()"
                          class="btn btn-primary px-5 mt-0"
                          style="margin-bottom: 20px; color: #ffffff"
                          >Ver Meus Pedidos</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="box_content">
                      <!-- Dados do Cartão -->
                      <div id="statusScreenBrick_container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from "axios";
import { SHA1, SHA256 } from "crypto-js";
import moment from "moment";
import CheckAcesso from "../components/PageCheckAcesso.vue";
import Cookies from "js-cookie";
import { useHead } from "@vueuse/head";

export default {
  beforeRouteEnter(to, from, next) {
    let dadosArmazenados = localStorage.getItem("dadoscompraCestasPortoAlegre");
    dadosArmazenados = JSON.parse(dadosArmazenados);

    const script = document.createElement("script");
    script.src = "https://sdk.mercadopago.com/js/v2";
    script.async = true;
    script.onload = () => {
      // O SDK foi carregado com sucesso, agora você pode inicializá-lo
      const mp = new MercadoPago(
        "APP_USR-5f6e364d-88ee-463d-b453-88faef5c08ac",
        {
          locale: "pt-BR",
          three_d_secure_mode: "optional",
        }
      );

      const bricksBuilder = mp.bricks();

      const renderStatusScreenBrick = async (
        bricksBuilder,
        paymentid,
        externalResourceURL,
        creq
      ) => {
        const settings = {
          initialization: {
            paymentId: paymentid, // id do pagamento a ser mostrado
            additionalInfo: {
              externalResourceURL: externalResourceURL,
              creq: creq,
            },
          },
          callbacks: {
            onReady: () => {
              /*
                  Callback chamado quando o Brick estiver pronto.
                  Aqui você pode ocultar loadings do seu site, por exemplo.
                */
            },
            onError: (error) => {
              // callback chamado para todos os casos de erro do Brick
              console.error(error);
            },
          },
          customization: {},
        };
        window.statusScreenBrickController = await bricksBuilder.create(
          "statusScreen",
          "statusScreenBrick_container",
          settings
        );
      };

      if (dadosArmazenados) {
        renderStatusScreenBrick(
          bricksBuilder,
          dadosArmazenados.pagamento[0].CodRetornoPgto,
          dadosArmazenados.pagamento[0].ExternalResource,
          dadosArmazenados.pagamento[0].Creq
        );
      } else {
        window.location.href = "/meuspedidos";
      }
    };
    document.head.appendChild(script);
    next();
  },
  name: "PedidoRealizadoView",
  components: {
    CheckAcesso,
  },
  data: () => ({
    logintopo: [],
    Formulario: {
      NomeDe: "",
      NomePara: "",
      Mensagem: "",
    },
    AbreSenha: false,
    AbreCad: false,
    TipoPgto: "",
    ValidacaoCartao: false,
    TextoComplementar: "",
    paymentId: 0,
    cliId: 0,
    intervalId: null,
    maxIntervalTime: 120000, // Tempo máximo em milissegundos (por exemplo, 120 segundos)
    elapsedTime: 0, // Tempo decorrido
    NumeroPedido: "",
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: "Pagamento Finalizado - Cestas Porto Alegre",
      });
    },
    VerMeusPedidos() {
      this.$router.push("/meuspedidos");
    },
    async CarregaPedido() {
      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData === undefined) {
        // O cookie está expirado
        this.$router.push("/login");
      } else {
        const _dadosRecuperados = JSON.parse(cookieData);

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (dadosArmazenados) {
          dadosArmazenados = JSON.parse(dadosArmazenados);
          let _situacao = "Aguardando";
          let _pspId = 1;
          let _retorno = 1;
          let _erro = "";

          if (dadosArmazenados.pagamento[0].RetornoPgto == "approved") {
            _situacao = "Aprovado";
            _pspId = 2;
          }

          try {
            //dados do pedido
            let _pedido = {
              PedId: 0,
              GrtId: 7,
              CdtId: 28,
              IdiId: 1,
              PedDataPedido: moment().format("YYYY-MM-DD"),
              PedOrigemPedido: "Site",
              CliId: _dadosRecuperados.usuarioId,
              PedFormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
              PedTipoFrete: dadosArmazenados.agenda[0].Horarios.Tipo,
              PedValorFrete: dadosArmazenados.agenda[0].Horarios.Valor,
              CupId: 0,
              PedCodigoCupom:
                dadosArmazenados.cupom.length > 0
                  ? dadosArmazenados.cupom[0].numCupom
                  : "",
              PedValorCupom:
                dadosArmazenados.cupom.length > 0
                  ? dadosArmazenados.cupom[0].ValorCupom
                  : 0,
              PedObsCliente: "",
              PedObsLoja: "",
              PedRetornoPgto: dadosArmazenados.pagamento[0].RetornoPgto,
              PedCodigoRetornoPgto:
                dadosArmazenados.pagamento[0].CodRetornoPgto.toString(),
              PspId: _pspId,
              PedSituacaoPedido: _situacao,
              PedObsCliente: dadosArmazenados.agenda[0].ObsCliente,
            };

            await axios
              .post(`/Process/PedPedidos/Add`, _pedido)
              .then((response) => {
                if (response.data > 0) {
                  _pedido.PedId = response.data;
                  this.NumeroPedido = _pedido.PedId;
                  console.log("_pedido adicionado");
                  console.log(_pedido);
                }
              });

            //itens do pedido
            let _pedidoItens = [];
            if (dadosArmazenados.produtos != "") {
              dadosArmazenados.produtos.forEach((itemprod) => {
                _pedidoItens.push({
                  PdiId: 0,
                  GrtId: 7,
                  CdtId: 28,
                  IdiId: 1,
                  PedId: _pedido.PedId,
                  ProId: itemprod.proId,
                  PdiNomeProduto: itemprod.proNomeProduto,
                  PdiQtdeProduto: 1,
                  PdiValorProduto: itemprod.proProdutosPrecos[0].ppcValorPor,
                  PdiValorTotalProduto:
                    itemprod.proProdutosPrecos[0].ppcValorPor,
                  PdiDataCadastro: moment().format("YYYY-MM-DD"),
                  PdiCampoExtra1: itemprod.proCampoExtra2,
                });
              });
            }

            await _pedidoItens.forEach((itens) => {
              axios
                .post(`/Process/PedPedidoItem/Add`, itens)
                .then((response) => {
                  if (response.data > 0) {
                    console.log("_pedidoItens adicionado");
                    console.log(itens);
                    axios
                      .put(
                        `/Process/ProProdutosPrecos/UpdateEstoque/` +
                          itens.ProId +
                          "/" +
                          itens.PdiQtdeProduto
                      )
                      .then((response) => {
                        if (response.data > 0) {
                          console.log("Estoque atualizado");
                        }
                      });
                  }
                });
            });

            //itens adicional do pedido
            let _pedidoItensAvulsos = [];
            if (dadosArmazenados.avulsos != "") {
              dadosArmazenados.avulsos.forEach((itemprod) => {
                _pedidoItensAvulsos.push({
                  PiaId: 0,
                  GrtId: 7,
                  CdtId: 28,
                  IdiId: 1,
                  PedId: _pedido.PedId,
                  PraId: itemprod.praId,
                  PiaNomeProduto: itemprod.praNomeProduto,
                  PiaQtdeProduto: 1,
                  PiaValorProduto: itemprod.praValorPor,
                  PiaValorTotalProduto: itemprod.praValorPor,
                  PiaDataCadastro: moment().format("YYYY-MM-DD"),
                  PiaCampoExtra1: itemprod.praCampoExtra1,
                  PiaCampoExtra2: itemprod.praCampoExtra2, // arte
                  PiaCampoExtra3: itemprod.praCampoExtra3, //foto
                });
              });

              await _pedidoItensAvulsos.forEach((itens) => {
                axios
                  .post(`/Process/PedPedidoItemAdicional/Add`, itens)
                  .then((response) => {
                    if (response.data > 0) {
                      console.log("_pedidoItensAvulsos adicionado");
                      console.log(itens);
                    }
                  });
              });
            }

            var _PagHoraIni = "";
            var _PagMinIni = "";
            var _PagHoraFim = "";
            var _PagMinFim = "";

            if (
              dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni ===
                undefined ||
              dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni ===
                null
            ) {
              _PagHoraIni =
                dadosArmazenados.agenda[0].Horarios.Agendamento.excHoraIni;
              _PagMinIni =
                dadosArmazenados.agenda[0].Horarios.Agendamento.excMinIni;
              _PagHoraFim =
                dadosArmazenados.agenda[0].Horarios.Agendamento.excHoraFim;
              _PagMinFim =
                dadosArmazenados.agenda[0].Horarios.Agendamento.excMinFim;
            } else {
              _PagHoraIni =
                dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni;
              _PagMinIni =
                dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinIni;
              _PagHoraFim =
                dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraFim;
              _PagMinFim =
                dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinFim;
            }
            //pedido agendamento
            let _pedidoAgendamento = {
              PagId: 0,
              GrtId: 7,
              CdtId: 28,
              IdiId: 1,
              PagDiaEntrega: dadosArmazenados.agenda[0].DataEntrega,
              PagHoraIni: _PagHoraIni,
              PagMinIni: _PagMinIni,
              PagHoraFim: _PagHoraFim,
              PagMinFim: _PagMinFim,
              PagUrlImagemEntrega: "",
              PedId: _pedido.PedId,
            };

            await axios
              .post(`/Process/PedPedidoAgendamento/Add`, _pedidoAgendamento)
              .then((response) => {
                if (response.data > 0) {
                  console.log("_pedidoAgendamento adicionado");
                  console.log(_pedidoAgendamento);
                }
              });

            //pedido Endereço
            let _pedidoEndereco = {
              PdcId: 0,
              GrtId: 7,
              CdtId: 28,
              IdiId: 1,
              PdcEndereco: dadosArmazenados.agenda[0].Endereco,
              PdcNumero: parseInt(dadosArmazenados.agenda[0].Numero, 10),
              PdcComplemento: dadosArmazenados.agenda[0].Complemento,
              PdcObsEndereco: "",
              pdcCep: "",
              PdcPais: "",
              PdcEstado: "",
              PdcCidade: "",
              PdcBairro: dadosArmazenados.agenda[0].BairroTexto,
              CpaId: 1,
              CetId:
                dadosArmazenados.agenda[0].Estado != null
                  ? parseInt(dadosArmazenados.agenda[0].Estado, 10)
                  : 0,
              CidId:
                dadosArmazenados.agenda[0].Cidade != null
                  ? parseInt(dadosArmazenados.agenda[0].Cidade, 10)
                  : 0,
              BaiId:
                dadosArmazenados.agenda[0].Bairro != null
                  ? parseInt(dadosArmazenados.agenda[0].Bairro, 10)
                  : 0,
              PedId: _pedido.PedId,
            };

            await axios
              .post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
              .then((response) => {
                if (response.data > 0) {
                  console.log("_pedidoEndereco adicionado");
                  console.log(_pedidoEndereco);
                }
              });

            if (dadosArmazenados.mensagem != "") {
              let _recebeCesta = dadosArmazenados.mensagem[0].NomePara;

               _recebeCesta +=
                  " - (Quem vai receber: " +
                  dadosArmazenados.agenda[0].NomePara2 +
                  " )";

                var _mensagem = dadosArmazenados.mensagem[0].Mensagem;
                if (dadosArmazenados.mensagem[0].CartaoBranco){
                  _mensagem = "Enviar Cartão Branco"
                }
                if (dadosArmazenados.mensagem[0].SemCartao){
                  _mensagem = "Sem Cartão"
                }

              //pedido Mensagem
              let _pedidoMensagem = {
                PmsId: 0,
                GrtId: 7,
                CdtId: 28,
                IdiId: 1,
                PmsUrlImagem: "",
                PmsNomeDe: dadosArmazenados.mensagem[0].NomeDe,
                PmsNomePara: _recebeCesta,
                PmsTexto: _mensagem,
                PmsTelefoneRecebe: dadosArmazenados.agenda[0].TelefoneRecebe,
                PedId: _pedido.PedId,
              };

              await axios
                .post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                .then((response) => {
                  if (response.data > 0) {
                    console.log("_pedidoMensagem adicionado");
                    console.log(_pedidoMensagem);
                  }
                });
            }

            if (dadosArmazenados.pagamento[0].FormaPgto != "credit_card") {
              //pedido Comprovante
              let _pedidoComprovante = {
                PpcId: 0,
                GrtId: 7,
                CdtId: 28,
                IdiId: 1,
                PpcUrlImagem: dadosArmazenados.pagamento[0].FotoComprova,
                PedId: _pedido.PedId,
              };

              await axios
                .post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                .then((response) => {
                  if (response.data > 0) {
                    console.log("_pedidoComprovante adicionado");
                    console.log(_pedidoComprovante);
                  }
                });
            }
            if (dadosArmazenados.pagamento[0].RetornoPgto == "approved") {
              this.EnviaAviso();
            }

            if (dadosArmazenados.pagamento[0].UrlPix != "") {
              this.EnviaEmailAviso(dadosArmazenados.pagamento[0].UrlPix);
            }
            return _retorno;
          } catch (erro1) {
            _retorno = 0;
            _erro = "Pedido - " + erro1;
            this.EnviaEmailErro(_erro, JSON.stringify(dadosArmazenados));
            return _retorno;
          }
        }
      }
    },
    EnviaAviso() {
      axios.get(`/Process/CadMensagensPadrao/GetAll`).then((response) => {
        this.templateEmail = response.data.filter((x) => x.mpdId == 3);

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
          const _dadosRecuperados = JSON.parse(cookieData);

          let _dadosEmail = {
            nomeDe: "Cestas Porto Alegre",
            nomePara: _dadosRecuperados.nomeUsuario,
            assunto: "Andamento Pedido: " + this.NumeroPedido,
            destinatario: _dadosRecuperados.email,
            emailResposta: "pedidos@cestasportoalegre.com",
            textoEmail: this.templateEmail[0].mpdTexto
              .replace("[NomeCliente]", _dadosRecuperados.nomeUsuario)
              .replace("[NumeroPedido]", this.NumeroPedido)
              .replace("[Status]", "Aprovado"),
            nomeAnexo: "",
            anexo: "",
            tipoAnexo: "",
          };
          axios
            .post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
            .then((response) => {
              if (response.data == 1) {
                console.log("email enviado");
              }
            });

          //envia cestas
          let _dadosEmail2 = {
            nomeDe: "Cestas Porto Alegre",
            nomePara: "Cestas Porto Alegre",
            assunto: "Novo Pedido Site: " + this.NumeroPedido,
            destinatario: "pedidos@cestasportoalegre.com",
            emailResposta: "pedidos@cestasportoalegre.com",
            textoEmail:
              "Novo pedido de compra no site, Cliente: " +
              _dadosRecuperados.nomeUsuario +
              " - Pedido: " +
              this.NumeroPedido,
            nomeAnexo: "",
            anexo: "",
            tipoAnexo: "",
          };
          axios
            .post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
            .then((response) => {
              if (response.data == 1) {
                console.log("email 2 enviado");
              }
            });
        }
      });
    },
    EnviaEmailAviso(value) {
      axios.get(`/Process/CadMensagensPadrao/GetAll`).then((response) => {
        this.templateEmail = response.data.filter((x) => x.mpdId == 4);

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
          const _dadosRecuperados = JSON.parse(cookieData);

          let _dadosEmail = {
            nomeDe: "Cestas Porto Alegre",
            nomePara: _dadosRecuperados.nomeUsuario,
            assunto: "Link Pagamento Pedido: " + this.NumeroPedido,
            destinatario: _dadosRecuperados.email,
            emailResposta: "contato@cestasportoalegre.com",
            textoEmail: this.templateEmail[0].mpdTexto
              .replace("[NomeCliente]", _dadosRecuperados.nomeUsuario)
              .replace("[NumeroPedido]", this.NumeroPedido)
              .replace("[LinkPgto]", value),
            nomeAnexo: "",
            anexo: "",
            tipoAnexo: "",
          };
          axios
            .post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
            .then((response) => {
              if (response.data == 1) {
                console.log("email enviado");
              }
            });
        }
      });
    },
    EnviaEmailErro(erro, dados) {
      let texto =
        "Dados do pedido:  <br><br>" + dados + "<br><br> Erro: " + erro;

      //envia email com erro e dados do pedido
      /* let _dadosEmail = {
             nomeDe: 'Site Cestas Porto Alegre',
             nomePara: "Cestas Porto Alegre",
             assunto: 'Falha no cadastro de pedido ',
             destinatario: 'contato@cestasportoalegre.com',
             emailResposta: 'contato@cestasportoalegre.com',
             textoEmail: texto,
             nomeAnexo: '',
             anexo: '',
             tipoAnexo: ''
           }
           axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
             .then(response => {
               if (response.data == 1) {
                 console.log('email enviado')
               }
             });*/

      //envia email com erro e dados do pedido
      let _dadosEmail2 = {
        nomeDe: "Site Cestas Porto Alegre",
        nomePara: "Avance Digital",
        assunto: "Falha no cadastro de pedido ",
        destinatario: "contato@avancedigital.com.br",
        emailResposta: "contato@cestasportoalegre.com",
        textoEmail: texto,
        nomeAnexo: "",
        anexo: "",
        tipoAnexo: "",
      };
      axios
        .post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
        .then((response) => {
          if (response.data == 1) {
            console.log("email 2 enviado");
          }
        });
    },
  },
  async created() {
    this.carregaMetas();

    let dadosArmazenados = localStorage.getItem("dadoscompraCestasPortoAlegre");

    if (dadosArmazenados) {
      dadosArmazenados = JSON.parse(dadosArmazenados);

      if (dadosArmazenados.pagamento[0].FormaPgto == "bank_transfer") {
        this.TipoPgto = "Pix";
      } else {
        this.TipoPgto = "Cartao";
      }

      if (
        dadosArmazenados.pagamento[0].FormaPgto == "credit_card" &&
        dadosArmazenados.pagamento[0].RetornoPgto == "pending"
      ) {
        this.ValidacaoCartao = true;
        this.TextoComplementar =
          "É necessário concluir validação com seu banco para confirmar o pagamento. <br><br> Após, você pode acompanhar o status do seu pedido através do menu Meus Pedidos.<br><br> Em Caso de não autorização, entre em contrato conosco para lhe enviarmos via link de pagamento.";
      }

      await axios
        .get("/Process/ConConfigFormasPagamento/GetById/4")
        .then((response) => {
          this.logintopo = response.data;
        })
        .catch((error) => {
          console.error(error);
        });

      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData === undefined) {
        this.$router.push("/login");
      } else {
        const _dadosRecuperados = JSON.parse(cookieData);

        this.paymentId = dadosArmazenados.pagamento[0].CodRetornoPgto;
        this.cliId = _dadosRecuperados.usuarioId;
      }

      let _result = await this.CarregaPedido();

      if (_result > 0) {
        if (dadosArmazenados.pagamento[0].RetornoPgto == "approved") {
          await this.EnviaAviso();
        }

        if (dadosArmazenados.pagamento[0].UrlPix != "") {
          await this.EnviaEmailAviso(dadosArmazenados.pagamento[0].UrlPix);
        }

        localStorage.removeItem(this.$DadosCompra);
        this.$store.commit("updateVariavelHeader2", "");

        if (dadosArmazenados.pagamento[0].RetornoPgto != "approved") {
          //fica testando
          const fetchData = () => {
            axios
              .get(
                "/Process/PgtoMercadoPago/payment/" +
                  this.paymentId +
                  "/" +
                  this.cliId
              )
              .then((response) => {
                if (response.data > 0) {
                  clearInterval(this.intervalId);
                  this.EnviaAviso();
                  this.$router.push("/meuspedidos");
                } else {
                  this.elapsedTime += 5000; // Atualiza o tempo decorrido
                  if (this.elapsedTime >= this.maxIntervalTime) {
                    clearInterval(this.intervalId); // Para o intervalo após o tempo máximo
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          };

          // Chame a função inicialmente
          fetchData();

          // Em seguida, use setInterval para disparar a função a cada 5 segundos
          this.intervalId = setInterval(fetchData, 5000); // 5000 milissegundos = 5 segundos
          //fica testando
        }
      } 
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99cc33;
  line-height: 1;
}

span p {
  color: #ffffff !important;
  font-size: 20px;
}
</style>
