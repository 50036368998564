<template>
    <div>
        <main class="main">
            <div class="page-content pt-6">
                <div class="container">
                    <div class="product-details-top mb-2">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="product-gallery product-gallery-vertical">
                                    <div class="row" v-if="ProdutoImg!='' && Produto!=''">
                                        <span v-if="Produto.proEtiqueta != '' && Produto.proEtiqueta != null" class="product-label label-sale" :style="{color: Produto.proEtiquetaCorFonte + ' !important', backgroundColor: Produto.proEtiquetaCorFundo + ' !important', fontSize: '25px',padding: '10px 12px'}" >{{ Produto.proEtiqueta }}</span>
                                        <span v-else-if="Produto.proProdutosPrecos[0].ppcValorDe!='' && Produto.proProdutosPrecos[0].ppcValorDe!=null  && Produto.proProdutosPrecos[0].ppcValorDe > 0" class="product-label label-sale">Promoção</span>
                                        <v-carousel show-arrows="hover" hide-delimiters class="altcel">
                                            <v-carousel-item v-for="itemimg in ProdutoImg" :key="itemimg.pimId"
                                                :src="$imgURL + itemimg.pimUrlImagem"
                                                cover
                                            ></v-carousel-item>
                                        </v-carousel>
                                        
                                    </div><!-- End .row -->
                                </div><!-- End .product-gallery -->
                            </div><!-- End .col-md-6 -->

                            <div class="col-lg-6 col-md-12" v-if="Produto!=''">
                                <div class="product-details product-details-centered">
                                    <h1 class="product-title" style="color: #B91F1C;margin-bottom: 0px !important;font-weight: 500;">{{Produto.proNomeProduto}} </h1>
                                    <div style="font-size: 14px;color:#333333;">Ref: {{Produto.proReferencia}}</div><!-- End .product-title -->
                                    <div class="row">
                                        <div class="col-lg-12 pb-3" style="padding: 20px;">
                                            <p v-html="Produto.proTexto1"></p>
                                        </div>
                                         <div class="row" style="padding-top: 20px;">
                                            <div class="col-md-12" style="padding-top: 30px;border-top: 1px solid #cccccc;">
                                                <div class="row">
                                                    <div class="col-lg-12" style="text-align: center;">
                                                        <h5 style="color: #B91F1C;">O que esta incluso</h5>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <ul>
                                                            <li v-for="itemx in Produto.proProdutosGrids.slice(0, (Produto.proProdutosGrids.length / 2))" :key="itemx.pgsId" style="font-size: 13px;">
                                                                {{ itemx.pgsNome }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <ul>
                                                            <li v-for="itemx in Produto.proProdutosGrids.slice(Produto.proProdutosGrids.length / 2)" :key="itemx.pgsId" style="font-size: 13px;">
                                                                {{ itemx.pgsNome }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                            </div>
                                            </div>
                                            <div v-if="vProdutosExtras!=''" class="col-lg-12" style="padding-top: 30px;border-top: 1px solid #cccccc;">
                                                   <div class="row" style="border: 0px solid #cccccc;padding: 10px;">
                                                        <div class="col-lg-12" style="text-align: center;">
                                                            <h5 style="color: #B91F1C;">Itens Extras</h5> 
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-12">
                                                             <div class="row" v-for="(itemextra,index) in vProdutosExtras" :key="index">
                                                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                                                    <img v-if="itemextra.praCampoExtra2!=''" :src="$imgURL + itemextra.praCampoExtra2" alt="" style="width: 100%;">
                                                                    <img v-if="itemextra.proProdutosAvulsosImagens!='' && itemextra.praCampoExtra2 ==''" :src="$imgURL + itemextra.proProdutosAvulsosImagens[0].paiUrlImagem" alt="" style="width: 100%;">
                                                                </div>
                                                                <div class="col-lg-3 col-md-3 col-sm-5 col-3 altavul" style="margin: 0 !important;">
                                                                    <button v-if="itemextra.praAceitaImg" class="btn btn-info" @click.prevent="openModal(index)" style="padding: 3px !important;min-width: 70px !important">Escolher arte</button>
                                                                </div>
                                                                <div class="col-lg-3 col-md-3 col-sm-5 col-3" style="margin: 0 !important;">
                                                                    <span class="d-flex align-items-center justify-content-center" style="font-size: 13px;height: 100%;">{{  itemextra.praNomeProduto }}</span>
                                                                </div>
                                                                <div class="col-lg-2 col-md-2 col-sm-3 col-2" style="margin: 0 !important;">
                                                                    <span class="d-flex align-items-center justify-content-center" style="font-size: 13px;height: 100%;">R$ {{  formatarValor(itemextra.praValorPor) }}</span>
                                                                </div>
                                                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                                                    <a href="#" @click.prevent="ExcluiAvulso(index)" class="d-flex align-items-center justify-content-center" style="height: 100%;"><img src="/images/remover.png" /></a>
                                                                </div>
                                                             </div>
                                                        </div>                                                        
                                                   </div>
                                           </div>
                                           </div>
                                     </div>

                                    <div class="product-details-action bg-light" style="padding-top: 10px;">
                                        <div class="details-action-col">
                                            <div class="product-price" style="padding-right: 20px;padding-top: 14px;">
                                             <span style="font-size: 14px;padding-right: 5px;">  {{ vProdutosExtras == '' ? 'Valor Produto': 'Valor Produto + Extras' }} </span> R$ {{ formatarValor(valorTotal) }}
                                            </div><!-- End .product-price -->

                                            <button v-if="ProdutoPreco != '' && ProdutoPreco[0].ppcEstoque > 0" class="btn-product btn-cart"  @click="AdicionaCarrinho()">Adicionar Carrinho</button>
                                            <a v-else  class="btn-product btn-cart">Indisponível</a>
                                        </div><!-- End .details-action-col -->
                                        <div class="details-action-col">
                                           <a href="#" @click.prevent="openModal2()" style="display: inline-flex;"><img src="/images/ENTREGA.png" style="height: 35px;padding-right: 10px;" /><span style="padding-right: 5px;font-size: 18px;color:#333333;">Calcule Frete</span></a>
                                        </div>

                                    </div><!-- End .product-details-action -->
                                </div><!-- End .product-details -->
                            </div><!-- End .col-md-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .product-details-top -->
                    <div id="itemExtras" class="container" v-if="ProdutoAvulsos!='' && ProdutoPreco != '' && ProdutoPreco[0].ppcEstoque > 0">
                        <h2 class="title text-center mb-4">Adicione produtos avulsos e incremente sua cesta!</h2>
                        <!-- End .title text-center -->
        
                        <div class="cat-blocks-container">
                            <div class="row">
                                <div class="col-6 col-sm-4 col-lg-2" v-for="itema in ProdutoAvulsos" :key="itema.papId">
                                    <a href="#" @click.prevent="AdicionaAvulso(itema)" class="cat-block">
                                        <figure v-if="itema.proProdutosAvulso.proProdutosAvulsosImagens!=''">
                                            <span>
                                                <img :src="$imgURL + itema.proProdutosAvulso.proProdutosAvulsosImagens[0].paiUrlImagem" alt="">
                                            </span>
                                        </figure>
        
                                        <h3 class="cat-block-title">{{itema.proProdutosAvulso.praNomeProduto}}</h3>
                                        <span class="new-price" style="font-size: 14px;">R$ {{ formatarValor(itema.proProdutosAvulso.praValorPor) }}</span><!-- End .cat-block-title -->
                                          <span class="new-price" style="font-size: 14px;">Adicionar</span>
                                    </a>
                            </div><!-- End .row -->
                            </div>
                        </div><!-- End .cat-blocks-container -->
                    </div><!-- End .container -->
                </div><!-- End .container -->
                <div v-if="infocall!=''" class="cta bg-image bg-dark" :style="{backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom:'80px'}">
                    <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-sm-10 col-md-8 col-lg-6">
                        <div class="cta-text text-center">
                            <h3 class="cta-title text-white">{{infocall[0].itiNome}}</h3><!-- End .cta-title -->
                            <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1" style="color: #ccc !important;"></p><!-- End .cta-desc -->

                            <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded" target="_blank"><span>{{infocall[0].itiTextoBtn1}}</span><i
                                class="icon-long-arrow-right"></i></a>
                            <a v-if="infocall[0].itiReferencia!='' && infocall[0].itiReferencia!=null" :href="infocall[0].itiReferencia" class="btn btn-warning btn-rounded" style="margin: 15px;"><span>{{infocall[0].itiCampoExtra1}}</span><i
                                class="icon-long-arrow-right"></i></a>
                        </div><!-- End .cta-text -->
                        </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
                    </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .cta -->
            </div><!-- End .page-content -->
         
        </main>
    </div>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <!-- Toolbar fixo -->
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="ListaArtesCateg!=''" style="padding: 5px;padding-top: 5px;">
                    <v-select-wrapper>
                        <select class="styled-select fontstyle" v-model="CategArteSelected" @change="FiltraCategArtes()" style="cursor: pointer;">
                            <option value="" disabled selected>Selecione Categoria</option>
                            <option v-for="item in ListaArtesCateg" :key="item.sgiId" :value="item.sgiId">{{ item.sgiNomeSegmento }}</option>
                        </select>
                        <span class="caret"></span>
                    </v-select-wrapper>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn variant="text" class="btn btn-success" @click="GravarArtes();" style="font-size:15px;color: #fff;
    background-color: #218838;
    border-color: #1e7e34;">Concluir</v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                <v-item-group v-model="ArteSelected">
                    <v-container>
                    <v-row>
                        <v-col v-for="mens in artes" :key="mens.itiId" cols="6" md="3">
                        <v-item>
                            <v-card 
                            class="d-flex align-center" 
                            dark height="230" 
                            @click="toggle(mens)"
                            :class="{ 'border-green': ArteSelected === mens }"
                            >
                            <div class="flex-grow-1 text-center" style="justify-content: center;">
                                <img :src="$imgURL + mens.insInstitucionalImagens[0].itgUrlImagem" style="height: 150px;" class="mx-auto" />
                                {{ mens.itiNome }}
                            </div>
                            <v-icon v-if="ArteSelected === mens" class="check-icon">mdi-check</v-icon>
                            </v-card>
                        </v-item>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-item-group>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>

    <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialogfrete" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialogfrete = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="ListaArtesCateg!=''" style="padding: 5px;padding-top: 15px;">
                   <div style="display: inline-flex;">
                    <img src="/images/ENTREGA.png" style="height: 35px;padding-right: 10px;" />
                    <span style="padding-right: 5px;font-size: 18px;color:#333333;padding-top: 5px;">Calcule Frete</span>
                </div> 
                </v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" v-if="Estados.length > 1">
                                        <v-select v-model="selectedEstados" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(selectedEstados)"
                                            item-title="cetNome" item-value="cetId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select-wrapper>
                                            <small>Selecione Cidade</small>
                                            <select class="styled-select" v-model="selectedCidades" @change="CarregaBairros(selectedCidades)" style="cursor: pointer;">
                                                 <option v-for="item in Cidades" :key="item.cidNome" :value="item.cidId">{{ item.cidNome }}</option>
                                            </select>
                                            <span class="caret"></span>
                                        </v-select-wrapper>
                                    </v-col>
                                    <v-col cols="12" md="6" >
                                        <v-select-wrapper v-if="Bairros!='' && Bairros!=null">
                                            <small>Selecione Bairro</small>
                                            <select class="styled-select" v-model="selectedBairros" @change="BuscaValor(2)" style="cursor: pointer;">
                                                 <option v-for="item in Bairros" :key="item.baiNome" :value="item.baiId">{{ item.baiNome }}</option>
                                            </select>
                                            <span class="caret"></span>
                                        </v-select-wrapper>
                                    </v-col>
                                    <v-col>
                                         <span v-for="val in ValorFrete" :key="val.eftId" class="fonttext">
                                           {{ TextoFrete }} {{ val.eftTipoEntrega }} ( R$ {{  formatarValor(val.eftValorFrete) }} )
                                        </span><br>
                                        <span class="fonttext" v-if="mostraCidBai">
                                           - Sem hora marcada: Frete Grátis (Carro)
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                       <p style="text-align: justify;" class="fonttext">Para garantir maior qualidade nossas entregas são feitas de carro e entregues em mãos. Não embutimos o valor do frete 
                                          no valor dos proutos. É possível retirar na loja para não ter custo do frete.</p>
                                    </v-col>
                                    <v-col style="text-align: center;">
                                        <button class="btn btn-success" @click.prevent="FinalizarCalculoFrete()" style="min-width: 70px !important">Concluir</button>
                                    </v-col>
                                </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';



export default {
    name: 'CestaDetalheView',
    setup() {
        const route = useRoute();
        const id = route.params.idProduto;
        const nomeProduto = route.params.nomeProduto;
        return {
            id
        };
    },
    components: {
    },
    data: () => ({
        Produto: [],
        ProdutoImg:[],
        infocall:[],
        valorTotal:0,
        vProdutosExtras:[],
        ProdutoAvulsos:[],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        ProdutoPreco:[],
        dialog: false,
        artes:[],
        artesGeral:[],
        ArteSelected: '',
        ArteSelectedImg: '',
        controleindex:'',
        ListaArtesCateg:[],
        CategArteSelected:'',
        textovalor:'Valor Produto ',
        dialogfrete:false,
        Estados: [],
        selectedEstados:null,
        Cidades: [],
        selectedCidades:null,
        Bairros: [],
        selectedBairros:null,
        ValorFrete:[],
        TextoFrete:'',
        mostraCidBai:false,
        
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Detalhe Cesta - Cestas Porto Alegre'
            })
        },
        FiltraCategArtes(){
          this.artes = this.artesGeral.filter(x=> x.insInstitucionalSegmentos.some(w=> w.insSegmentos.sgiId == this.CategArteSelected))
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        calculatotal(value){
            var valorAvulsos = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != ''){
                    
                    dadosArmazenados.avulsos.forEach(item => {
                        valorAvulsos += item.praValorPor;
                        });
                }
            }
          this.valorTotal = value.proProdutosPrecos[0].ppcValorPor + valorAvulsos;
        },
        AdicionaAvulso(produtoAvulsoAdicionado){
           let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.avulsos.push(produtoAvulsoAdicionado.proProdutosAvulso);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.vProdutosExtras.push(produtoAvulsoAdicionado.proProdutosAvulso)

                if (produtoAvulsoAdicionado.proProdutosAvulso.praAceitaImg){
                    const indexNovoProduto = this.vProdutosExtras.length - 1; // Obtém o índice do novo produto adicionado
                     this.openModal(indexNovoProduto);
                }
                this.calculatotal(this.Produto)
                this.$mensagemAviso("Produto Adicionado.")
            }
        },
        ExcluiAvulso(index) {
        this.vProdutosExtras.splice(index, 1);
        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
       
        if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            dadosArmazenados.avulsos.splice(index, 1);
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
            this.calculatotal(this.Produto);
        }
       },
       AdicionaCarrinho(){
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

               let result = dadosArmazenados.avulsos.filter(x=> x.praAceitaImg && x.praCampoExtra2 == '').length;

               if (result > 0){
                 this.$mensagemAviso("Escolha Arte produto.")
               }
               else{
                dadosArmazenados.produtos.push(this.Produto);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.$router.push('/carrinho')
               }
            }
       },
       GravarArtes() {
        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        dadosArmazenados = JSON.parse(dadosArmazenados);
           console.log('dadosArmazenados.avulsos')
           console.log(dadosArmazenados.avulsos)
            if (this.ArteSelected == '' && dadosArmazenados.avulsos[this.controleindex].praCampoExtra2 == ''){
                this.$mensagemAviso("Selecione uma arte.")
            }
            else{      
                if (this.ArteSelected!=''){
                    dadosArmazenados.avulsos[this.controleindex].praCampoExtra2 = this.ArteSelected.insInstitucionalImagens[0].itgUrlImagem;
                    this.vProdutosExtras = dadosArmazenados.avulsos;
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                }       
                this.controleindex = '';
                    this.dialog=false;
               
            }
       },
       openModal(value){
        this.controleindex = value;
        this.dialog = true;
    },
    openModal2(){
        this.dialogfrete = true;
    },
    toggle(mes) {
            this.ArteSelected = mes
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                    const nomeA = a.cetNome || ""; 
                    const nomeB = b.cetNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    });
    
                    if (this.Estados.length == 1) {
                        this.selectedEstados = this.Estados[0].cetId;
                        this.CarregaCidades(this.selectedEstados)
                    }
                // console.log('this.Estados')
               // console.log(this.Estados)

                });
        },
        CarregaCidades(value) {
            this.ValorFrete = [];
            this.TextoFrete ="";
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                });
        },
        CarregaBairros(value) {
            this.ValorFrete = [];
            this.TextoFrete ="";
            this.selectedBairros = null;
            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                    const nomeA = a.baiNome || ""; 
                    const nomeB = b.baiNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    }); 
                    
                    if (this.Bairros.length == 0){
                      this.BuscaValor(1);
                    }
                });
        },
        FinalizarCalculoFrete(){
            let _agenda = {
                    Estado: this.selectedEstados,
                    Cidade: this.selectedCidades,
                    Bairro: this.selectedBairros,
                }
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.agenda = [];
                    dadosArmazenados.agenda.push(_agenda);
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                }
                this.dialogfrete = false;
        },
        BuscaValor(value){
            this.ValorFrete = [];
            this.TextoFrete ="";
            if (value == 1){
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades);
                this.TextoFrete = "- Com hora marcada: "
            }
            else{
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades && x.baiId == this.selectedBairros);
                this.TextoFrete = "- Com hora marcada: "
            }
            if (this.selectedBairros == 42){
                this.mostraCidBai = true;
            }
            else{
                this.mostraCidBai = false;
            }
            
        },
    },
    created() {
        this.carregaMetas();

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);

            if (dadosArmazenados.avulsos!=''){
                this.vProdutosExtras = dadosArmazenados.avulsos;
            }
        }

        axios.get('/Process/InsInstitucional/institucional/19/callaction')
            .then(response => {
                this.infocall = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        if (this.id != null && this.id != '') {
            axios.get('/Process/ProProdutos/GetById/' + this.id)
                .then(response => {
                    this.Produto = response.data;
                    this.calculatotal(this.Produto);
                    if (this.Produto.proProdutosImagens!=''){
                        this.ProdutoImg = this.Produto.proProdutosImagens.filter(x=> x.pimVisivel).sort((a, b) => {
                                                                            const valorA = parseInt(a.pimOrdem) || 0;
                                                                            const valorB = parseInt(b.pimOrdem) || 0;
                                                                            return valorA - valorB;
                                                                            });
                    }
                    this.Produto.proProdutosGrids.sort((a, b) => {
                                                                const valorA = parseInt(a.pgsOrdem) || 0;
                                                                const valorB = parseInt(b.pgsOrdem) || 0;
                                                                return valorA - valorB;
                                                                })

                   console.log('this.produto')
                   console.log(this.Produto)
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/ProAvulsosporProdutos/GetAllByProId/' + this.id)
                .then(response => {
                    this.ProdutoAvulsos = response.data.filter(x=> x.proProdutosAvulso!=null && x.proProdutosAvulso.proProdutosAvulsosImagens!=null);
                    this.ProdutoAvulsos = this.ProdutoAvulsos.sort((a, b) => {
                                                                const valorA = parseInt(a.proProdutosAvulso.praOrdem) || 0;
                                                                const valorB = parseInt(b.proProdutosAvulso.praOrdem) || 0;
                                                                return valorA - valorB;
                                                                })
                    
                })
                .catch(error => {
                    console.error(error);
                });


                axios.get('/Process/ProProdutosPrecos/GetAllByProId/' + this.id)
                .then(response => {
                    this.ProdutoPreco = response.data;
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsInstitucional/institucional/29/cadastroartes')
                .then(response => {
                    this.artesGeral = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
                    this.artes =this.artesGeral;
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsSegmentos/GetAll')
                .then(response => {
                    this.ListaArtesCateg = response.data.sort((a, b) => a.itiNome - b.itiNome);

                })
                .catch(error => {
                    console.error(error);
                });

             axios.get(`/Process/EntEntregaFrete/GetAll`)
                    .then(response => {
                        this.EntregaFrete = response.data;
                        console.log('EntregaFrete')
                     console.log(this.EntregaFrete)
                    });


           this.CarregaEstados(76);
        }
    },
}
</script>

<style>

@media (min-width:1200px) {

    .fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}

@media (min-width:768px) {
    .altavul {
    padding-top: 35px;
}

.fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}


@media (max-width:767px) {

    .fonttext{
        font-size: 12px;
    }

    .altcel {
    min-height: 300px !important;
    height: auto !important;
}
.altavul {
    padding-top: 5px;
}

.text-h5{
    font-size: 15px !important;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    padding: 0 !important;
     font-size: 12px;
}

/* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.v-toolbar-title__placeholder {
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-toolbar-title {
    flex: auto !important;
    font-size: 1.25rem;
    min-width: 0;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    text-transform: none;
}

}



.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}

.border-green {
    border: 4px solid green !important; /* Defina a cor e largura da borda */
    border-radius: 8px; /* Adicione um raio de borda para suavizar a aparência */
}

.check-icon {
    position: absolute;
    bottom: 5px; /* Ajuste conforme necessário */
    right: 5px; /* Ajuste conforme necessário */
    color: green;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); /* Adiciona sombra para destaque */
}

.v-card {
    position: relative; /* Isso garante que o ícone absoluto esteja posicionado em relação a este cartão */
}

    .v-dialog > .v-overlay__content {
        max-height: calc(100% - 128px);
        width: calc(100% - 48px);
        max-width: calc(100% - 6px) !important;
        margin: 24px;
        display: flex;
        flex-direction: column;
    }


    /* Estilos para o wrapper do select */
.v-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}



/* Estilos para o ícone de dropdown */
.caret {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  pointer-events: none;
}


</style>
