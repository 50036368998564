<template>
  <div>
    <main class="main">
      <section class="main-carrinho" :style="{backgroundImage:'url(' +  $imgURL + logintopo.pfcUrlImgFundo + ')',backgroundSize: 'cover',minHeight: '500px'}" v-if="logintopo!=''">
        <section class="">
          <div class="container" style="padding-bottom: 60px;">
              <div class="row">
                <div class="col-lg-12" style="padding-top: 60px;">
                    <span style="color:#ffffff;font-size: 30px;">Seu pagamento foi aprovado!</span><br>
                    <span style="color:#ffffff;font-size: 20px;">Você receberá o status de entrega da cesta, via e-mail.</span><br><br>
                    <span style="color:#ffffff;font-size: 20px;">Att,</span><br>
                    <span style="color:#ffffff;font-size: 20px;font-weight: 500;">Equipe Cestas Porto Alegre,</span>
                 </div>
                </div>
              </div>
        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';

export default {
  name: 'SuccessView',
  components: {
  },
  data: () => ({
    logintopo: [],
  }),
  methods: {
  },
  async created() {
    await axios.get('/Process/ConConfigFormasPagamento/GetById/4')
        .then(response => {
          this.logintopo = response.data;
          
        })
        .catch(error => {
          console.error(error);
        });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color:#ffffff !important;
  font-size: 20px;
}
</style>
