<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-minha_conta">
          <div class="container">
              <div class="row">
                  <!-- Sidenav -->
                  <div class="col-lg-2">
                      <ul class="lista-sidebar_links">
                          <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                          <li><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                      </ul>
                  </div>
                  <!-- Main -->
                  <div class="col-lg-10" v-if="PedidosCli!=''">
                      <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                      <h6 class="subtitulo h5 mb-4">Meu Pedido</h6>
                      <!-- Lista de Pedidos -->
                      <div class="row">
                          <div class="col-lg-12">
                              <ul class="lista-pedidos border-top py-3">
                                  <li>
                                      <div class="row">
                                          <div class="col-6 col-md-3 col-lg-4 mb-4 mb-md-0">
                                              <div class="pedido_id">
                                                  <i class="fas fa-clipboard-list text-success fa-2x"></i>
                                                  <div>
                                                      <h6 class="mb-0">Pedido: {{ PedidosCli[0].pedId }}</h6>
                                                      <div class="pedido_dados" v-if="PedidosCli[0].pedFormaPgto == 'credit_card'">Pgto: Cartão Crédito</div>
                                                      <div class="pedido_dados" v-else-if="PedidosCli[0].pedFormaPgto == 'bank_transfer'">Pgto: PIX</div>
                                                      <div class="pedido_dados" v-else>Pgto: {{ PedidosCli[0].pedFormaPgto }}</div>
                                                      <strong>Situação:</strong> {{ PedidosCli[0].pedSituacaoPedido }}
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-6 col-md-2 col-lg-2 mb-4 mb-md-0 d-flex align-items-center">
                                              <div>
                                                  <div class="pedido_dados">R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.',',') }}</div>
                                              </div>
                                          </div>
                                          <div class="col-6 col-md-2 col-lg-4 mb-4 mb-md-0 d-flex align-items-center" v-if="PedidosCli[0].pedCodigoRetornoPgto == 3 && PedidoComprovante==''">
                                            <v-file-input v-model="selectedFile" label="Enviar Comprovante" variant="underlined"
                                                    prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif, image/tiff, application/pdf "></v-file-input>
                                          </div>
                                          <div class="col-6 col-md-2 col-lg-2 mb-4 mb-md-0 d-flex align-items-center" v-if="PedidosCli[0].pedCodigoRetornoPgto == 3 && PedidoComprovante==''">
                                            <button type="button" class="btn btn-success" @click="AddImagens()">
                                                    Enviar
                                            </button>
                                          </div>
                                          <div class="col-6 col-md-2 col-lg-4 mb-4 mb-md-0 d-flex align-items-center" v-if="PedidoComprovante!=''">
                                                <a :href="$imgURL + PedidoComprovante[0].ppcUrlImagem" target="blank"><i class="fa fa-file" style="font-size: 20px;"></i> Ver Comprovante</a>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <!-- Status -->
                      <div class="row">
                          <div class="col-lg-12">
                              <div class="status border-top py-3 px-xl-5">
                                  <div class="row" v-if="PedidosCli[0].pspId != 6">
                                      <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                          <i v-if="PedidosCli[0].pspId >= 1" class="fa fa-thumbs-up text-success fa-2x"></i>
                                          <i v-else-if="PedidosCli[0].pspId <= 1" class="fa fa-thumbs-up text-secondary2 fa-2x"></i>
                                          <p v-if="PedidosCli[0].pspId >= 1" class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;color:#333">Pedido Realizado</p>
                                          <p v-else-if="PedidosCli[0].pspId <= 1" class="titulo-status text-secondary2 mb-0 mt-2" style="font-size: 13px;color:#333">Pedido Realizado</p>
                                          
                                      </div>
                                      <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                          <i v-if="PedidosCli[0].pspId >= 2" class="fa fa-money text-success fa-2x"></i>
                                          <i v-else-if="PedidosCli[0].pspId <= 2" class="fa fa-money text-secondary2 fa-2x"></i>
                                          <p v-if="PedidosCli[0].pspId >= 2" class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">Aprovado</p>
                                          <p v-else-if="PedidosCli[0].pspId <= 2" class="titulo-status text-secondary2 mb-0 mt-2" style="font-size: 13px;">Aprovado</p>
                                      </div>
                                      <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                          <i v-if="PedidosCli[0].pspId >= 3" class="fa fa-calendar text-success fa-2x"></i>
                                          <i v-else-if="PedidosCli[0].pspId <= 3" class="fa fa-calendar text-secondary2 fa-2x"></i>
                                          <p v-if="PedidosCli[0].pspId >= 3" class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">Preparação</p>
                                          <p v-else-if="PedidosCli[0].pspId <= 3" class="titulo-status text-secondary2 mb-0 mt-2" style="font-size: 13px;">Preparação</p>
                                      </div>
                                      <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                          <i v-if="PedidosCli[0].pspId >= 4" class="fa fa-space-shuttle text-success fa-2x"></i>
                                          <i v-else-if="PedidosCli[0].pspId <= 4" class="fa fa-space-shuttle text-secondary2 fa-2x"></i>
                                          <p v-if="PedidosCli[0].pspId >= 4" class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">Enviado</p>
                                          <p v-else-if="PedidosCli[0].pspId <= 4" class="titulo-status text-secondary2 mb-0 mt-2" style="font-size: 13px;">Enviado</p>
                                      </div>
                                      <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                          <i v-if="PedidosCli[0].pspId >= 5" class="fa fa-check text-success fa-2x"></i>
                                          <i v-else-if="PedidosCli[0].pspId <= 5" class="fa fa-check text-secondary2 fa-2x"></i>
                                          <p v-if="PedidosCli[0].pspId >= 5" class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">Entregue</p>
                                          <p v-else-if="PedidosCli[0].pspId <= 5" class="titulo-status text-secondary2 mb-0 mt-2" style="font-size: 13px;">Entregue</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                            <div v-for="produto in ListaPedidoItem" :key="produto.pdiId" class="descricao-pedido border-top py-3">
                              <div class="row">
                                  <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                      <div class="descricao-img">
                                          <img v-if="produto.proProdutos.proCampoExtra10 != ''" class="img-fluid border border-rounded mb-3 mb-md-0"
                                              :src="$imgURL + produto.proProdutos.proCampoExtra10" alt="">
                                          <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                              :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-2 d-flex align-items-center">
                                      <div class="descricao-content">
                                          <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                          <p class="texto-mensagem"> Código: {{ produto.proId }}</p>
                                          <p class="texto-mensagem"> Referência: {{ produto.proProdutos.proReferencia }} </p>
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-3 d-flex align-items-center">
                                      <div class="descricao-content">
                                          <p class="texto-mensagem"></p>
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-1 d-flex align-items-center">
                                      <div class="descricao-content">
                                          <h6 class="subtitulo">{{ produto.pdiQtdeProduto }}</h6>
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-2 d-flex align-items-center">
                                      <div class="descricao-content">
                                          <h6 class="subtitulo">R$ {{  parseFloat(produto.pdiValorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-2 d-flex align-items-center">
                                      <div class="descricao-content">
                                          <h6 class="subtitulo">R$ {{ parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div>
                      </div>
                        <div v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId" class="descricao-pedido border-top py-3">
                          <div class="row">
                              <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                  <div class="descricao-img">
                                      <img v-if="adicional.proProdutosAvulsos.proProdutosAvulsosImagens != null" class="img-fluid border border-rounded mb-3 mb-md-0" style="width:80%;"
                                          :src="$imgURL + adicional.proProdutosAvulsos.proProdutosAvulsosImagens[0].paiUrlImagem" alt="">
                                      <img v-else class="img-fluid border border-rounded mb-3 mb-md-0" style="width:70%;"
                                          :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                  </div>
                              </div>
                              <div class="col-6 col-md-2 d-flex align-items-center">
                                  <div class="descricao-content">
                                      <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                      <p class="texto-mensagem"> Código: {{ adicional.proProdutosAvulsos.praReferencia}}</p>
                                  </div>
                              </div>
                              <div class="col-6 col-md-3 d-flex align-items-center">
                                  <div class="descricao-content">
                                      <p class="texto-mensagem"></p>
                                  </div>
                              </div>
                              <div class="col-6 col-md-1 d-flex align-items-center">
                                  <div class="descricao-content">
                                      <h6 class="subtitulo">{{ adicional.piaQtdeProduto }}</h6>
                                  </div>
                              </div>
                              <div class="col-6 col-md-2 d-flex align-items-center">
                                  <div class="descricao-content">
                                      <h6 class="subtitulo">R$ {{  parseFloat(adicional.piaValorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                  </div>
                              </div>
                              <div class="col-6 col-md-2 d-flex align-items-center">
                                  <div class="descricao-content">
                                      <h6 class="subtitulo">R$ {{ parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                        <div v-if="PedidosMensagem!=''" >
                          <div v-for="pedidomensagem in PedidosMensagem" :key="pedidomensagem.pmsId" class="row">
                              <div v-if="pedidomensagem.pmsTexto != ''" class="col">
                                  <div class="descricao-pedido border-top py-3">
                                      <div class="row">
                                          <div class="col-4 col-md-6">
                                              <div class="descricao-img" style="padding: 20px;border: 1px solid #cccccc;height:150px;overflow-y: auto;">
                                                <p v-html="pedidomensagem.pmsTexto"></p>
                                              </div>
                                          </div>
                                          <div class="col-6 col-md-3 align-items-center">
                                              <div class="descricao-content">
                                                <small>Quem envia</small><br>
                                                {{pedidomensagem.pmsNomeDe}}
                                              </div>
                                          </div>
                                          <div class="col-6 col-md-3 align-items-center">
                                              <div class="descricao-content">
                                                <small>Quem Recebe</small><br>
                                                {{pedidomensagem.pmsNomePara}}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row" v-if="PedidosAgendamento!='' && PedidosEndereco !='' && Cidades!='' && Estados!='' && Bairros!=''">
                          <div class="col-lg-12">
                              <div class="border-top py-3">
                                  <div class="row">
                                      <div class="col-lg-7 mb-4 mb-lg-0">
                                          <div>
                                              <h5 class="subtitulo">Entrega</h5>
                                              <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Dia:</strong> {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{ validaHora(PedidosAgendamento[0].pagMinIni) }} e {{ validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{ validaHora(PedidosAgendamento[0].pagMinFim) }}</p>
                                              <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Endereço:</strong> {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{ PedidosEndereco[0].pdcComplemento }} </p>
                                              <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Cidade/UF:</strong> {{ Cidades.filter(x=> x.cidId == PedidosEndereco[0].cidId)[0].cidNome }} / {{ Estados.filter(x=> x.cetId == PedidosEndereco[0].cetId)[0].cetSigla}}</p>
                                              <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x=> x.baiId == PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro  }} </p>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <div class="d-flex align-items-center justify-content-between">
                                              <h5 class="subtitulo">Cupom Desconto</h5>
                                              <h6 class="subtitulo">(-) R$ {{ parseFloat(PedidosCli[0].pedValorCupom).toFixed(2).toString().replace('.',',') }}</h6>
                                          </div>
                                          <div class="d-flex align-items-center justify-content-between">
                                              <h5 class="subtitulo">Frete</h5>
                                              <h6 class="subtitulo">R$ {{ parseFloat(PedidosCli[0].pedValorFrete).toFixed(2).toString().replace('.',',') }}</h6>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <div class="border-top py-3">
                                  <div class="row">
                                      <div class="col-lg-2 offset-lg-7">
                                          <div class="d-flex align-items-center justify-content-between">
                                              <h5 class="subtitulo">Total</h5>
                                          </div>
                                      </div>
                                      <div class="col-lg-2 offset-lg-1">
                                          <div class="d-flex align-items-center justify-content-between">
                                              <h5 class="subtitulo">R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.',',') }}</h5>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col text-center">
                                          <router-link to="/meusPedidos" class="btn btn-success mt-5">Ver histórico de pedidos</router-link>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section style="background-color:#B91F1C;height:20px;">
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';
import { useRoute } from 'vue-router';

export default {
  name: 'MeusPedidosDetalheView',
  setup() {
    const route = useRoute();
    const id = route.params.idPedido;
    return {
      id
    };
  },
  components: {
    CheckAcesso,
  },
  data: () => ({
    PedidosCli: [],
    TotalPedido:0,
    validacomprova: [],
    PedidosComprova: [],
    showModal_comprova:false,
    ListaPedidoItem:[],
    ListaPedidoItemAdicional:[],
    PedidosMensagem:[],
    PedidosAgendamento:[],
    PedidosEndereco:[],
    Estados:[],
    Cidades:[],
    Bairros:[],
    selectedFile:'',
    urlComprovante: '',
    PedidoComprovante:[],
  }),
  methods: {
    validaHora(value) {
        var _hora = value.toString();
        if (_hora.length == 1)
            _hora = "0" + _hora;
        return _hora;
    },
    formatarValor(valor) {
        if (valor!='' && valor!=null){
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        }
        else{
            valor = 0;
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        }
            
    },
    formattedDate(value) {
        if (value!=null || value != ""){
            return this.$moment(value).format('DD/MM/YYYY')
        }
        else{
            return "";
        }
        
    },
    calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
          

                if (this.ListaPedidoItem != '') {
                    this.ListaPedidoItem.forEach(item => {
                      valorProdutos += item.pdiValorTotalProduto;
                    });
                }

                if (this.ListaPedidoItemAdicional != '') {
                    this.ListaPedidoItemAdicional.forEach(item => {
                      valorAvulsos += item.piaValorTotalProduto;
                    });
                }

            this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.PedidosCli[0].pedValorFrete)) - parseFloat(this.PedidosCli[0].pedValorCupom);
    },
    async UploadImg(value) {
        const formData = new FormData();
        formData.append('file', value);
        const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
        return uploadResult;
    },
    AddImagens(){
        if (this.selectedFile != '' && this.selectedFile != null) {
        this.selectedFile.map(async (element) => {
            const uploadSuccess = await this.UploadImg(element);
            if (uploadSuccess.status == 200) {
                this.urlComprovante = uploadSuccess.data.nameFile;

                await axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                    .then(response => {
                        this.PedidoComprovante = response.data;
                       // console.log('this.PedidoComprovante')
                       // console.log(this.PedidoComprovante)
                        if (this.PedidoComprovante!=""){                             
                            let _pedidoComprovante = {
                                    PpcId: this.PedidoComprovante[0].ppcId,
                                    GrtId: this.PedidoComprovante[0].grtId,
                                    CdtId: this.PedidoComprovante[0].cdtId,
                                    IdiId: 1,
                                    PpcUrlImagem: this.urlComprovante,
                                    PedId:this.PedidoComprovante[0].pedId,
                                }

                            axios.put(`/Process/PedPedidoPgtoComprova/Update`, _pedidoComprovante)
                                .then(response => {
                                    if (response.status == 200) {
                                        this.$mensagemAvisoSucesso("Comprovante enviado.")
                                    }
                                });

                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
              
            } else {
                this.$mensagemErro("Não foi possível carregar a imagem.");
                return null;
            }
          });
    } else{
      this.$mensagemAviso("Selecione o comprovante para enviar.");
    }
    },
  },
  async created() {

      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData) {
        const _dadosRecuperados = JSON.parse(cookieData);

        await axios.get('/Process/PedPedidos/GetAllByCliId/' + _dadosRecuperados.usuarioId)
          .then(response => {
            this.PedidosCli = response.data.filter(x=> x.pedId == this.id);
           // console.log('PedidosCli')
           // console.log(this.PedidosCli)
          })
          .catch(error => {
            console.error(error);
          });
         

         await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
          .then(response => {
            this.ListaPedidoItem = response.data;
           // console.log('ListaPedidoItem')
           // console.log(this.ListaPedidoItem)
          })
          .catch(error => {
            console.error(error);
          });

         await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
          .then(response => {
            this.ListaPedidoItemAdicional = response.data;
           // console.log('ListaPedidoItemAdicional')
           // console.log(this.ListaPedidoItemAdicional)
          })
          .catch(error => {
            console.error(error);
          });

          axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.id)
          .then(response => {
            this.PedidosMensagem = response.data;
           // console.log('PedidosMensagem')
           // console.log(this.PedidosMensagem)
          })
          .catch(error => {
            console.error(error);
          });

          axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
          .then(response => {
            this.PedidosAgendamento = response.data;
           // console.log('PedidosAgendamento')
           // console.log(this.PedidosAgendamento)
          })
          .catch(error => {
            console.error(error);
          });

          axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
          .then(response => {
            this.PedidosEndereco = response.data;
           // console.log('PedidosEndereco')
          //  console.log(this.PedidosEndereco)
          })
          .catch(error => {
            console.error(error);
          });

          axios.get(`/Process/CadEstados/GetByPaisId/76`)
                .then(response => {
                    this.Estados = response.data
                   // console.log('this.Estados')
                  //  console.log(this.Estados)
                });

          axios.get(`/Process/CadCidades/GetAll`)
          .then(response => {
              this.Cidades = response.data
             // console.log('this.Cidades')
             // console.log(this.Cidades)
          });

          axios.get(`/Process/CadBairros/GetAll`)
                .then(response => {
                    this.Bairros = response.data
                  //  console.log('this.Bairros')
                  //  console.log(this.Bairros)
                });

        axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
        .then(response => {
            this.PedidoComprovante = response.data.filter(x=> x.ppcUrlImagem != '')
          //  console.log('this.PedidoComprovante')
          //  console.log(this.PedidoComprovante)
        });

          this.calculatotal();
      }
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color:#ffffff !important;
  font-size: 20px;
}

.text-secondary2 {
    color: #5b5b5b !important;
}
</style>
