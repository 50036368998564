<template>
  <div>
    <main class="main">
      <div v-if="informacoestopo != '' && informacoestopo[0].insInstitucionalImagens != ''" class="page-header text-center"
        :style="{ backgroundImage: 'url(' + $imgURL + informacoestopo[0].insInstitucionalImagens[0].itgUrlImagem + ')' }">
        <div class="container">
          <h1 class="page-title">{{ informacoestopo[0].itiNome }}</h1>
        </div><!-- End .container -->
      </div><!-- End .page-header -->
      <nav aria-label="breadcrumb" class="breadcrumb-nav">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Informações Gerais</li>
          </ol>
        </div><!-- End .container -->
      </nav><!-- End .breadcrumb-nav -->
    </main>
    <div class="page-content">
      <div class="container">
        <div class="row">
          <aside class="col-lg-3">
            <div class="sidebar">
              <div class="widget widget-cats">
                <h3 class="widget-title" style="text-decoration:underline;">Menu Informações</h3>
                <!-- End .widget-title -->
                <ul v-if="informacoes!=''">
                  <li style="padding-top: 5px;padding-bottom: 5px;" v-for="itens in informacoes" :key="itens.itiId">
                    <a href="#" @click.prevent="carregaTexto(itens.itiId)"> {{itens.itiNome}}</a>
                  </li>
                </ul>
              </div><!-- End .widget -->
            </div><!-- End .sidebar sidebar-shop -->
          </aside><!-- End .col-lg-3 -->
          <div class="col-lg-9" v-if="informacao!=''">
            <article class="entry single-entry">
              <div class="entry-body">
                <h2 class="entry-title pb-3">
                  {{informacao[0].itiNome}}
                </h2><!-- End .entry-title -->

                <div class="entry-content editor-content" v-if="informacao[0].itiReferencia!='' && informacao[0].itiReferencia!=null">
                  <iframe width="100%" height="350" :src="informacao[0].itiReferencia" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div><!-- End .entry-content -->

                <div class="entry-content editor-content">
                  <p v-html="informacao[0].itiResumo1"></p>
                </div><!-- End .entry-content -->

              </div><!-- End .entry-body -->
            </article><!-- End .entry -->
          </div><!-- End .col-lg-9 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
      <div v-if="infocall!=''" class="cta bg-image bg-dark" :style="{backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom:'80px'}">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-6">
              <div class="cta-text text-center">
                <h3 class="cta-title text-white">{{infocall[0].itiNome}}</h3><!-- End .cta-title -->
                <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1" style="color: #ccc !important;"></p><!-- End .cta-desc -->

                <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded" target="_blank"><span>{{infocall[0].itiTextoBtn1}}</span><i
                    class="icon-long-arrow-right"></i></a>
                    <a v-if="infocall[0].itiReferencia!='' && infocall[0].itiReferencia!=null" :href="infocall[0].itiReferencia" class="btn btn-warning btn-rounded" style="margin: 15px;"><span>{{infocall[0].itiCampoExtra1}}</span><i
                                class="icon-long-arrow-right"></i></a>
              </div><!-- End .cta-text -->
            </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
          </div><!-- End .row -->
        </div><!-- End .container -->
      </div><!-- End .cta -->
    </div><!-- End .page-content -->
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'InformacoesView',
  setup() {
    const route = useRoute();
    const id = route.params.idInformacoes;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    informacoestopo: [],
    informacoes: [],
    informacao:[],
    infocall: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Informações - Cestas Porto Alegre'
      })
    },
    carregaTexto(value) {
      this.informacao = this.informacoes.filter(x => x.itiId == value)
    },
  },
  created() {

    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/17/informacoesgeraistopo')
      .then(response => {
        this.informacoestopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
      .then(response => {
        this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);

        if (this.id) {
          this.carregaTexto(this.id);
        }
        else {
          this.carregaTexto(this.informacoes[0].itiId);
        }
      })
      .catch(error => {
        console.error(error);
      });

       axios.get('/Process/InsInstitucional/institucional/19/callaction')
      .then(response => {
        this.infocall = response.data;
      })
      .catch(error => {
        console.error(error);
      });

  },
}
</script>

<style>.hhss {
  display: none;
}

.sli p {
  color: #ede3e3 !important;
}</style>
