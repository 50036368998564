<template>
  <div>
    <footer class="footer">
            <div class="footer-middle">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3">
                            <div class="widget widget-about" v-if="dados!=''">
                                <img src="/images/logo-nome.png" class="footer-logo"
                                    alt="Logo" width="200" height="auto">
                                    <p v-html="dados[0].itiResumo1"></p>
                                <div class="widget-call">
                                    <i class="icon-phone"></i>
                                    Atendimento <br>
                                    {{dados[0].itiNome}} <br> <a :href="dados[0].itiCampoExtra1" target="_blank" style="color: #333;font-size: 13px;"> {{ dados[0].itiReferencia }}</a>
                                </div><!-- End .widget-call -->
                            </div><!-- End .widget about-widget -->
                        </div><!-- End .col-sm-6 col-lg-3 -->

                        <div class="col-sm-6 col-lg-3">
                            <div class="widget">
                                <h4 class="widget-title">Menu</h4><!-- End .widget-title -->

                                <ul class="widget-list">
                                    <li><router-link to="/">Inicio</router-link></li>
                                    <li><router-link to="/sobre">Sobre</router-link></li>
                                    <li><router-link to="/CestasporCategoria">Tipos de Cestas</router-link></li>
                                    <li><router-link to="/faleconosco">Fale Conosco</router-link></li>
                                </ul><!-- End .widget-list -->
                            </div><!-- End .widget -->
                        </div><!-- End .col-sm-6 col-lg-3 -->

                        <div class="col-sm-6 col-lg-3">
                            <div class="widget">
                                <h4 class="widget-title">Informações</h4><!-- End .widget-title -->

                                <ul class="widget-list">
                                    <li v-for="itens in informacoes" :key="itens.itiId">
                                       <router-link :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ','-').replaceAll('/','').replaceAll(',','')">
                                          {{itens.itiNome}}
                                      </router-link></li>
                                </ul><!-- End .widget-list -->
                            </div><!-- End .widget -->
                        </div><!-- End .col-sm-6 col-lg-3 -->

                        <div class="col-sm-6 col-lg-3">
                            <div class="widget">
                                <h4 class="widget-title">Area do Cliente</h4><!-- End .widget-title -->

                                <ul class="widget-list">
                                    <li><router-link to="/login">Login</router-link></li>
                                    <li><router-link to="/carrinho">Carrinho</router-link></li>
                                    <li><router-link to="/meuspedidos">Meus Pedidos</router-link></li>
                                    <li><router-link to="/meusdados">Minha Conta</router-link></li>
                                </ul><!-- End .widget-list -->
                            </div><!-- End .widget -->
                        </div><!-- End .col-sm-6 col-lg-3 -->
                    </div><!-- End .row -->
                </div><!-- End .container -->
            </div><!-- End .footer-middle -->

            <div class="footer-bottom">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4">
                       <a href="https://avancedigital.com.br/" target="_blank">
                        <img src="/images/logo_avance.png" alt="">
                      </a>
                    </div>
                    <div class="col-sm-4 col-lg-4 text-center">
                        <div class="social-icons d-flex justify-content-center" v-if="redes!=''">
                            <a v-if="redes[0].itiNome!='' && redes[0].itiNome!=null" :href="redes[0].itiNome" class="social-icon" target="_blank" title="Facebook"><i class="icon-facebook-f"></i></a>
                            <a v-if="redes[0].itiReferencia!='' && redes[0].itiReferencia!=null" :href="redes[0].itiReferencia" class="social-icon" target="_blank" title="Instagram"><i class="icon-instagram"></i></a>
                            <a v-if="redes[0].itiCampoExtra1!='' && redes[0].itiCampoExtra1!=null" :href="redes[0].itiCampoExtra1" class="social-icon" target="_blank" title="Youtube"><i class="icon-youtube"></i></a>
                            <a v-if="redes[0].itiCampoExtra2!='' && redes[0].itiCampoExtra2!=null" :href="redes[0].itiCampoExtra2" class="social-icon" target="_blank" title="Linkedin"><i class="icon-linkedin"></i></a>
                        </div><!-- End .social-icons -->
                    </div>
                    <div class="col-sm-4 col-lg-4">
                    </div>
                  </div>
                    <!-- End .footer-copyright -->
                   
                </div><!-- End .container -->
            </div><!-- End .footer-bottom -->
    </footer><!-- End .footer -->
    <div v-if="dados!=''" class="whatsapp-fixo">
        <a :href="dados[0].itiCampoExtra1" target="_blank">
            <img src="/images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
        </a>
       </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    redes:[],
    informacoes:[],
    callaction:[],
    LinkWhats:[],
  }),
  methods: {

  },
  created() {  
    axios.get('/Process/InsInstitucional/institucional/10/rodape')
         .then(response => {
         this.dados = response.data;
       })
       .catch(error => {
         console.error(error);
       });

     axios.get('/Process/InsInstitucional/institucional/20/redessociais')
         .then(response => {
         this.redes = response.data;
       })
       .catch(error => {
         console.error(error);
       });

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
         .then(response => {
         this.informacoes = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
       })
       .catch(error => {
         console.error(error);
       });
  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}

/* WHATSAPP FIXO */
.whatsapp-fixo {
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 999;
  }
</style>
