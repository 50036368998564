<template>
    <div>
        <main class="main">
            <section class="main-carrinho" style="background-color: #f7e7db;padding-bottom: 60px;">
                <section class="">
                    <div class="container">
                        <div class="row py-5">
                            <!--div-- class="col-lg-5 hidden-xs hidden-sm">
                                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                                    v-if="textoCarrinho != ''">
                                    <img v-if="textoCarrinho[0].insInstitucionalImagens != ''"
                                        :src="$imgURL + textoCarrinho[0].insInstitucionalImagens[0].itgUrlImagem" />
                                </div>
                            </!--div-->
                            <div class="col-lg-8">
                                <div class="box_content">
                                    <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center hidden-xs hidden-sm">Entrega > <span style="color:#cccccc">Login > Pagamento</span></h3>
                                    <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center hidden-lg hidden-md" style="font-size: 20px;">Entrega > <span style="color:#cccccc">Login > Pagamento</span></h3>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-checkbox v-model="retirarNaLoja" label="Retirar na loja" @change="preencherEnderecoFixo"></v-checkbox>
                                                </v-col>
                                                <v-col cols="12" md="6" v-if="Estados.length > 1">
                                                    <v-select v-model="selectedEstados" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(selectedEstados)"
                                                        item-title="cetNome" item-value="cetId" variant="outlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select v-model="selectedCidades" :disabled="retirarNaLoja" label="Cidade" :items="Cidades" @update:modelValue="CarregaBairros(selectedCidades)"
                                                        item-title="cidNome" item-value="cidId" variant="outlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="6" v-if="Bairros!='' && Bairros!=null">
                                                    <v-select v-model="selectedBairros" :disabled="retirarNaLoja" label="Bairro" :items="Bairros" @update:modelValue="AtualizaData()"
                                                        item-title="baiNome" item-value="baiId" variant="outlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="6" v-else>
                                                    <v-text-field v-model="BairroTexto" maxlength="100" autocomplete="off"
                                                        label="Bairro" variant="outlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6"> 
                                                    <v-text-field v-model="Endereco" :disabled="retirarNaLoja" maxlength="200" autocomplete="off"
                                                        label="Endereço" variant="outlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="Numero" type="number" :disabled="retirarNaLoja" autocomplete="off"
                                                        label="Número" variant="outlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="Complemento" :disabled="retirarNaLoja" maxlength="200" autocomplete="off"
                                                        label="Complemento" variant="outlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="5">
                                                    <v-text-field v-model="NomePara2" maxlength="100" autocomplete="off"
                                                                label="Quem recebe a entrega" variant="outlined" required>
                                                            <template v-slot:append>
                                                                <v-tooltip v-model="show" location="top" :open-on-click="true">
                                                                    <template v-slot:activator="{ props }">
                                                                        <v-icon color="#000000" v-bind="props" style="font-size: 20px;height: 30px;">
                                                                            mdi-help-circle
                                                                        </v-icon>
                                                                    </template>
                                                                    <span style="font-size: 12px;">Quem irá atender nosso <br> entregador e receber a <br> cesta no ato da entrega</span>
                                                                    </v-tooltip>
                                                            </template>
                                                    </v-text-field>                                                   
                                                </v-col>
                                                <!--v-col cols="12" md="7">
                                                    <strong>É quem recebe a entrega? </strong>
                                                    <v-radio-group v-model="recebeEntrega" style="display: inline-flex;flex-direction: row;">
                                                        <v-radio :label="'Sim'" value="true" style="width: 80px;"></v-radio>
                                                        <v-radio :label="'Não'" value="false" style="width: 80px;"></v-radio>
                                                    </v-radio-group>
                                                </!--v-col>
                                                <v-col-- cols="12" md="12" v-if="recebeEntrega == 'false'">
                                                    <v-text-field v-model="NomePara2" maxlength="100"
                                                        label="Nome/Setor de quem recebe a entrega da cesta"  variant="underlined" required></v-text-field>
                                                </v-col-->
                                                <v-col cols="12" md="3">
                                                    <v-select v-model="selectedPais" label="País (DDI)" :items="Paises" @update:modelValue="TelefoneRecebe=''"
                                                        item-title="cpaNome" item-value="cpaId" variant="outlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="TelefoneRecebe" autocomplete="off"
                                                     ref="whatsAppInput"
                                                        label="Telefone quem recebe"  variant="outlined" required>
                                                        <template v-slot:append>
                                                                <v-tooltip v-model="show2" location="top" class="custom-tooltip" :open-on-click="true">
                                                                    <template v-slot:activator="{ props }">
                                                                        <v-icon color="#000000" v-bind="props" style="font-size: 20px;height: 30px;">
                                                                            mdi-help-circle
                                                                        </v-icon>
                                                                    </template>
                                                                    <span style="font-size: 12px;">Usamos somente se for necessário entrar em <br> contato para realizar a entrega. Não informamos <br> na ligação quem esta enviando o presente.</span>
                                                                    </v-tooltip>
                                                            </template>
                                                        </v-text-field>
                                                        <span class="small-text">*Número com DDD (ex: 5199999999)</span>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field v-model="Observacao" maxlength="200" autocomplete="off"
                                                        label="Observações" variant="outlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6" v-if="selectedCidades != null && (selectedBairros != null || BairroTexto != '')">
                                                    <VDatePicker v-model="DataEntrega" mode="date" :attributes="attrs" class="my-calendar" @update:modelValue="CalculaFrete()" />                                                      
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                <small>Horários Disponíveis</small>
                                                    <v-radio-group v-model="selectedHorarioId" @update:model-value="updateTextosemhorario()">
                                                        <v-radio
                                                        v-for="item in ArrayEntregas"
                                                        :key="item.id"
                                                        :label="item.Horario"
                                                        :value="item"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                    <p style="text-align: justify;">{{ textosemhorario }}</p>
                                                </v-col>
                                              
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                    <!--mensagem cartao-->
                                    <h3 v-if="logintopo != ''" class="titulo-principal text-center mt-3">{{ logintopo[0].itiNome }}</h3>
                                    <v-col cols="12" md="12">
                                        <v-btn v-model="EnviaCartao" @click="EnviarCartao()" class="fontbtn" style="margin: 5px;">Sim</v-btn>
                                        <v-btn v-model="SemCartao" @click="EnviarSemCartao()" class="fontbtn" style="margin: 5px;">Não</v-btn><span class="hidden-lg hidden-md"><br></span>
                                        <v-btn v-model="CartaoBranco" @click="EnviarCartaoBranco()" class="fontbtn" style="margin: 5px;">Enviar Cartão sem nada escrito</v-btn>
                                        <v-tooltip v-model="show3" location="top" :open-on-click="true" >
                                            <template v-slot:activator="{ props }">
                                                <v-icon color="#000000" v-bind="props" style="padding-top: 10px;padding-left: 3px;">
                                                    mdi-help-circle
                                                </v-icon>
                                            </template>
                                            <span style="font-size: 12px;">Use a última opção caso <br>queira escrever a mensagem <br> a mão ao receber a entrega.</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-form v-if="EnviaCartao" class="mb-3">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6" >
                                                    <v-text-field v-model="Formulario.NomeDe" autocomplete="off" type="text" label="De:" variant="outlined" maxlength="70"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="Formulario.NomePara" autocomplete="off" type="text" label="Para:" variant="outlined" maxlength="70"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-textarea
                                                        v-model="Formulario.Mensagem"
                                                        label="Mensagem"
                                                        variant="outlined"
                                                        rows="5"
                                                        auto-grow
                                                        @focus="limparPlaceholder" @blur="restaurarPlaceholder" @input="limitarCaracteres"
                                                    ></v-textarea>
                                                  <br><small>{{ contadorCaracteres }}/500 caracteres</small>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <button class="btn btn-info" @click.prevent="dialog = true">Ver sugestões de mensagens</button>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                    <span v-else class="mb-3"> {{ TextoCartao }}</span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="posicao">
                                    <div class="box_content">
                                        <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">Resumo</h3>
                                        <div v-if="valorTotal != ''"
                                                class="d-flex align-items-center justify-content-between mb-1"
                                                style="padding-right:5px;">
                                                <h6 class="mb-0" v-if="DataEntrega !== '' && selectedHorarioId !== null && selectedHorarioId !== ''">
                                                    Entrega: {{ DataEntrega !== null ? formattedDate(DataEntrega) : '' }} -
                                                    {{ selectedHorarioId == '' ? null : (selectedHorarioId.Valor == 0 ? ( !retirarNaLoja ? selectedHorarioId.Horario.split('R$')[0].trim() + " (Sem Hora Marcada)" : selectedHorarioId.Horario.split('R$')[0].trim() + " (Retirar na Loja)") : selectedHorarioId.Horario.split('R$')[0].trim()) }}
                                                </h6>
                                                <h6 class="text-center mb-0"></h6>
                                            </div>
                                            <div v-if="valorTotal != ''"
                                                class="d-flex align-items-center justify-content-between mb-1"
                                                style="padding-right:5px;">
                                                <h6 class="mb-0">Produtos: </h6>
                                                <h6 class="text-center mb-0">R$ {{ formatarValor(valorTotal) }}</h6>

                                            </div>
                                            <div v-if="valorTotal != ''"
                                                class="d-flex align-items-center justify-content-between mb-1"
                                                style="padding-right:5px;">
                                                <h6 class="mb-0" v-if="selectedHorarioId !== null && selectedHorarioId !== ''">Entrega: </h6>
                                                <h6 class="text-center mb-0" v-if="selectedHorarioId !== null && selectedHorarioId !== ''">R$ {{ selectedHorarioId.Valor == '' ? 0: formatarValor(selectedHorarioId.Valor) }}</h6>
                                        </div>
                                        <div v-if="valorCupom != ''"
                                                class="d-flex align-items-center justify-content-between mb-1"
                                                style="padding-right:5px;">
                                                <h6 class="mb-0">Cupom: </h6>
                                                <h6 class="text-center mb-0" style="color:red;">- R$ {{ formatarValor(valorCupom) }}</h6>

                                            </div>
                                        <div class="border-bottom mb-1"></div>
                                        <div v-if="valorTotal != ''"
                                            class="d-flex align-items-center justify-content-between mb-2"
                                            style="padding-right:5px;">
                                            <h6 class="texto-destaque mb-0" style="font-size: 20px;">Total:</h6>
                                            <h6 class="texto-destaque text-center mb-0" style="font-size: 20px;">R$ {{ formatarValor((isNaN(selectedHorarioId.Valor) ? valorTotal-valorCupom : ((valorTotal + parseFloat(selectedHorarioId.Valor))-valorCupom))) }}</h6>
                                        </div>
                                    </div>
                                    <div class="box_content-footer">
                                        <div class="mt-2" style="width: 100%;" v-if="textoCarrinho != ''">
                                            <small>Ao continuar você concorda com a <router-link to="/informacoes/61/Políticas-de-Entrega" target="_blank" style="color: blue;">Política de Entregas</router-link></small>
                                            <button @click="CarregarDados()"
                                                class="btn btn-success w-100 mb-1">{{
                                                    textoCarrinho[0].itiTextoBtn1 }}</button><br>
                                            <router-link to="/carrinho"
                                                class="btn btn-outline-danger mb-3" style="width: 100%;">Voltar Carrinho</router-link>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </section>
            </section>
        </main>
        <v-row justify="center">
            <v-dialog v-model="dialog" class="controlcel">
              <v-card style="height: 600px;overflow-y: scroll;">
                <v-card-title>
                  <span class="text-h5">Sugestões de Mensagens</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                  <v-container>
                    <v-row v-for="(mens, index) in mensagens" :key="mens.itiId" :class="index % 2 === 0 ? 'white-row' : 'light-gray-row'">
                      <v-col cols="12" sm="8" md="8" >
                        <h3 style="font-size: 20px;">{{ mens.itiNome }}</h3>
                        <p v-html="mens.itiResumo1"></p>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="d-flex align-center">
                        <v-btn variant="text" @click="updateMensagem(mens.itiResumo1);dialog = false" class="btn btn-info" style="color:#ffffff;">
                          Escolher
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black" variant="text" @click="dialog = false">
                    Fechar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const date = ref(new Date());

const attrs = ref([
  {
    highlight: 'red',
    dates: new Date(),
  },
]);

</script>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import 'moment-timezone';
import libphonenumber from 'google-libphonenumber';
import Cookies from 'js-cookie';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export default {
    name: 'CarrinhoView',
    components: {
    },
    data: () => ({
        show: false,
        show2: false,
        show3:false,
        textoCarrinho: [],
        valorTotal: 0,
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        Paises: [],
        selectedPais:null,
        Estados: [],
        selectedEstados:null,
        Cidades: [],
        selectedCidades:null,
        Bairros: [],
        selectedBairros:null,
        BairroTexto:'',
        Endereco:'',
        Numero:'',
        Complemento:'',
        DataEntrega:'',
        valorFrete:0,
        NomePara2: '',
        recebeEntrega: 'true',
        //
        EntregaFrete:[],
        EntregaExcecao:[],
        EntregaExcecaoList:[],
        EntregaHorarios:[],
        ArrayEntregas:[],
        ObjEntregas:{
            Horario: '',
            Valor: '',
            Tipo:''
        },
        selectedHorarioId: [],
        valorCupom:0,
        TelefoneRecebe:'',
        Observacao:'',
        retirarNaLoja: false,
        textosemhorario:'',
        //mensagem cartao
        logintopo: [],
        Formulario: {
        NomeDe: '',
        NomePara: '',
        Mensagem: '',
        },
        AbreSenha: false,
        AbreCad: false,
        contadorCaracteres: 0,
        EnviaCartao: false,
        SemCartao: false,
        CartaoBranco:false,
        dialog: false,
        mensagens:[],
        set: 'apple',
        TextoCartao:'',
        SetaExcecaoSeg: '',
        SegmentoProd:[],
        }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Endereço Entrega - Cestas Porto Alegre'
            })
        },
        validarNumero() {
        // Remover espaços em branco e caracteres desnecessários
        let numeroTelefone = this.TelefoneRecebe.trim();

      try {
        // Parse do número de telefone
        const parsedNumber = phoneUtil.parse(numeroTelefone, 'ZZ');
        
        // Verificar se o número é válido
        if (phoneUtil.isValidNumber(parsedNumber)) {
          this.aplicarMascara(); // Formatar o número de telefone
        } else {
          this.$mensagemAviso('Número de telefone inválido');
          this.TelefoneRecebe = ''; // Limpar o campo
          this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
        }
      } catch (e) {
        console.error('Erro ao processar o número de telefone:', e);
        this.TelefoneRecebe = ''; // Limpar o campo
        this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
      }
        },
        aplicarMascara() {
            let input = this.TelefoneRecebe.replaceAll("(","").replaceAll(")","").replaceAll("-","");

            let fonePais = this.Paises.filter(x=> x.cpaId == this.selectedPais)[0];

            input =  "+" + fonePais.cpaFone + this.TelefoneRecebe;

            try {
                const parsedNumber = phoneUtil.parseAndKeepRawInput(input, 'ZZ');
                const isValid = phoneUtil.isValidNumber(parsedNumber);

                if (isValid) {
                const formattedNumber = phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
                this.TelefoneRecebe = formattedNumber;
                return true;
                } else {
                    this.TelefoneRecebe = ''; // Limpar o campo
                    return false;
                }
            } catch (e) {
                this.TelefoneRecebe = ''; // Limpar o campo
                return false;
            }
        },
        formatarValor(valor) {
            if (valor!='' && valor!=null){
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            else{
                valor = 0;
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            
        },
        formattedDate(value) {
            if (value!= null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                        valorAvulsos += item.praValorPor;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                        valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                    });
                }
            }
            this.valorTotal = valorProdutos + valorAvulsos;
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                    const nomeA = a.cetNome || ""; 
                    const nomeB = b.cetNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    });
    
                    if (this.Estados.length == 1) {
                        this.selectedEstados = this.Estados[0].cetId;
                        this.CarregaCidades(this.selectedEstados)
                    }
                // console.log('this.Estados')
               // console.log(this.Estados)

                });
        },
        CarregaCidades(value) {
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];
            this.selectedBairros = null;
            this.BairroTexto = '';
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                });
        },
        CarregaBairros(value) {
            this.selectedBairros = null;
            this.BairroTexto = '';
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];

            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                    const nomeA = a.baiNome || ""; 
                    const nomeB = b.baiNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    }); 
                    
                    if (this.Bairros.length > 0){
                      this.BairroTexto = '';
                    }
                });
        },
        AtualizaData(){
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];
        },
        async CalculaFrete() {
           // console.log('como e a data')
           // console.log(this.DataEntrega)
            this.selectedHorarioId = [];
            this.ArrayEntregas = [];

            if (this.DataEntrega!='' && this.DataEntrega!=null ){
                const dataHoje = moment().format('YYYY-MM-DD'); // Pega a data de hoje
                const dataArmazenada = moment(this.DataEntrega, 'YYYY-MM-DD'); // Converte this.Data para um objeto Moment
                if (dataArmazenada.isBefore(dataHoje)) {
                    this.$mensagemAviso("Data não pode ser anterior ao dia de hoje.")
                }
                else{
                    if (this.selectedEstados == null){
                    this.$mensagemAviso("Selecione o Estado.")
                }
                else if (this.selectedCidades == null){
                    this.$mensagemAviso("Selecione a Cidade.")
                }
                else if (this.selectedBairros == null && this.BairroTexto == ""){
                    this.$mensagemAviso("Selecione o Bairro.")
                }
                else{
                        let bloqueaVenda = true;
                        this.DataEntrega = this.$moment(this.DataEntrega).startOf('day').format('YYYY-MM-DD')
                        if (this.DataEntrega != null) {

                            //busca qtde de horas atraso dos produtos

                            let _atrasosProdutos = 0;
                            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                            if (dadosArmazenados) {
                                dadosArmazenados = JSON.parse(dadosArmazenados);

                                if (dadosArmazenados.avulsos != '') {
                                    dadosArmazenados.avulsos.forEach(item => {
                                        if (item.praTempoProducao != null && item.praTempoProducao != ''){
                                            if(_atrasosProdutos < item.praTempoProducao){
                                                _atrasosProdutos = _atrasosProdutos + item.praTempoProducao;
                                            }
                                        }
                                    });
                                }

                                if (dadosArmazenados.produtos != '') {
                                    dadosArmazenados.produtos.forEach(item => {
                                        if (item.proTempoProducao != null && item.proTempoProducao != ''){
                                            if(_atrasosProdutos < item.proTempoProducao){
                                                _atrasosProdutos = _atrasosProdutos + item.proTempoProducao;
                                            }
                                        }
                                    });
                                }
                                if (_atrasosProdutos > 0){
                                    _atrasosProdutos = (_atrasosProdutos/60);
                                }
                                
                            }
                             //busca qtde de horas atraso dos produtos

                             //busca os valores de entrega
                                let _baiId = 0;
                                if (this.selectedBairros != null) {
                                    _baiId = this.selectedBairros;
                                }

                                if (!this.retirarNaLoja){
                                    await axios.get(`/Process/EntEntregaFrete/GetAll`)
                                            .then(response => {
                                                this.EntregaFrete = response.data;
                                                //console.log('EntregaFrete')
                                            // console.log(this.EntregaFrete)
                                            });
                                }

                                let _entregaFrete = this.EntregaFrete.filter
                                    (x => x.cetId == this.selectedEstados && x.cidId == this.selectedCidades && x.baiId == _baiId && x.eftStatus && x.eftCompraMinima <= this.valorTotal)

                             //   console.log('_entregaFrete')
                             //   console.log(_entregaFrete)
                             //busca os valores de entrega
                             
                             //valida se tem exceção para o dia e localicade
                             let _excecao = false;
                             this.EntregaExcecaoList = this.EntregaExcecao.filter(x =>  moment(x.excDataExcecao).format("YYYY-MM-DD") === moment(this.DataEntrega).format("YYYY-MM-DD") && x.excStatus);
                             //console.log('this.EntregaExcecaoList')
                             //console.log(this.EntregaExcecaoList)
                             if (this.EntregaExcecaoList.filter(x=> x.cidId == 0).length > 0){
                                _excecao = true;
                             }
                             else if (this.EntregaExcecaoList.filter(x=> x.cidId == this.selectedCidades).length > 0){
                                if (this.EntregaExcecaoList.filter(x=> x.cidId == this.selectedCidades && x.baiId == 0).length > 0){
                                    _excecao = true;
                                }
                                else if (this.EntregaExcecaoList.filter(x=> x.cidId == this.selectedCidades && x.baiId == this.selectedBairros).length > 0){
                                    _excecao = true;
                                }
                             }
                            
                            
                             //valida se tem exceção para o dia e localicade && x.cetId == this.selectedEstados && x.cidId == _CidId && x.baiId == _baiId
                            // console.log('this.EntregaExcecao')
                            // console.log(this.EntregaExcecao)
                           
                            if (_excecao) {
                               // console.log('this.EntregaExcecaoList')
                                // console.log(this.EntregaExcecaoList)
                      
                                    this.ArrayEntregas = [];
                                    this.selectedHorarioId = [];
                                    this.EntregaExcecaoList.forEach(element => {
                                        if (element.excAtrasoHs != 1){      
                                            if (this.retirarNaLoja)
                                            {
                                                _entregaFrete[0].eftValorFrete = 0;
                                                _entregaFrete[0].eftTipoEntrega = "Retirar Loja";
                                            }
                                            
                                            this.ArrayEntregas.push(
                                                {
                                                    id: element.excId,
                                                    Horario: (element.excHoraIni.toString().padStart(2, '0')) + ':' + (element.excMinIni.toString().padStart(2, '0')) + ' às ' + (element.excHoraFim.toString().padStart(2, '0')) + ':' + (element.excMinFim.toString().padStart(2, '0')) + ' R$ ' + this.formatarValor(_entregaFrete[0].eftValorFrete) + ' (' + _entregaFrete[0].eftTipoEntrega + ')',
                                                    Valor : _entregaFrete[0].eftValorFrete,
                                                    Tipo: _entregaFrete[0].eftTipoEntrega,
                                                    Agendamento: element
                                                }
                                            );
                                        }
                                    });
                                    
                                    //console.log('this.ArrayEntregas')
                                    //console.log(this.ArrayEntregas)

                                    if (this.ArrayEntregas.length == 0) {
                                        //this.$mensagemAviso("Sem horários disponíveis para dia selecionado :( tente outra data.")
                                        this.textosemhorario = "Sem horários disponíveis para dia selecionado :( tente outra data." ;
                                        bloqueaVenda = false;
                                        this.selectedHorarioId = [];
                                    }
                                    else{
                                        this.textosemhorario = "";
                                    }

                            }
                            else {
                                let _diaSemana = moment(this.DataEntrega, 'YYYY-MM-DD').day()

                                if (_diaSemana > 0 && _diaSemana < 6) {
                                    _diaSemana = 1
                                }
                                else if (_diaSemana == 6) {
                                    _diaSemana = 2
                                }
                                else if (_diaSemana == 0) {
                                    _diaSemana = 3
                                }

                                let _horarios = this.EntregaHorarios.filter(x => x.ehrDia == _diaSemana && x.ehrStatus && x.ehrQtde > 0)
                                .sort((a, b) => {
                                const valorA = parseInt(a.ehrHoraIni) || 0;
                                const valorB = parseInt(b.ehrHoraIni) || 0;
                                return valorA - valorB;
                                });

                                let _horaAgora = moment().hour();
                                if (moment().isSame(moment(this.DataEntrega, 'YYYY-MM-DD'), 'day')) {
                                    this.selectedHorarioId = [];
                                    this.ArrayEntregas = [];
                                   // console.log('aqui')
                                   // console.log(moment().hour())

                                    if (_horaAgora < _horarios[0].ehrHoraIni) {
                                        _horaAgora = _horarios[0].ehrHoraIni;
                                    }
                                    let _atrasoFrete = 0;
                                    if (_entregaFrete[0].eftAtrasoHs != null && _entregaFrete[0].eftAtrasoHs != ''){
                                        _atrasoFrete = _entregaFrete[0].eftAtrasoHs;
                                    }

                                    if (this.retirarNaLoja)
                                    {
                                        _entregaFrete[0].eftValorFrete = 0;
                                        _entregaFrete[0].eftTipoEntrega = "Retirar Loja";
                                    }

                                
                                    _horarios.forEach(element => {
                                        if ((_horaAgora + _atrasoFrete + _atrasosProdutos) < element.ehrHoraIni) {
                                            this.ArrayEntregas.push(
                                                {
                                                    id: element.ehrId,
                                                    Horario: (element.ehrHoraIni.toString().padStart(2, '0')) + ':' + (element.ehrMinIni.toString().padStart(2, '0')) + ' às ' + (element.ehrHoraFim.toString().padStart(2, '0')) + ':' + (element.ehrMinFim.toString().padStart(2, '0')) + ' R$ ' + this.formatarValor(_entregaFrete[0].eftValorFrete) + ' (' + _entregaFrete[0].eftTipoEntrega + ')',
                                                    Valor : _entregaFrete[0].eftValorFrete,
                                                    Tipo: _entregaFrete[0].eftTipoEntrega,
                                                    Agendamento: element
                                                }
                                            );
                                        }
                                    });

                                    if (this.ArrayEntregas.length == 0) {
                                        //this.$mensagemAviso("Sem horários disponíveis para dia selecionado :( tente outra data.")
                                        this.textosemhorario = "Sem horários disponíveis para dia selecionado :( tente outra data.";
                                        bloqueaVenda = false;
                                    }
                                    else{
                                        this.textosemhorario ="";
                                    }


                                }
                                else {
                                 //   console.log('_horarios aqui')
                                //   console.log(_horarios)
                                //   console.log("this.this.retirarNaLoja")
                                //    console.log(this.retirarNaLoja)
                                //    console.log("this.this.ArrayEntregas")
                               //     console.log(this.ArrayEntregas)
                                    this.ArrayEntregas = [];
                                    if (this.retirarNaLoja)
                                    {
                                        _entregaFrete[0].eftValorFrete = 0;
                                        _entregaFrete[0].eftTipoEntrega = "Retirar Loja";
                                    }


                                    _horarios.forEach(element => {
                                            this.ArrayEntregas.push(
                                                {
                                                    id: element.ehrId,
                                                    Horario: (element.ehrHoraIni.toString().padStart(2, '0')) + ':' + (element.ehrMinIni.toString().padStart(2, '0')) + ' às ' + (element.ehrHoraFim.toString().padStart(2, '0')) + ':' + (element.ehrMinFim.toString().padStart(2, '0')) + ' R$ ' + this.formatarValor(_entregaFrete[0].eftValorFrete) + ' (' + _entregaFrete[0].eftTipoEntrega + ')',
                                                    Valor : _entregaFrete[0].eftValorFrete,
                                                    Tipo: _entregaFrete[0].eftTipoEntrega,
                                                    Agendamento: element
                                                }
                                            );
                                    });

                                    if (this.ArrayEntregas.length == 0) {
                                        //this.$mensagemAviso("Sem horários disponíveis para dia selecionado :( tente outra data.")
                                        this.textosemhorario = "Sem horários disponíveis para dia selecionado :( tente outra data.";
                                        this.selectedHorarioId = [];
                                    }
                                    else{
                                        this.textosemhorario ="";
                                    }
                                }

                            }
                       
                             const agora = moment();
                          //   console.log('agora.hour()')
                         //    console.log(agora.hour())
                        //     console.log('dia')
                        //     console.log(dataArmazenada)
                        //     console.log(dataArmazenada.isSame(agora.clone().add(1, 'day'), 'day'))

                           /* if (dataArmazenada.isSame(agora.clone().add(1, 'day'), 'day') && agora.hour() >= 18) {
                                // A data de entrega é amanhã e agora é depois das 18 horas
                                this.ArrayEntregas.shift(); // Remove o primeiro valor do array
                            }*/
                            if (!_excecao) {
                               // console.log('aqui')
                               // console.log(agora.hour())
                           /* if ((dataArmazenada.isSame(agora.clone().add(1, 'day'), 'day') && agora.hour() >= 18) || // Verifica se é o dia seguinte e já passou das 18h
                                (dataArmazenada.day() === 1 && agora.day() === 5 && agora.hour() >= 18) || // Verifica se é segunda-feira (0 = domingo, 1 = segunda-feira) e sexta-feira depois das 18h
                                (dataArmazenada.day() === 1 && agora.day() === 6) || //verifica se é segunda e o hoje é sabado
                                (dataArmazenada.day() === 1 && agora.day() === 0) //verifica se é segunda e o hoje é domingo
                                ) {
                               this.ArrayEntregas.shift(); // Remove o primeiro valor do array
                            }*/
                            if (
                                dataArmazenada.day() === 1 && // Verifica se dataArmazenada é uma segunda-feira
                                (
                                    (agora.day() === 5 && agora.hour() >= 18) || // Sexta-feira após as 18h
                                    (agora.day() === 6) || // Sábado inteiro
                                    (agora.day() === 0) || // Domingo inteiro
                                    (agora.day() === 1 && agora.hour() < 7) // Segunda-feira antes das 07h
                                )
                                ) {
                                this.ArrayEntregas.shift(); // Remove o primeiro valor do array
                                }
                                else if (dataArmazenada.isSame(agora.clone().add(1, 'day'), 'day') && agora.hour() >= 18){
                                    this.ArrayEntregas.shift(); // Remove o primeiro valor do array
                                }
                        }
                    }

                         //   console.log("this.this.retirarNaLoja")
                         //   console.log(this.retirarNaLoja)
                            if (this.selectedBairros == 42 && !this.retirarNaLoja && bloqueaVenda){
                                //console.log("this.ArrayEntregas aqui")
                                //console.log(this.ArrayEntregas)
                                this.ArrayEntregas.push({
                                                            id: 98,
                                                            Horario: 'Pela manhã R$ grátis (Sem Hora Marcada)',
                                                            Valor: 0,
                                                            Tipo: 'Sem Hora Marcada',
                                                            Agendamento: {
                                                                ehrId: 98,
                                                                ehrStatus: true,
                                                                ehrQtde: 99,
                                                                ehrHoraIni: 7,
                                                                ehrHoraFim: 12,
                                                                ehrMinIni: 0,
                                                                ehrMinFim: 0,
                                                                admGrupoTelas: null,
                                                                admCadastroTelas: null,
                                                                cadIdiomas: null,
                                                                grtId: 5,
                                                                cdtId: 23,
                                                                idiId: 1,
                                                                ehrDia: 1
                                                            }
                                                        });
                                    this.ArrayEntregas.push({
                                                            id: 99,
                                                            Horario: 'Pela tarde R$ grátis (Sem Hora Marcada)',
                                                            Valor: 0,
                                                            Tipo: 'Sem Hora Marcada',
                                                            Agendamento: {
                                                                ehrId: 99,
                                                                ehrStatus: true,
                                                                ehrQtde: 99,
                                                                ehrHoraIni: 14,
                                                                ehrHoraFim: 18,
                                                                ehrMinIni: 0,
                                                                ehrMinFim: 0,
                                                                admGrupoTelas: null,
                                                                admCadastroTelas: null,
                                                                cadIdiomas: null,
                                                                grtId: 5,
                                                                cdtId: 23,
                                                                idiId: 1,
                                                                ehrDia: 1
                                                            }
                                                        });
                            }
                    }
                }

            }
        },
        async CarregarDados(){
            if (this.selectedEstados == null){
                this.$mensagemAviso("Selecione o Estado.")
            }
            else if (this.selectedCidades == null){
                this.$mensagemAviso("Selecione a Cidade.")
            }
            else if (this.selectedBairros == null && (this.BairroTexto == "" || this.BairroTexto == null)){
                this.$mensagemAviso("Selecione o Bairro.")
            }
            else if (this.Endereco == "" || this.Endereco == null){
                this.$mensagemAviso("Preencha o Endereço de entrega.")
            }
            else if (this.Numero == "" || this.Numero == null){
                this.$mensagemAviso("Preencha o Número de entrega.")
            }
            else if (this.Complemento == "" || this.Complemento == null){
                this.$mensagemAviso("Preencha o Complemento (casa, apto, escritório...) de entrega.")
            }
            else if (this.EnviaCartao && (this.Formulario.NomePara == "" || this.Formulario.NomePara == null)){
                this.$mensagemAviso("Preencha o nome do(s) presenteado(s).")
            }
            else if (this.NomePara2 == "" || this.NomePara2 == null){
                this.$mensagemAviso("Preencha nome de quem receberá a cesta.")
            }
            else if (this.TelefoneRecebe == ""){
                this.$mensagemAviso("Preencha o Telefone de quem vai receber a entrega.")
            }
            else if (this.DataEntrega == "" || this.DataEntrega == null){
                this.$mensagemAviso("Selecione a Data de entrega.")
            }
            else if (this.selectedHorarioId.length == 0){
                this.$mensagemAviso("Selecione a Horário de entrega.")
            }
           else if (this.EnviaCartao && !this.SemCartao && !this.CartaoBranco && this.Formulario.Mensagem == ""){
                this.$mensagemAviso("Por favor preencha a mensagem ou selecione as opções sem mensagem.");
            }
            else if (!this.EnviaCartao && !this.SemCartao && !this.CartaoBranco){
                this.$mensagemAviso("Por favor selecione as opções de envio de mensagem.");
            }
            else{
              let _result = await this.aplicarMascara();

              if (_result)
              {
                let _agenda = {
                    Endereco: this.Endereco,
                    Numero: this.Numero,
                    Complemento: this.Complemento,
                    Pais: this.selectedPais,
                    Estado: this.selectedEstados,
                    Cidade: this.selectedCidades,
                    Bairro: this.selectedBairros,
                    BairroTexto: this.BairroTexto,
                    DataEntrega: this.DataEntrega,
                    Horarios: this.selectedHorarioId,
                    TelefoneRecebe: this.TelefoneRecebe,
                    NomePara:  this.NomePara,
                    NomePara2:  this.NomePara2,
                    //MesmoRecebe: this.recebeEntrega,
                    ObsCliente: this.Observacao,
                    RetirarLoja: this.retirarNaLoja
                }

                //mensagem cartao
                let _mensagem = {
                    NomeDe: this.Formulario.NomeDe,
                    NomePara: this.Formulario.NomePara,
                    Mensagem: this.Formulario.Mensagem,
                    SemCartao: this.SemCartao,
                    CartaoBranco: this.CartaoBranco,
                    }


                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let validaprod = false;
                    if (this.SetaExcecaoSeg != 0 && this.SetaExcecaoSeg != '' && this.SetaExcecaoSeg !== undefined){
                        let result = dadosArmazenados.produtos.filter(product => 
                            product.proProdutosSegmentos.every(segment => 
                                segment.proSegmentos.psgId !== this.SetaExcecaoSeg
                            )
                            );
                      // console.log(this.SetaExcecaoSeg)
                      // console.log(result)
                       if (result != ''){
                        validaprod = true;
                       }
                    }

                   if (validaprod){

                    axios.get(`/Process/ProSegmentos/GetById/` + this.SetaExcecaoSeg)
                        .then(response => {
                            this.SegmentoProd = response.data;
                            this.$mensagemAviso("Nesta data somente entregas de produtos da categoria " + this.SegmentoProd.psgNomeSegmento);
                        });                    
                   }
                   else{
                    dadosArmazenados.agenda = [];
                    dadosArmazenados.agenda.push(_agenda);
                    //mensagem cartao
                    dadosArmazenados.mensagem = [];
                    dadosArmazenados.mensagem.push(_mensagem);
                    //mensagem cartao
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$router.push('/login')
                   }
                }
            }
            else{
                this.$mensagemAviso('Número de telefone de quem recebe inválido');
            }
            }
        },
        formatPhoneNumber(){
           const phoneNumber = this.TelefoneRecebe.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

                if (phoneNumber.length <= 11) {
                // Se a quantidade de dígitos for menor que 11, assume-se que é um número brasileiro
                this.TelefoneRecebe = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
                } else {
                // Caso contrário, não aplica nenhuma máscara
                this.TelefoneRecebe = phoneNumber;
                }
        },
        preencherEnderecoFixo() {
        if (this.retirarNaLoja) {
            // Preencher com o endereço fixo quando "Retirar na loja" for marcado
            this.Endereco = 'Av. Dante Angelo Pilla';
            this.Numero = 91;
            this.Complemento = 'Loja';
            this.selectedEstados = 21;
            this.CarregaCidades( this.selectedEstados)
            this.selectedCidades = 1;
            this.CarregaBairros(this.selectedCidades)
            this.BairroTexto = '';
            this.selectedBairros = 42;
            this.AtualizaData();
            // ... outros campos fixos ...
        } else {
            // Limpar os campos se o checkbox for desmarcado
            this.Endereco = '';
            this.Numero = '';
            this.Complemento = '';
            this.selectedCidades = null;
            this.selectedBairros = null;
            this.BairroTexto = '';
            this.CarregaCidades( this.selectedEstados);
            // ... limpar outros campos ...
        }
        },
        //mensagem cartao
        updateMensagem(value) {
        // Usando a propriedade innerText para obter apenas o texto sem HTML
        this.Formulario.Mensagem = this.stripHTML(value);
        },
        stripHTML(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
        },
        EnviarCartao(){
        this.EnviaCartao = true;
        this.SemCartao = false;
        this.CartaoBranco = false;
        },
        EnviarSemCartao(){
        this.EnviaCartao = false;
        this.SemCartao = true;
        this.CartaoBranco = false;
        this.TextoCartao = "Não enviar cartão."
        this.Formulario.Mensagem = "";
        this.Formulario.NomeDe = "";
        this.Formulario.NomePara = "";
        this.contadorCaracteres = 0;
        },
        EnviarCartaoBranco(){
        this.EnviaCartao = false;
        this.SemCartao = false;
        this.CartaoBranco = true;
        this.TextoCartao = "Enviar cartão em branco para preencher a mão."
        this.Formulario.Mensagem = "";
        this.Formulario.NomeDe = "";
        this.Formulario.NomePara = "";
        this.contadorCaracteres = 0;
        },
        limitarCaracteres() {
        if (this.Formulario.Mensagem.length > 500) {
            this.Formulario.Mensagem = this.Formulario.Mensagem.substring(0, 500);
        }
        this.contadorCaracteres = this.Formulario.Mensagem.length;
        },
        limparPlaceholder(event) {
        event.target.placeholder = '';
        },
        restaurarPlaceholder(event) {
        event.target.placeholder = 'Mensagem (*Não esqueça de assinar)';
        },
        updateTextosemhorario() {
        if (this.selectedHorarioId) {
            this.textosemhorario = this.selectedHorarioId.Agendamento.excObs;
            this.SetaExcecaoSeg = this.selectedHorarioId.Agendamento.excCategoria;


            if (this.SetaExcecaoSeg != 0 && this.SetaExcecaoSeg != '' && this.SetaExcecaoSeg !== undefined){
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let validaprod = false;
                  
                        let result = dadosArmazenados.produtos.filter(product => 
                            product.proProdutosSegmentos.every(segment => 
                                segment.proSegmentos.psgId !== this.SetaExcecaoSeg
                            )
                            );
                      // console.log(this.SetaExcecaoSeg)
                      // console.log(result)
                       if (result != ''){
                        validaprod = true;
                       }
                  

                   if (validaprod){

                    axios.get(`/Process/ProSegmentos/GetById/` + this.SetaExcecaoSeg)
                        .then(response => {
                            this.SegmentoProd = response.data;
                            this.$mensagemAviso("Nesta data somente entregas de produtos da categoria " + this.SegmentoProd.psgNomeSegmento);
                        });                    
                   }
                }
            }

            //console.log('selectedHorarioId')
            //console.log(this.selectedHorarioId)
        }
        },
    },
    async created() {
        this.carregaMetas();
        
        await this.CarregaEstados(76);

       //let distancia = this.calculateDistance('-30.013673551822365', '-51.11928835767131', '-30.0304119', '-51.2356119');
       //console.log('distancia')
       //console.log(distancia)
       
        axios.get('/Process/InsInstitucional/institucional/23/textoendereco')
            .then(response => {
                this.textoCarrinho = response.data;
               
            })
            .catch(error => {
                console.error(error);
            });

           await axios.get(`/Process/EntEntregaFrete/GetAll`)
            .then(response => {
                this.EntregaFrete = response.data;
                //console.log('EntregaFrete')
               // console.log(this.EntregaFrete)
            });

            await axios.get(`/Process/EntEntregaExcecao/GetAll`)
            .then(response => {
                this.EntregaExcecao = response.data;
                //console.log('EntregaExcecao')
               // console.log(this.EntregaExcecao)
            });

            await axios.get(`/Process/EntEntregaHorarios/GetAll`)
            .then(response => {
                this.EntregaHorarios = response.data;
                //console.log('EntregaHorarios')
                //console.log(this.EntregaHorarios)
            });

            //mensagem cartao
            await axios.get('/Process/InsInstitucional/institucional/26/textomensagem')
                .then(response => {
                    this.logintopo = response.data;
                // console.log('this.logintopo')
                // console.log(this.logintopo)
                })
                .catch(error => {
                    console.error(error);
                });

             axios.get('/Process/InsInstitucional/institucional/28/cadastromensagens')
                .then(response => {
                    this.mensagens = response.data;
                // console.log('this.logintopo')
                // console.log(this.logintopo)
                })
                .catch(error => {
                    console.error(error);
                });

                await  axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.sort((a, b) => {
                                                            const nomeA = a.cpaNome || ""; 
                                                            const nomeB = b.cpaNome || ""; 
                                                            return nomeA.localeCompare(nomeB);
                                                            });
               // console.log('this.Paises')
               // console.log(this.Paises)

                });


            //mensagem cartao
   
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    //console.log('agenda entrou')
                    if (dadosArmazenados.agenda != ''){
                       // console.log('agenda entrou2')
                        this.retirarNaLoja = dadosArmazenados.agenda[0].RetirarLoja;
                        this.selectedEstados = dadosArmazenados.agenda[0].Estado;
                        this.selectedCidades = dadosArmazenados.agenda[0].Cidade;
                        await this.CarregaBairros(this.selectedCidades);
                        this.selectedBairros = dadosArmazenados.agenda[0].Bairro;
                        this.Endereco = dadosArmazenados.agenda[0].Endereco;
                        this.Numero = dadosArmazenados.agenda[0].Numero;
                        this.Complemento = dadosArmazenados.agenda[0].Complemento;
                        this.BairroTexto = dadosArmazenados.agenda[0].BairroTexto;
                        this.DataEntrega ='';
                        this.selectedHorarioId  = '';
                        this.TelefoneRecebe = dadosArmazenados.agenda[0].TelefoneRecebe;
                        if (dadosArmazenados.agenda.length > 0 && dadosArmazenados.agenda[0].Pais != '' && dadosArmazenados.agenda[0].Pais != null){
                            this.selectedPais = dadosArmazenados.agenda[0].Pais;
                        }
                        else{
                            this.selectedPais = 76;
                        }
                        
                        this.NomePara2 = dadosArmazenados.agenda[0].NomePara2;
                    }
                    else{
                        this.selectedPais = 76;
                    }

                    if (dadosArmazenados.cupom != ''){
                        this.valorCupom = dadosArmazenados.cupom[0].ValorCupom;
                    }

                    //mensagem cartao
                    if (dadosArmazenados.mensagem != ''){
                      
                      this.CartaoBranco = dadosArmazenados.mensagem[0].CartaoBranco;
                      this.SemCartao = dadosArmazenados.mensagem[0].SemCartao;

                      if (this.SemCartao){
                          this.EnviarSemCartao();
                      }
                      else if (this.CartaoBranco){
                          this.EnviarCartaoBranco();
                      }
                      else{
                        this.Formulario.Mensagem = dadosArmazenados.mensagem[0].Mensagem;
                        this.Formulario.NomeDe = dadosArmazenados.mensagem[0].NomeDe;
                        this.Formulario.NomePara = dadosArmazenados.mensagem[0].NomePara;
                        this.EnviaCartao = true;
                      }
                    }
                //mensagem cartao


                    this.calculatotal();
                }
                else{
                    this.selectedPais = 76;
                }
        },
}
</script>

<style>
@media (max-width:767px) {
.posicao {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:767px) {
.posicao {
    position:relative; 
    padding-right: 0;
    padding-top: 20px;
}

.fontbtn{
    font-size: 12px;
}
}

@media (min-width:1200px) {
    .posicao {
    position:fixed; 
    padding-right: 10px;
    padding-top: 0px;
    z-index: 9;
    width: 28%;
}

.fontbtn{
    font-size: 15px;
}
}

.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}

.my-calendar .vc-weekday-1, .my-calendar .vc-weekday-7 {
  color: #6366f1;
}

.vc-sky-blue {
  --vc-accent-50: #f0f9ff;
  --vc-accent-100: #e0f2fe;
  --vc-accent-200: #bae6fd;
  --vc-accent-300: #7dd3fc;
  --vc-accent-400: #38bdf8;
  --vc-accent-500: #0ea5e9;
  --vc-accent-600: #0284c7;
  --vc-accent-700: #0369a1;
  --vc-accent-800: #075985;
  --vc-accent-900: #0c4a6e;
}

.small-text {
    font-size: 12px;
    color: #dc2626;
    margin-top: -15px !important;
    position: absolute;
}

.v-input--horizontal .v-input__append {
    -webkit-margin-start: 3px;
    margin-inline-start: 3px;
    padding-top: 10px !important;
}

.v-tooltip > .v-overlay__content {
    background: #333333;
    color: #ffffff;
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1.6;
    display: inline-block;
    padding: 5px 16px;
    text-transform: initial;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition-property: opacity, transform;
}

</style>
