<template>
  <div>

    <div class="mb-4 px-md-4">

      <div class="agendamento" v-if="ListaformasPagamentoPix!= ''">

        <div class="row">
          <div class="col-sm-12" style="text-align:center;">
            <img v-if="ListaformasPagamentoPix!= ''" class="img-fluid" :src="$imgURL + ListaformasPagamentoPix.pfcUrlImgPgto" style="width:200px;height:auto;"
              alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
              <p v-html="ListaformasPagamentoPix.pfcDados" class="texto-strong d-inline-block mb-0 mr-2" style="padding-bottom: 30px;padding-top: 20px;text-align: justify;">
              </p>
              <div style="padding: 20px;border: 1px solid #cccccc;">
                <div v-if="TotalCarrinho != ''"
                    class="d-flex align-items-center justify-content-between mb-2"
                    style="padding-right:5px;">
                    <h6 class="mb-0">Produtos: </h6>
                    <h6 class="text-center mb-0">R$ {{ formatarValor(TotalProdutos) }}</h6>

                </div>
                <div v-if="TotalCarrinho != ''"
                    class="d-flex align-items-center justify-content-between mb-2"
                    style="padding-right:5px;">
                    <h6 class="mb-0">Total Frete: </h6>
                    <h6 class="text-center mb-0">R$ {{ formatarValor(TotalFrete) }} </h6>
                </div>
                <div v-if="TotalCarrinho != '' && TotalCupom!= 0"
                    class="d-flex align-items-center justify-content-between mb-2"
                    style="padding-right:5px;">
                    <h6 class="mb-0" style="color:#B91F1C">Cupom Desconto: </h6>
                    <h6 class="text-center mb-0" style="color:#B91F1C">- R$ {{ formatarValor(TotalCupom) }} </h6>
                </div>
                <div v-if="TotalCarrinho != ''"
                    class="d-flex align-items-center justify-content-between mb-2"
                    style="padding-right:5px;">
                    <h6 class="texto-destaque mb-0">Total Carrinho: </h6>
                    <h6 class="texto-destaque text-center mb-0">R$ {{ formatarValor(TotalCarrinho) }} </h6>

                </div>
            </div>
          </div>
        </div>
        <row>
          <div class="col-sm-12 text-center" style="padding-top: 30px;">
            <button v-if="btnFinaliza == true" @click="PagamentoEfetuado()" class="btn btn-success w-100">PIX
              Realizado</button>
          </div>
        </row>
      </div>
    </div>
    <hr>
    <div v-if="showcomprovante == true" class="mb-4 px-md-4">
      <h5 class="subtitulo mb-4">Enviar Comprovante</h5>
      <v-form>
        <v-container>
            <v-row>
            <v-col cols="12" md="12">
                  <v-file-input v-model="selectedFile" multiple label="Arquivo" variant="underlined"
                    prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif, image/tiff, application/pdf "></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
                <button type="button" class="btn btn-success" @click="AddImagens()">
                    Enviar
                </button>
            </v-col>
            <v-col cols="12" md="6">
              <a @click="ComprovanteEfetuado()" class="btn btn-outline-primary px-5 mt-0" style="margin-bottom:20px;">Enviar  depois</a>
            </v-col>
            </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PagePgtoPixChaveTexto',
  components: {

  },
  props: {
    PixId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: () => ({
    TotalCarrinho:0,
    TotalProdutos: 0,
    TotalFrete:0,
    TotalCupom: 0,
    AgendamentoEntrega:'',
    EndereçoEntrega: '',
    showcomprovante: false,
    btnFinaliza: true,
    ListaformasPagamentoPix: [],
    textocopiado: '',
    mostra: true,
    FotoCarregada: '',
    selectedFile:'',
    urlComprovante: '',
  }),
  methods: {
    formatarValor(valor) {
            if (valor!='' && valor!=null){
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            else{
                valor = 0;
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            
    },
    formattedDate(value) {
        if (value!=null || value != ""){
            return this.$moment(value).format('DD/MM/YYYY')
        }
        else{
            return "";
        }
        
    },
    PagamentoEfetuado() {
      this.showcomprovante = true;
      this.btnFinaliza = false;
    },
    ComprovanteEfetuado() {
      let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
      dadosArmazenados = JSON.parse(dadosArmazenados);
      dadosArmazenados.pagamento = []
      dadosArmazenados.pagamento.push({
        FormaPgto: this.ListaformasPagamentoPix.pfcNomefront,
        RetornoPgto: '',
        CodRetornoPgto: this.ListaformasPagamentoPix.pfcId,
        ValorTotal: this.TotalCarrinho,
        FotoComprova: this.urlComprovante,
      });
      localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
      this.$router.push('/pedidorealizado');
    },
    copy() {
      var copyTextarea = this.ListaformasPagamentoPix.pfcToken
      copyTextarea.select();
      try {
        var successful = document.execCommand('copy');
        if (successful) {
          this.textocopiado = "chave copiada!";
          this.mostra = false;
        }
      } catch (err) {
        alert('Opa, Não conseguimos copiar o texto, é possivel que o seu navegador não tenha suporte, tente usar Crtl+C.');
      }
    },
    async AddImagens(){
      if (this.selectedFile != '' && this.selectedFile != null) {
        this.selectedFile.map(async (element) => {
            const uploadSuccess = await this.UploadImg(element);
            if (uploadSuccess.status == 200) {
                this.urlComprovante = uploadSuccess.data.nameFile;
                this.ComprovanteEfetuado();
            } else {
                this.$mensagemErro("Não foi possível carregar a imagem.");
                return null;
            }
          });
    } else{
      this.$mensagemAviso("Selecione o comprovante para enviar.");
    }
    },
    async UploadImg(value) {
        const formData = new FormData();
        formData.append('file', value);
        const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
        return uploadResult;
    },
  },
  created() {

    axios.get('/Process/ConConfigFormasPagamento/GetById/' + this.PixId)
      .then(response => {
        this.ListaformasPagamentoPix = response.data;
       // console.log('ListaformasPagamentoPix')
      //  console.log(this.ListaformasPagamentoPix)
      })
      .catch(error => {
        console.error(error);
      });

    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
    dadosArmazenados = JSON.parse(dadosArmazenados);

    let _totalProdutos = 0;
    let _cupom = 0;
    if (dadosArmazenados.produtos != '') {
      dadosArmazenados.produtos.forEach((itemprod) => {
        _totalProdutos += parseFloat(itemprod.proProdutosPrecos[0].ppcValorPor * 1)
      });
      this.TotalProdutos = _totalProdutos;
    }

    if (dadosArmazenados.avulsos != '') {
      if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
        dadosArmazenados.avulsos.forEach((itemavul) => {
          _totalProdutos += parseFloat(itemavul.praValorPor)
        });
        this.TotalProdutos = _totalProdutos;
      }
    }

    if (dadosArmazenados.agenda != '') {
      _totalProdutos += parseFloat(dadosArmazenados.agenda[0].Horarios.Valor);
      this.TotalFrete = parseFloat(dadosArmazenados.agenda[0].Horarios.Valor);
    }

    if (dadosArmazenados.cupom != '') {
      _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10);
      this.TotalCupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10);
    }

    this.TotalCarrinho = _totalProdutos - _cupom;
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
